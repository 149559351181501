import React, { useState, useRef, useEffect } from "react";
import Spinner from "../spinners/spinner";
import { Stack } from "react-bootstrap";
import dropdownIcon from "../../assets/svgs/downArrow.svg";
import "./Multiselect.css";
import Form from "react-bootstrap/Form";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import searchIcon from "../../assets/icons/search-icon.png";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useGlobalContext } from "../../context/global";

const MultiSelectWithFilters = ({
  title,
  options,
  defaultSelected,
  onApply,
  isSearch,
  placeHolder,
  classes,
  label,
  isLoading,
  optionChange,
  renderProp,
  filters,
  filtersLogic
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsToShow, setOptionsToShow] = useState(options ?? []); // If options undefined then put empty array []
  const [showOptions, setShowOptions] = useState(false);
  
  const [tempSelectedOptions, setTempSelectedOptions] = useState([]);
  const [activeFilter, setActiveFilter] = useState(filters[0]);
  const [searchValue,setSearchValue] = useState("")
  const { dropdownBtnOptionsVisibilty, setDropdownBtnOptionsVisibilty } =
    useGlobalContext();
    // Show the sorted options on the top.
  const sortedOptionsToShow = (searchValue?.trim() !== "" || activeFilter!==filters[0]) ? optionsToShow : defaultSelectedSort(
    selectedOptions,
    optionsToShow
  );

  // Effects

  // Adding this effect if the default selected options are changed from outside of this component then here selected options should also change.

  useEffect(() => {
    setSelectedOptions([...defaultSelected]);
    setTempSelectedOptions([...defaultSelected]);
  }, [defaultSelected]);

  useEffect(() => {
    if (optionChange) {
      if (options) {
        setOptionsToShow(options ?? []);
      }
    }
  }, [options]);

  const closeDropdown = () => {
    setSelectedOptions(defaultSelected);
    setTempSelectedOptions(defaultSelected);
    setShowOptions(false);
    setSearchValue("");
    setOptionsToShow(options);
    setActiveFilter(filters[0])
  };

  //Ref
  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  useEffect(() => {
    if (!dropdownBtnOptionsVisibilty) {
      closeDropdown();
      setDropdownBtnOptionsVisibilty(true);
    }
  }, [dropdownBtnOptionsVisibilty]);

  useEffect(() => {
    const activeFilterData = filtersLogic(activeFilter,options);
    const filteredOptions = activeFilterData?.filter((option) => {
      return option?.toLowerCase().includes(searchValue?.toLowerCase()); 
    });
    setOptionsToShow(filteredOptions)
  },[searchValue,activeFilter])

  const handleFilterChange = (value) => {
    setActiveFilter(value);
  }

  // Methods
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setTempSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(value)
        ? prevSelectedOptions.filter((option) => option !== value)
        : [...prevSelectedOptions, value]
    );
  };

  const handleApply = () => {
    setSelectedOptions(tempSelectedOptions);
    onApply(tempSelectedOptions);
    setShowOptions(false);
    setOptionsToShow(options);
    setActiveFilter(filters[0]);
    setSearchValue("");
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setTempSelectedOptions(optionsToShow);
    } else {
      setTempSelectedOptions([]);
    }
  };

  const onCancelBtnhandler = () => {
    setSelectedOptions(defaultSelected);
    setTempSelectedOptions(defaultSelected);
    setShowOptions(false);
    setOptionsToShow(options);
    setActiveFilter(filters[0]);
    setSearchValue("")
  };

  const onSearch = (e) => {
    const value = e.target.value 
    setSearchValue(value)
  };

  function defaultSelectedSort(defaultArray, optionsArray) {
    let newArray = defaultArray?.concat(optionsArray);
    const sortedArray = new Set([...newArray]);
    const newSortedArray = Array.from(sortedArray)
    return newSortedArray
  }

  return (
    <div ref={ref} key={title}>
      <Stack className="d-flex flex-col w-100 position-relative cursor-pointer-global">
        {title ? (
          <>
            <label className="filter-options d-hide" htmlFor="campaign-type">
              {title}
            </label>
          </>
        ) : (
          ""
        )}
        <div
          className="select-button-container select-div w-100"
          onClick={() => setShowOptions((prevShowOptions) => !prevShowOptions)}
        >
          <div
            id="campaign-type"
            className="selected-opt-show-btn "
            value={title}
          >
            {tempSelectedOptions.length > 0 ? <>Selected</> : <>Select</>}
            {tempSelectedOptions.length > 0 ? (
              <div className="count-of-selcted-in-main-button-container mx-1">
                {tempSelectedOptions.length}
              </div>
            ) : (
              <div className="count-of-selcted-in-main-button-container zero mx-2">
                0
              </div>
            )}
          </div>
          {isLoading && (
            <div className="for-loader mt-2">
              <Spinner size={"sm"} shape={"border"} />
            </div>
          )}
          <div className="">
            <img
              className="dropdown-img-select"
              src={dropdownIcon}
              alt={"open-dropdown-icon"}
            />
          </div>
        </div>
        {showOptions && (
          <div
            className={`select-option-container multiselect-option-wrapper ${
              classes && classes
            } ${label ? "withLabel" : ""}`}
          >
            {isSearch && (
              <div
                className={
                  "search-box-infind-box dynamic-width-search-bar w-100 position-static"
                }
              >
                <div>
                  <img src={searchIcon} alt="searchIcon"></img>
                </div>
                <div className="finnd-input-text">
                  <input
                    type="text"
                    placeholder={placeHolder}
                    id={title}
                    value={searchValue}
                    onChange={onSearch}
                  ></input>
                </div>
              </div>
            )}
            <div className="options-contaoner-cd lsit-table-scrollbar multiselect">
              <div className="select-opt d-flex flex-column gap-2">
                <div className="filters-container px-3 d-flex gap-3">
                  {filters?.length
                    ? filters?.map((filter) => {
                      const enabledBtn = filter === activeFilter
                        return (
                          <div key={filter}>
                            <SecondaryBtnRb
                              secondaryBtnText={filter}
                              className={
                                enabledBtn
                                  ? "primary-active-btn ennable-paused-btns-height custom-height-multiselect-filter"
                                  : "primary-inactive-btn ennable-paused-btns-height custom-height-multiselect-filter"
                              }
                              onClick={() => handleFilterChange(filter,options)}
                            ></SecondaryBtnRb>
                          </div>
                        );
                      })
                    : null}
                </div>
                <div className="checkbox-rb-container ">
                  <Form className="main-container-cb">
                    <div className="innput-container-cb multiselect">
                      <Form.Check
                        className="form-check"
                        inline
                        name={title}
                        type={"checkbox"}
                        id={title}
                        bsSwitchPrefix="form-switch"
                        onChange={handleSelectAllChange}
                        checked={tempSelectedOptions.length === optionsToShow.length}
                      />
                    </div>
                    <div className={"innput-label-cb"}>
                      <label htmlFor={title}>Select All</label>
                    </div>
                  </Form>
                </div>
              </div>
              {sortedOptionsToShow?.map((option,index) => (
                <div key={option+index} className="select-opt">
                  <div className="checkbox-rb-container ">
                    <Form className="main-container-cb">
                      <div className="innput-container-cb multiselect">
                        <Form.Check
                          value={option}
                          className="form-check"
                          inline
                          name={title}
                          type={"checkbox"}
                          id={option}
                          bsSwitchPrefix="form-switch"
                          onChange={handleOptionChange}
                          checked={tempSelectedOptions.includes(option)}
                        />
                      </div>
                      <div className={"innput-label-cb"}>
                        <label
                          className="d-flex flex-row gap-3"
                          htmlFor={option}
                        >
                          {option}
                          {renderProp ? renderProp(option) : null}
                        </label>
                      </div>
                    </Form>
                  </div>
                </div>
              ))}
            </div>
            <div className="custom-drop-down-saerch-box-footer multiselect-footer">
              <SecondaryBtnRb
                secondaryBtnText="Cancel"
                className="primary-inactive-btn custom-drop-down-footer-btn"
                onClick={onCancelBtnhandler}
              ></SecondaryBtnRb>
              <SecondaryBtnRb
                secondaryBtnText="Apply"
                className="primary-active-btn custom-drop-down-footer-btn"
                onClick={handleApply}
              ></SecondaryBtnRb>
            </div>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default MultiSelectWithFilters;
