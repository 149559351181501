export const categoriesList = [
    {
        name: "Aluminium Foil",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Black Tea",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Blush Makeup Brushes",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Facial Tissues",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Girls' Activewear Leggings",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Girls' Clothing Sets",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Girls' Dresses & Jumpsuits",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Girls' Pants",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Girls' Salwar Suit Sets",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Girls' Skirts",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Girls' T-Shirts",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Girls' Tights & Leggings",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Hair Straighteners",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Kitchen Storage & Containers",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Men's Casual Trousers",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Men's Kurtas",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Men's Mufflers & Scarves",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Men's Underwear Vests",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Sanitary Napkins",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Straws",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Wall Clocks",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Activewear Leggings",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Ballet Flats",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Bangles",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Blouses & Shirts",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Camping & Hiking Pants",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Cardigans",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Chunnis & Dupattas",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Churidar & Salwar Bottoms",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Clothing",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Coats, Jackets & Vests",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Dresses",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Ethnic Footwear",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Fashion Sandals",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Indian Clothing",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Jackets",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Jeans & Jeggings",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Jumpsuits",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Kurtas & Kurtis",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Leggings",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Ponchos & Capes",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Pullover Sweaters",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Pumps",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Salwar Suits",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Shawls",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Skirts",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Skirts & Shorts",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Socks & Stockings",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Tops",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Women's Trousers",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }, {
        name: "Yarn",
        isDataChecked: false,
        buttonName: "Choose Category",
        apiFieldNameForFilteringRequest: "choose_category",
        fieldName: "mt-category-list"
    }

];


export const productsTableHead = [
    {
        "fieldName": "ACTION",
        "minRange": "-",
        "maxRange": "-",
        "id": "status",
        "isDefaultHead": true,
        "isSortingClicked": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false
    },
    {
        "fieldName": "Product",
        "minRange": "-",
        "maxRange": "-",
        "id": "name",
        "isDefaultHead": true,
        "isSortingClicked": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false
    },
    {
        "fieldName": "ASIN",
        "minRange": "-",
        "maxRange": "-",
        "id": "asin",
        "isDefaultHead": true,
        "isSortingClicked": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false
    },
    {
        "fieldName": "MRP",
        "minRange": "-",
        "maxRange": "-",
        "id": "maximum_retail_price",
        "isDefaultHead": false,
        "isSortingClicked": null,
        "isFindTaskActive": false,
        "isSearchNeededHere": true,
        "isSortFunctionalityNeededHere": true
    },
    {
        "fieldName": "SP",
        "minRange": "-",
        "maxRange": "-",
        "isDefaultHead": false,
        "id": "sale_price",
        "isSortingClicked": null,
        "isFindTaskActive": false,
        "isSearchNeededHere": true,
        "isSortFunctionalityNeededHere": true
    },
    {
        "fieldName": "Discount (%)",
        "minRange": "-",
        "maxRange": "-",
        "isDefaultHead": false,
        "id": "discount_percent",
        "isSortingClicked": null,
        "isFindTaskActive": false,
        "isSearchNeededHere": true,
        "isSortFunctionalityNeededHere": true
    },
    // {
    //     "fieldName": "Star rating",
    //     "minRange": "-",
    //     "maxRange": "-",
    //     "isDefaultHead": false,
    //     "id": "star_rating",
    //     "isSortingClicked": null,
    //     "isFindTaskActive": false,
    //     "isSearchNeededHere": true,
    //     "isSortFunctionalityNeededHere": false
    // },

    // {
    //     "fieldName": "Reviews",
    //     "minRange": 0,
    //     "maxRange": 0,
    //     "id": "prod_reviews",
    //     // "status": false, 

    //     "isFindTaskActive": false,
    //     "isSortFunctionalityNeededHere": false,
    //     "isSortingClicked": false
    // },
    {
        "fieldName": "Market - Size",
        "minRange": 0,
        "maxRange": 0,
        "id": "size",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false,
        "isSortingClicked": false
    },
    {
        "fieldName": "Market - Color",
        "minRange": 0,
        "maxRange": 0,
        "id": "color",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false,
        "isSortingClicked": false
    },
    // {
    //     "fieldName": "Launched Date",
    //     "minRange": 0,
    //     "maxRange": 0,
    //     "id": "launched_date",
    //     "status": false,
    //     "isFindTaskActive": false,
    //     "isSortFunctionalityNeededHere": false,
    //     "isTwoDecimalPlacesNeeded": true
    // },
    {
        "fieldName": "Gender",
        "minRange": 0,
        "maxRange": 0,
        "id": "gender",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false,
        "isTwoDecimalPlacesNeeded": true
    },
    {
        "fieldName": "Already advertised",
        "minRange": 0,
        "maxRange": 0,
        "id": "ad_status",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "BSR",
        "minRange": 0,
        "maxRange": 0,
        "id": "best_selling_rank",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "Spends",
        "minRange": 0,
        "maxRange": 0,
        "id": "spends",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "Revenue",
        "minRange": 0,
        "maxRange": 0,
        "id": "sale_value",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "Orders",
        "minRange": 0,
        "maxRange": 0,
        "id": "orders",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "CTR",
        "minRange": 0,
        "maxRange": 0,
        "id": "ctr",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "ROAS",
        "minRange": 0,
        "maxRange": 0,
        "id": "roas",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "Conversion Rate",
        "minRange": 0,
        "maxRange": 0,
        "id": "conversion_rate",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "Impressions",
        "minRange": 0,
        "maxRange": 0,
        "id": "impressions",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "Clicks",
        "minRange": 0,
        "maxRange": 0,
        "id": "clicks",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    // {
    //     "fieldName": "CVR",
    //     "minRange": 0,
    //     "maxRange": 0,
    //     "id": "cvr",
    //     "status": false,
    //     "isFindTaskActive": false,
    //     "isSortFunctionalityNeededHere": true,
    //     "isTwoDecimalPlacesNeeded": false
    // },
    {
        "fieldName": "CPM",
        "minRange": 0,
        "maxRange": 0,
        "id": "cpm",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "AOV",
        "minRange": 0,
        "maxRange": 0,
        "id": "aov",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "ACOS",
        "minRange": 0,
        "maxRange": 0,
        "id": "acos",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
    {
        "fieldName": "CPC",
        "minRange": 0,
        "maxRange": 0,
        "id": "cpc",
        "status": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": true,
        "isTwoDecimalPlacesNeeded": false
    },
];

export const productsTableData = [
    {
        "product_name": "Cloth Architect Cotton Roti Cover/Chapati Covers/Roti Rumal- Round Traditional Rumals to Keep Roti/Chapati Fresh- Assorted (Set of 2 = 2X (Top + Bottom)",
        "imageurl": "https://m.media-amazon.com/images/I/81m5IYXNxSL.jpg",
        "asin": "B07VFZ735N",
        "category": "Kitchen Storage & Containers",
        "ad_group_id": 132618258760450,
        "status": "paused",

        "selling_price": 2323,
        "mrp": 123,
        "discount": 23,
        "star_rating": 4.5,
        "prod_reviews": 2302,
        "size_of_market": 1203,
        "market_color": 'red',
        "launched_date": '12-12-2022',
        'gender': 'Male',
        'already_advertised': "yes",
        "revenue": 1232,
        "orders": 323,
        "ctr": 22,
        "conversion_rate": 12.32,
        "roas": 33,
        "conversionRate": 24,
        "impressions": 232323,
        "clicks": 1232,

        "campaign_id": 218802748122170,
        "campaign_count": 105,
        "adgroup_count": 105,
        "ad_id": 134930211655848,
        "profile_id": 919193917466412,
        "parse_client_id": "GKL4iuk6Gh",
        "isPlusIconDisabled": false,
        "isTargetChecked": false,
        "childRows": []
    },
    {
        "product_name": "ISHIKA Acrylic (Plastic) Glossy Finish Bangle/Kadaa Set For Women and Girls MultiColor Bangle Set_(Pack Of 12 Kada Set)_Size-2.2 (2.2)",
        "imageurl": "https://m.media-amazon.com/images/I/A1dBvsUmB3L.jpg",
        "asin": "B0BHDN7W5Z",
        "category": "Women's Bangles",
        "ad_group_id": 132618258760450,
        "status": "enabled",
        "campaign_id": 218802748122170,
        "campaign_count": 106,
        "adgroup_count": 106,
        "ad_id": 153075630374513,
        "profile_id": 919193917466412,
        "parse_client_id": "GKL4iuk6Gh",
        "isPlusIconDisabled": false,
        "isTargetChecked": false,

        "selling_price": 2323,
        "mrp": 123,
        "discount": 23,
        "star_rating": 4.5,
        "prod_reviews": 2302,
        "size_of_market": 1203,
        "market_color": 'red',
        "launched_date": '12-12-2022',
        'gender': 'Male',
        'already_advertised': "yes",
        "revenue": 1232,
        "orders": 323,
        "ctr": 22,
        "conversion_rate": 12.32,
        "roas": 33,
        "conversionRate": 24,
        "impressions": 232323,
        "clicks": 1232,

        "childRows": [  ]
    },

    {
        "product_name": "Fancy Rhinestone-encrusted Earrings for Women and Girls",
        "imageurl": "https://m.media-amazon.com/images/I/A1dBvsUmB3L.jpg",
        "asin": "B0DR5PNX3W",
        "category": "Women's Earrings",
        "ad_group_id": 154802508772430,
        "status": "enabled",
        "campaign_id": 927603695153540,
        "campaign_count": 122,
        "adgroup_count": 122,
        "ad_id": 954404397194003,
        "profile_id": 688129556259342,
        "parse_client_id": "hX9ptBLpkz",
        "isPlusIconDisabled": false,
        "isTargetChecked": false,
        "selling_price": 450,
        "mrp": 200,
        "discount": 15,
        "star_rating": 4.3,
        "prod_reviews": 876,
        "size_of_market": 450,
        "market_color": "silver",
        "launched_date": "2022-01-15",
        "gender": "Female",
        "already_advertised": "no",
        "revenue": 2500,
        "orders": 50,
        "ctr": 20,
        "conversion_rate": 10.2,
        "roas": 2.5,
        "conversionRate": 12,
        "impressions": 15000,
        "clicks": 300
      },
      {
        "product_name": "Crystal Beaded Bracelet for Women and Girls",
        "imageurl": "https://m.media-amazon.com/images/I/A1dBvsUmB3L.jpg",
        "asin": "B0J3T87N1S",
        "category": "Women's Bracelets",
        "ad_group_id": 526987120930521,
        "status": "enabled",
        "campaign_id": 791952300965238,
        "campaign_count": 78,
        "adgroup_count": 78,
        "ad_id": 226310905490720,
        "profile_id": 451322014893616,
        "parse_client_id": "gW5rBejYnu",
        "isPlusIconDisabled": false,
        "isTargetChecked": false,
        "selling_price": 599,
        "mrp": 299,
        "discount": 20,
        "star_rating": 4.8,
        "prod_reviews": 1200,
        "size_of_market": 689,
        "market_color": "gold",
        "launched_date": "2021-11-01",
        "gender": "Female",
        "already_advertised": "yes",
        "revenue": 8000,
        "orders": 100,
        "ctr": 15,
        "conversion_rate": 8.9,
        "roas": 3.2,
        "conversionRate": 10,
        "impressions": 20000,
        "clicks": 300
      },
      {
        "product_name": "Floral Print Women's Sleeveless Top",
        "imageurl": "https://m.media-amazon.com/images/I/A1dBvsUmB3L.jpg",
      
        "asin": "B09W57Y6KJ",
        "category": "Women's Clothing",
        "ad_group_id": 523624108402166,
        "status": "enabled",
        "campaign_id": 122419836557854,
        "campaign_count": 97,
        "adgroup_count": 117,
        "ad_id": 546384970323185,
        "profile_id": 975234530840994,
        "parse_client_id": "GKL4iuk6Gh",
        "isPlusIconDisabled": false,
        "isTargetChecked": false,
        "selling_price": 1525,
        "mrp": 1899,
        "discount": 20,
        "star_rating": 4.2,
        "prod_reviews": 128,
        "size_of_market": 345,
        "market_color": "purple",
        "launched_date": "03-09-2022",
        "gender": "Female",
        "already_advertised": "yes",
        "revenue": 2356,
        "orders": 78,
        "ctr": 17,
        "conversion_rate": 9.6,
        "roas": 2.8,
        "conversionRate": 14,
        "impressions": 20420,
        "clicks": 349,
        "childRows": []
        },

        {
            "product_name": "Men's Printed Round Neck T-Shirt",
        "imageurl": "https://m.media-amazon.com/images/I/A1dBvsUmB3L.jpg",
           
            "asin": "B0BHDN7W5Z",
            "category": "Men's Clothing",
            "ad_group_id": 703291108760450,
            "status": "enabled",
            "campaign_id": 648802748122170,
            "campaign_count": 75,
            "adgroup_count": 94,
            "ad_id": 123075630374513,
            "profile_id": 888193917466412,
            "parse_client_id": "GKL4iuk6Gh",
            "isPlusIconDisabled": false,
            "isTargetChecked": false,
            "selling_price": 899,
            "mrp": 1299,
            "discount": 30,
            "star_rating": 3.9,
            "prod_reviews": 70,
            "size_of_market": 268,
            "market_color": "blue",
            "launched_date": "07-01-2022",
            "gender": "Male",
            "already_advertised": "yes",
            "revenue": 1245,
            "orders": 50,
            "ctr": 13,
            "conversion_rate": 7.8,
            "roas": 2.4,
            "conversionRate": 10,
            "impressions": 15260,
            "clicks": 198,
            "childRows": []
            },

            {
                "product_name": "Set of 6 Glass Flower Vases with Tray for Centerpiece Table Decorations, Clear",
                "imageurl": "https://m.media-amazon.com/images/I/A1dBvsUmB3L.jpg",
                "asin": "B0X5K5PZ8R",
                "category": "Home Decor",
                "ad_group_id": 132618258760450,
                "status": "enabled",
                "campaign_id": 218802748122170,
                "campaign_count": 106,
                "adgroup_count": 106,
                "ad_id": 153075630374513,
                "profile_id": 919193917466412,
                "parse_client_id": "GKL4iuk6Gh",
                "isPlusIconDisabled": false,
                "isTargetChecked": false,"selling_price": 1989,
                "mrp": 2999,
                "discount": 33,
                "star_rating": 4.2,
                "prod_reviews": 230,
                "size_of_market": 1000,
                "market_color": 'green',
                "launched_date": '05-01-2023',
                'gender': 'Female',
                'already_advertised': "no",
                "revenue": 5432,
                "orders": 543,
                "ctr": 12,
                "conversion_rate": 10.5,
                "roas": 18,
                "conversionRate": 16,
                "impressions": 42323,
                "clicks": 454,
                
                "childRows": [  ]
            },

            {
                "product_name": "Men's Striped Casual Shirt, Cotton",
                "imageurl": "https://m.media-amazon.com/images/I/A1dBvsUmB3L.jpg",
                "asin": "B0Y4J9BPF9",
                "category": "Men's Clothing",
                "ad_group_id": 132618258760450,
                "status": "enabled",
                "campaign_id": 218802748122170,
                "campaign_count": 106,
                "adgroup_count": 106,
                "ad_id": 1530752630374513,
                "profile_id": 919193917466412,
                "parse_client_id": "GKL4iuk6Gh",
                "isPlusIconDisabled": false,
                "isTargetChecked": false,
                "selling_price": 999,
                "mrp": 1599,
                "discount": 37,
                "star_rating": 4.6,
                "prod_reviews": 1023,
                "size_of_market": 3000,
                "market_color": 'blue',
                "launched_date": '03-15-2023',
                'gender': 'Male',
                'already_advertised': "yes",
                "revenue": 7654,
                "orders": 987,
                "ctr": 18,
                "conversion_rate": 14.2,
                "roas": 22,
                "conversionRate": 18,
                "impressions": 76543,
                "clicks": 1323,
                
                "childRows": [  ]
            }

            
            ,



];



export const productsSelectedTableHead = [
    {
        "fieldName": "ACTION",
        "minRange": "-",
        "maxRange": "-",
        "id": "status",
        "isDefaultHead": true,
        "isSortingClicked": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false
    },
    {
        "fieldName": "Product",
        "minRange": "-",
        "maxRange": "-",
        "id": "product_name",
        "isDefaultHead": true,
        "isSortingClicked": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false
    },
    {
        "fieldName": "ASIN",
        "minRange": "-",
        "maxRange": "-",
        "id": "asin",
        "isDefaultHead": true,
        "isSortingClicked": false,
        "isFindTaskActive": false,
        "isSortFunctionalityNeededHere": false
    },
    
];


export const productsSelectedTableData = [
    {
        "product_name": "Cloth Architect Cotton Roti Cover/Chapati Covers/Roti Rumal- Round Traditional Rumals to Keep Roti/Chapati Fresh- Assorted (Set of 2 = 2X (Top + Bottom)",
        "imageurl": "https://m.media-amazon.com/images/I/81m5IYXNxSL.jpg",
        "asin": "07VFZ7325N",
        "category": "Kitchen Storage & Containers",
        "ad_group_id": 1361822587360450,
        "status": "enabled",
        "campaign_id": 288027481222170,
        "campaign_count": 105,
        "adgroup_count": 105,
        "ad_id": 134930116553848,
        "profile_id": 919193917246412,
        "parse_client_id": "GKL4iuk6Gih",
        "isPlusIconDisabled": false,
        "isTargetChecked": true,
        "childRows": []
    },
    {
        "product_name": "ISHIKA Acrylic (Plastic) Glossy Finish Bangle/Kadaa Set For Women and Girls MultiColor Bangle Set_(Pack Of 12 Kada Set)_Size-2.2 (2.2)",
        "imageurl": "https://m.media-amazon.com/images/I/A1dBvsUmB3L.jpg",
        "asin": "B0HDN7W52Z",
        "category": "Women's Bangles",
        "ad_group_id": 132618253876045,
        "status": "enabled",
        "campaign_id": 218802748132217,
        "campaign_count": 106,
        "adgroup_count": 106,
        "ad_id": 153075630374351,
        "profile_id": 919193921746641,
        "parse_client_id": "GKL4iuk6G",
        "isPlusIconDisabled": false,
        "isTargetChecked": true,
        "childRows": []
    }
];


export const productAttrib = [
    "Product MRP",
    "Product SP",
    "Product Discount",
    // "Product Star rating",
    // "Product Reviews",
    "Product Name",
    "Product - Size",
    "Cut Size *",
    "Product - Color",
    "BSR",
    "Top BSR",
    // "Launched Date",
    "Gender",
    "Products not advertised"
];

export const perfmanceMetrices = [
    "Revenue",
    "Orders",
    "CTR",
    "ROAS",
    "Conversion Rate",
    "Impressions",
    "Clicks",
    "Spends",
    "Top Selling",
    "Top ROAS",
    "Top Conversion"
];

export const numberParamaterFields = [
    "Revenue",
    "Orders",
    "CTR",
    "ROAS",
    "Conversion Rate",
    "Impressions",
    "Clicks",
    "Spends",
    "Top Selling",
    "Top ROAS",
    "Top Conversion",
    "Product MRP",
    "Product SP",
    "Product Discount",
    "BSR",
    "Top BSR",
    "Cut Size *"
]

export const anyParamaterFields = [
    "Product Name",
    "Product - Size",
    "Product - Color"
]


