import "./DiscountAnalysis.css";
import { Stack } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import SectionTitle from "../../components/sectionTitle";
import Loader from "../../components/loader";
import TableRb from "../../components/Tables/tableRb";
import ReportFilters from "../../components/Reports/Filters";
import { useDeepCompareMemo } from "use-deep-compare";
import {
  humanize,formatTableData,sortArrayOfObjectsByLabel
} from "../../components/Reports/Components/utils";
import Spinner from 'react-bootstrap/Spinner';
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import useInitialLoad from "../../hooks/useInitialLoad";
import Breadcrumb from '../../components/Breadcrumb/ReportsBreadCrumb';
import RadioInputGroup from "../../components/radioInputGroup/radioInputGroup";

const useCategoryData = (resultSet) => {
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet ? resultSet.tableColumns({}) : [];
    return [
      columns,
      formatTableData(columns, resultSet ? resultSet.tablePivot({}) : []),
    ];
  }, [resultSet]);

  return { tableColumns, dataSource };
};

const DiscountAnalysis = (props) => {
  const [token, refresh] = useToken("");
  const { cubeToken, setCubeToken } = useGlobalContext();
  const { dateDifference } = useGlobalContext();
  const [discountTableResult, setDiscountTableResult] = useState([])
  const [categoryList, setCategoryList] = useState([]);
  const [categoryListLoading,setCategoryListLoading] = useState(false);
  const [selectedCategoryList, setSelectedCategoryList] = useState([])
  const [timeGroupBy,setTimeGroupBy] = useState("Day");
  const groupByForAPI = timeGroupBy === "Day" ? "date" : "month";

  const [itemsList, setItemsList] = useState([]);
  const [itemListLoading,setItemListLoading] = useState(false);
  const [selectedItemsList, setSelecteditemsList] = useState([])
  const [showItemsList, setShowtemsList] = useState(false)
  const [categoryDataResult, setCategoryDataResult] = useState(null)
  const [discoutCategoryData, setDiscoutCategoryData] = useState([])  
  const [discoutCategoryDataComputed, setDiscoutCategoryDataComputed] = useState([])  

  const formatDate = (date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0].toString();
  };
  const { calendarDate, setCalendarDate, setDateDifference, applyCalendar } = useGlobalContext();
  const { clientAccountId } = useGlobalContext();
  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });

  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );

  const [table, setTable] = useState("amazon_discount_analysis_table");
  const [analysisType, setAnalysisType] = useState(['Discount','SP', 'MRP'])
  const [selectedAnalysisType, setSelectedAnalysisType] = useState("Discount");

  const [initialLoad,setInitialLoad] = useInitialLoad();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "amazon_discount_analysis_table.averageDiscount"
  ]);

  const [cubeQueryOrder, setCubeQueryOrder] = useState({
    "amazon_discount_analysis_table.date": "desc",
  });

  const optionsForDateFormatting = {
    year:"numeric",
    month:"long",
    ...(groupByForAPI==="date" && {day:"numeric"})
  }

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState([
    `amazon_discount_analysis_table.${groupByForAPI}`
  ]);
  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState([
    {
      dimension: "amazon_discount_analysis_table.date",
      dateRange: [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ],
    },
  ]);
  const [cubeQueryFilters, setCubeQueryFilters] = useState([
    {
      member: "amazon_discount_analysis_table.profileId",
      operator: "equals",
      values: [clientAccountId],
    },
  ]);

  const [cubeQueryFiltersAsins, setCubeQueryFiltersAsins] = useState([
    {
      member: "amazon_discount_analysis_table.profileId",
      operator: "equals",
      values: [clientAccountId],
    },
  ]);

  const [resultSet, setResultSet] = useState({});

  useEffect(() => {
      setCubeQueryTimeDimensions((prev) => {
        let data = [...prev];
        data[0]["dateRange"] = [formatDate(calendarDate[0].startDate), formatDate(calendarDate[0].endDate)];
        return data;
      });
    setIsLoaderVisible(true)
  }, [applyCalendar]);

  useEffect(() => {
    //table data
    if (cubeToken) {
      setCategoryListLoading(true)
      cubejsApi
        .load(
          {
            measures: [],
            order: { [`${table}.category_name`]: "desc" },
            dimensions: [`${table}.category_name`],
            filters: cubeQueryFilters,
            timeDimensions: [],
            timezone: "Asia/Kolkata",
            limit: 5000,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((result) => {
          const data = result.loadResponse.results[0].data;
          const newArray = data.map(obj => Object.values(obj)[0]);
          setCategoryList(newArray)
          setCategoryListLoading(false)
          setSelectedCategoryList((prev) => {
            prev.push(newArray[0])
            return prev
          })
          setCubeQueryFilters((prev)=>{
            let data = [ ...prev ];
            data.forEach((x, i) => {
              if(x.member === table+".category_name") {
                  data.splice(i, 1);
              }
            });
            if(newArray.length > 0) {
                data.push({
                    "member": table+".category_name",
                    "operator": "equals",
                    "values": [newArray[0]]
                  })
            }
            return data;
         })
         setCubeQueryFiltersAsins((prev) => {
          let data = [ ...prev ];
          data.forEach((x, i) => {
            if(x.member === table+".category_name") {
                data.splice(i, 1);
            }
          });
          if(newArray.length > 0) {
              data.push({
                  "member": table+".category_name",
                  "operator": "equals",
                  "values": [newArray[0]]
                })
          }
          return data;
         })
        }).catch((error) => {
          setCategoryListLoading(false)
          if(error.message === 'Invalid token') {
              setCubeToken(false)
              localStorage.setItem("cubeToken", null);
              refresh()
          }
        });
    }
  }, [cubeToken]);

  useEffect(() => {
    //table data
    if (cubeToken) {
      if(cubeQueryFilters.length>1) {
        setIsLoaderVisible(true)
        setInitialLoad(true)
      cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: cubeQueryDimensions,
            filters: cubeQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            timezone: "Asia/Kolkata",
            limit: 5000,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((result) => {
          setResultSet(result);
          setDiscountTableResult(result?.loadResponse?.results[0]?.data)
          setIsLoaderVisible(false)
          setInitialLoad(false)
        }).catch((error) => {
          if(error.message === 'Invalid token') {
              setCubeToken(false)
              localStorage.setItem("cubeToken", null);
              refresh()
          }
        });
    }
  }
  }, [cubeQueryMeasure, cubeQueryFilters, cubeQueryTimeDimensions, cubeToken,cubeQueryDimensions]);

  useEffect(() => {
   setCubeQueryDimensions([`amazon_discount_analysis_table.${groupByForAPI}`])
  },[timeGroupBy])

  useEffect(() => {
    setTimeout(() => {
      if (cubeToken) {
        cubejsApi.load({
              measures: [
                "amazon_discount_analysis_table.total_product",
                "amazon_discount_analysis_table.avg_discount_percentage",
                "amazon_discount_analysis_table.total_discount_product",
                "amazon_discount_analysis_table.discount_product_percentage",
                "amazon_discount_analysis_table.units_sold_30_days",
              ],
              order: {"amazon_discount_analysis_table.total_product": "desc"},
              dimensions: ["amazon_discount_analysis_table.category_name"],
              filters: [{
                  "member": "amazon_discount_analysis_table.profileId",
                  "operator": "equals",
                  "values": [clientAccountId]
              }],
              timeDimensions: [{
                  "dimension": "amazon_discount_analysis_table.date",
                  "dateRange": "Last 30 days"
              }],
              timezone: "Asia/Kolkata",
              limit: 5000,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": true,
              },
            }
          )
          .then((result) => {
            setCategoryDataResult(result)
            setIsLoaderVisible(false)
            setInitialLoad(false)
          }).catch((error) => {
            if(error.message === 'Invalid token') {
                setCubeToken(false)
                localStorage.setItem("cubeToken", null);
                refresh()
            }
          });
    }
    }, 2000);
    
    
  },[cubeToken])

  useEffect(() => {
    setTimeout(() => {
      if (cubeToken) {
        cubejsApi.load({
              measures: [
                "amazon_discount_analysis_table.revenue",
                "amazon_discount_analysis_table.unit_sold",
              ],
              order: {"amazon_discount_analysis_table.date": "desc"},
              dimensions: ["amazon_discount_analysis_table.month",
              "amazon_discount_analysis_table.discount_category"],
              filters: [{
                  "member": "amazon_discount_analysis_table.profileId",
                  "operator": "equals",
                  "values": [clientAccountId]
              }],
              timeDimensions: [{
                  "dimension": "amazon_discount_analysis_table.date",
                  "dateRange": "This year"
              }],
              timezone: "Asia/Kolkata",
              limit: 5000,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": true,
              },
            }
          )
          .then((result) => {
            var computedResult = discountformatData(result?.loadResponse?.results[0]?.data)
            setDiscoutCategoryData(computedResult)
            setDiscoutCategoryDataComputed(getDiscountDataList(computedResult))
          }).catch((error) => {
            if(error.message === 'Invalid token') {
                setCubeToken(false)
                localStorage.setItem("cubeToken", null);
                refresh()
            }
          });
    }
    }, 1000);
    
    
  },[cubeToken])
  
  useEffect(() => {
    //table data
    if (cubeToken) {
      setShowtemsList(false)
      setItemsList([])
      if(cubeQueryFiltersAsins.length>1) {
        setItemListLoading(true)
      cubejsApi.load({
            measures: [`${table}.itemNameAsin`],
            order: { [`${table}.itemNameAsin`]: "desc" },
            dimensions: [`${table}.category_name`],
            filters: cubeQueryFiltersAsins,
            timeDimensions: [],
            timezone: "Asia/Kolkata",
            limit: 5000,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((result) => {
          const data = result.loadResponse.results[0].data;
          const newArray = data.map(item => {
            const itemNameAsinArray = item["amazon_discount_analysis_table.itemNameAsin"].split(",");
            return itemNameAsinArray;
          });
          const combinedArray = [].concat(...newArray);
          setItemsList([...new Set(combinedArray)])
          setItemListLoading(false)
          setShowtemsList(true)
        }).catch((error) => {
          setItemListLoading(false)
          if(error.message === 'Invalid token') {
              setCubeToken(false)
              localStorage.setItem("cubeToken", null);
              refresh()
          }
        });
    }
  }
  }, [cubeQueryFiltersAsins,cubeQueryTimeDimensions]);


  const discountformatData = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      const month = item["amazon_discount_analysis_table.month"];
      if (!groupedData[month]) {
        groupedData[month] = [];
      }
      groupedData[month].push(item);
    });
  
    const formattedData = [];
    for (const month in groupedData) {
      const items = groupedData[month];
      const totalRevenue = items.reduce((sum, item) => sum + (item["amazon_discount_analysis_table.revenue"] || 0), 0);
      const formattedItems = items.map((item) => ({
        label: item["amazon_discount_analysis_table.discount_category"],
        revenue: (item["amazon_discount_analysis_table.revenue"] || 0).toFixed(2),
        revenue_per: totalRevenue !== 0 ? (((item["amazon_discount_analysis_table.revenue"] || 0) / totalRevenue) * 100).toFixed(2) +'%' : '0',
        units_sold: (item["amazon_discount_analysis_table.unit_sold"] || 0).toString()
      }));
      formattedData.push({ [month]: sortArrayOfObjectsByLabel(formattedItems, 'label') });
    }
    return formattedData;
  };

  const [showCustomDateBox, setShowCustomDateBox] = useState(false);

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const calenderIconRefForCampaignlist = useRef(null);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const filterCampaigns = (e,type) => {
    setCubeQueryFilters((prev)=>{
      let data = [ ...prev ];
      data.forEach((x, i) => {
          if(x.member === table+".category_name") {
              data.splice(i, 1);
          }
      });
      if(e.length > 0) {
          data.push({
              "member": table+".category_name",
              "operator": "equals",
              "values": e
            })
      }
      return data;
   })
   setCubeQueryFiltersAsins((prev) => {
    let data = [ ...prev ];
    data.forEach((x, i) => {
      if(x.member === table+".category_name") {
          data.splice(i, 1);
      }
    });
    if(e.length > 0) {
        data.push({
            "member": table+".category_name",
            "operator": "equals",
            "values": e
          })
    }
    return data;
   })
    setSelectedCategoryList(e)
  }
  
  const filterItems = (e,type) => {
    const asins = e.map(item => item.split(" | ").pop());
    setCubeQueryFilters((prev)=>{
      let data = [ ...prev ];
      data.forEach((x, i) => {
          if(x.member === table+".asin") {
              data.splice(i, 1);
          }
      });
      if(e.length > 0) {
          data.push({
              "member": table+".asin",
              "operator": "equals",
              "values": asins
            })
      }
      return data;
   })
    setSelecteditemsList(e)
  }

  useEffect(()=> {
    if(selectedAnalysisType === 'Discount') {
      setCubeQueryMeasure([
        "amazon_discount_analysis_table.averageDiscount"
      ]);
    } else if(selectedAnalysisType === 'MRP') {
      setCubeQueryMeasure([
        "amazon_discount_analysis_table.averageMrp"
      ]);
    } else if(selectedAnalysisType === 'SP') {
      setCubeQueryMeasure([
        "amazon_discount_analysis_table.averageSp"
      ]);
    }
  },[selectedAnalysisType])

  const { tableColumns, dataSource } = useCategoryData(categoryDataResult);
  
  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);

  const onDropdownImghandler = (type) => {
    // console.log("@$# onDropdownImghandler");
  };


  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);


  var spendsRevenueResultSet = formatPieChartData(
    resultSet,
    "Average Discount",
    groupByForAPI,
    "averageDiscount"
  );

  function formatPieChartData(result, type,key, measure1) {
    let selectedval = 'Discount'
    let chartLabel = 'Discount'
    if(selectedAnalysisType === 'MRP') {
      selectedval = 'Mrp'
      chartLabel = 'MRP'
    } else if(selectedAnalysisType === 'SP') {
      selectedval = 'Sp'
      chartLabel = 'Selling price'
    }
    type = `Average ${selectedval}`
    measure1 = `average${selectedval}`
    if (Object.keys(result).length > 0) {
      let cubeResult = JSON.parse(JSON.stringify(result));
      let returnResultLabel = [];
      let resultObj = {};
      let returnResultSeries1 = [];
      let data = cubeResult?.loadResponse?.results[0]?.data;
      data.map((value, index) => {
        if (value["amazon_discount_analysis_table." + key] == null) {
          returnResultLabel.push("NULL");
        } else {
          returnResultLabel.push(
            new Date(value["amazon_discount_analysis_table." + key]).toLocaleDateString("en-US",optionsForDateFormatting)
          );
        }
        returnResultSeries1.push(
          value["amazon_discount_analysis_table." + measure1]
        );
      });
      resultObj[measure1] = returnResultSeries1;

      return { labels: returnResultLabel, series: resultObj, label: type,chartLabel:chartLabel };
    } else {
    }
  }

  const getDiscountDataList = (discount_data) => {
    let final_arr = [];
    discount_data.forEach(obj => {
      Object.keys(obj).forEach(dateKey => {
        obj[dateKey].forEach(item => {
          const label = item.label;
          const data = [
            { date: dateKey, revenue: item.revenue, revenue_per: item.revenue_per, unit_sold: item.units_sold }
          ];

          const existingCategory = final_arr.find(item => item.discount_category.label === label);
          if (existingCategory) {
            existingCategory.discount_category.data.push(...data);
          } else {
            final_arr.push({
              discount_category: {
                label: label,
                data: data
              }
            });
          }
        });
      });
    });
    return final_arr;
  }
  

  const avgDiscountTableBody = (
    <>
      <div>
 
        <Stack>

          <div className="avg-discount-table"> 
            <table className="mt-table">
              <thead className="fixed-table avg-discount-fix-table">
                <tr className="main-table-row-fixed avg-discount-header-fix">
                  <th className="txt-center">Date</th>
                  <th className="txt-center"> Average
                    {selectedAnalysisType === 'Discount' ?  ' Discount %' : selectedAnalysisType === 'MRP' ? ' MRP' :  selectedAnalysisType === 'SP' ? ' Selling Price' : ' Discount %'}
                  </th>
                </tr>
              </thead>
              {discountTableResult.length > 0 &&
              <tbody className="another-tbody">
                {discountTableResult?.map((obj, index1) => {
                  
                  return (
                    <tr key={index1}>
                      <td className="txt-center border-right">{ new Date(obj[`${table}.${groupByForAPI}`]).toLocaleDateString("en-US", optionsForDateFormatting)}</td>
                      {
                        selectedAnalysisType === 'Discount' && `${table}.averageDiscount` in obj ?
                            <td className="txt-center">
                            {obj[`${table}.averageDiscount`] !== null ? obj[`${table}.averageDiscount`].toFixed(2) : '0'}%
                          </td>
                        : selectedAnalysisType === 'MRP' && `${table}.averageMrp` in obj ?
                              <td className="txt-center">
                              {obj[`${table}.averageMrp`] !== null ? obj[`${table}.averageMrp`].toFixed(2) : '0'}
                            </td>
                          : selectedAnalysisType === 'SP' && `${table}.averageSp` in obj ?
                          <td className="txt-center">
                          {obj[`${table}.averageSp`] !== null ? obj[`${table}.averageSp`].toFixed(2) : '0'}
                        </td>
                      : <td></td>

                      }
                      
                    </tr>
                  );
                })}
              </tbody>
              }
            </table>
          </div>
        </Stack>
        
      </div>
    </>
  )

  const mainDiscountTableBody = (
    <>
      <div className="campaign-list-table-data">
        <Stack className="campaign-list-main-table">
          <div
            className={
              "discount-table listinng-table mh-260"
            }
          >
            <div className="table-wrapper">
              {Object.entries(discoutCategoryDataComputed).length > 0 ?
              <table className="mt-table reports">
                <thead className="fixed-table">
                  <tr className="main-table-row-fixed">
                    <th className="sticky-column m-width-151"></th>
                    {discoutCategoryData.map((item,index) => {
                      const month = Object.keys(item)[0];
                      return (
                        <th key={index} className="targeting-second-column targeting-table-heads discount-reports" colSpan={3}>
                          {month}
                        </th>
                      );
                    })}
                  </tr>
                  <tr className="main-table-row-fixed">
                    <th className="sticky-column targeting-second-column targeting-table-heads">Discount Category</th>
                    {discoutCategoryData.map((item,index) => {
                      return (
                        <>
                          <th className="targeting-second-column targeting-table-heads m-width-105">Revenue</th>
                          <th className="targeting-second-column targeting-table-heads m-width-105">Revenue %</th>
                          <th className="targeting-second-column targeting-table-heads m-width-105">Units Sold</th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="another-tbody">
                  {discoutCategoryDataComputed.map((obj, index1) => {
                    return (
                      <>
                        <tr key={index1}>
                          <td className="sticky-column border-right">{obj.discount_category.label}</td>
                          {obj.discount_category.data?.map((rec, index2) => {
                            return (
                              <React.Fragment key={index2}>
                                <td>{rec.revenue} </td>
                                <td>{rec.revenue_per} </td>
                                <td className="border-right">{rec.unit_sold} </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              : 
                <div
                className="d-flex w-100 h-50 justify-content-center align-items-center flex-column gap-1"
                style={{ minHeight: "200px" }}
              >
                <div>
                  <Spinner animation="border" role="status" variant="loader-variant">
                  </Spinner>
                </div>
                <div className="text-loader-variant">Loading...</div>
              </div>
              }
            </div>
          </div>
        </Stack>
      </div>
    </>
  );

  const categoryTableBody = (
    <>
      <div className="campaign-list-table-data">
        <Stack className="campaign-list-main-table">
          <div
            className={
              "main-targeting-table-body-container listinng-table d-hide"
            }
          >
            {dataSource?.length ?
            <table className="mt-table d-hide reports">
              <thead className="fixed-table category-fix-table">
                <tr className="main-table-row-fixed category-header-fix">
                {tableColumns?.map((row, mainIndex) => {
                      return (
                        <>
                        <th>{humanize(row.shortTitle)}</th>
                        </>
                      );
                    })}
                </tr>
              </thead>
              <tbody className="another-tbody">
                
              {dataSource?.map((tr, index1) => {
                return (
                  <React.Fragment key={index1 + 1}>
                    <tr
                      key={index1 + "-row"}
                    >
                      {Object.values(tr).map((td, columnindex) => {
                        return <td>{td}</td>
                      })}
                      </tr>
                      </React.Fragment>
                      )
              })}
                
              </tbody>
            </table>
            :
              <div
              className="d-flex w-100 h-50 justify-content-center align-items-center flex-column gap-1"
              style={{ minHeight: "200px" }}
            >
              <div>
                <Spinner animation="border" role="status" variant="loader-variant">
                </Spinner>
              </div>
              <div className="text-loader-variant">Loading...</div>
            </div>
            }
          </div>
        </Stack>
      </div>
    </>
  )

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={initialLoad}></Loader>
      <Breadcrumb />
      <div className="d-flex flex-row mb-2 w-300 gap-4">
          <div style={{minWidth:'20%'}}>
          <CustomDropDown
            title={"Select Comparison Type"}
            filterCampaigns={(e) => { setSelectedAnalysisType(e.target.value) }}
            targettingValue={selectedAnalysisType}
            onDropdownImghandler={onDropdownImghandler}
            src={dropdownIcon}
            btnBgColor=""
            borderRadius="0px 3px 3px 0px"
            menuZindex={101}
            type={"objective"}
            autoWidth={false}
            classes="reporting-select"
            data={analysisType}
          />
          </div>
      </div>
      <div
        className="d-flex flex-row mb-2 w-300 gap-4"
      >
        <div style={{minWidth:'20%'}}>
        {categoryList.length >0 && <ReportFilters name={`Select Categories ${categoryListLoading ? "(Loading...)" : ""}`} handleDropdown={filterCampaigns}  enabledfilters={['default']} title="Categories" data={categoryList} defaultDropdown={selectedCategoryList} label={"Categories"} />}
        </div>
        <div style={{minWidth:'20%'}}>
        { <ReportFilters handleDropdown={filterItems} name={`Select Product Name ${itemListLoading ? "(Loading...)" : ""}`}  enabledfilters={['default']} title="Product List" data={itemsList} defaultDropdown={selectedItemsList} label={"Products"} />}

        </div>
        <div>
        <button
          className="date-container date-btn-campaignlist d-none"
          onClick={dateInputClickHandler}
        >
          <div className="d-flex justify-conntent-center">
            <span className="date-btn-text">{dateDifference}</span>
          </div>
          <button className="btn-calender-img-holder">
            <span className="">
              <img
                className="dropdown-img-for-select"
                src={selectSideIcon}
                alt={"SelectSideIcon"}
              ></img>

              <img
                className="calender-icon-for-campaignlist"
                src={CalenderIconForCampaignList}
                alt={"CalendarIcon"}
              ></img>
            </span>
          </button>
        </button>
        </div>
      </div>
      <div
        ref={calenderIconRefForCampaignlist}
        className={
          showCustomDateBox ? "calender-inclasslist-container" : "hide"
        }
      >
        <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
        <span className="calender-close-icon-campaignlist">
          <img
            onClick={() => setShowCustomDateBox(false)}
            src={closeIcon}
            alt={"CloseIcon"}
          ></img>
        </span>
      </div>
     <div className="d-flex justify-content-between">
     <SectionTitle sectionTitleHeading='Discount Analysis'></SectionTitle>
      <div className="group-by-container d-flex justify-content-end">
            <RadioInputGroup
            state={timeGroupBy}
            setState={setTimeGroupBy}
            name="viewType"
            radios={["Day", "Month"]}
            required
          ></RadioInputGroup>
            </div>
     </div>
      
      <div className="d-flex justify-content-between mt-4 mb-4 gap-1">
        <div className="w-100 h-300 pie-container p-2">
          <Chart
            type="line"
            resultSet={spendsRevenueResultSet}
            classes={"marketing-reports"}
            noDataFound={!isLoaderVisible && !spendsRevenueResultSet?.length}
          />
        </div>
        <div className="w-100 h-300 pie-container p-2">
          <Stack className="campaign-list-main-table">
  
            <TableRb noDataFound={!isLoaderVisible &&  !discountTableResult?.length}  shimmerEffect={isLoaderVisible} table={avgDiscountTableBody}></TableRb>
          </Stack>
        </div>
      </div>

      <div className="">
        <div className="discount-label">
          <span>Month / Revenue / Revenue% / Units Sold</span>
        </div>
        <div className="campaign-list-table-data discount-main-table">
          <Stack className="campaign-list-main-table">
            <TableRb noDataFound={!isLoaderVisible && !Object.entries(discoutCategoryDataComputed)?.length} shimmerEffect={isLoaderVisible} table={mainDiscountTableBody}></TableRb>
          </Stack>
        </div>
      </div>

      <div className="">
        <div className="campaign-list-table-data mb-3">
          <Stack className="campaign-list-main-table">
            <TableRb noDataFound={!isLoaderVisible && !dataSource?.length} shimmerEffect={isLoaderVisible} table={categoryTableBody}></TableRb>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default DiscountAnalysis;
