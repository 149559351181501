import React from "react";
import { Modal } from "react-bootstrap";
import "./editBidModalForTargetinng.css";
import { useState } from "react";
import { useEffect } from "react";

import RadioButtonRb from "../Buttons/radioButtonRb/radioButtonRb";
// import "./editBidModal.css";
import plusIcon from "../../assets/icons/plus-sign.png";
import minusIcon from "../../assets/icons/minus-sign.png";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import { useDispatch, useSelector } from "react-redux";
import { campaignListActions } from "../../redux/slices/campaignList.js";

export default function EditBidModalForTargeting(props) {
  const dispatch = useDispatch();
  const [isVisible, setisVisible] = useState(false);
  const [selectedActionForbidEdit, setSelectedActionForbidEdit] = useState("");
  const [increaseByInputValue, setincreaseByInputValue] = useState(5);
  const [decreaseByInputValue, setdecreaseByInputValue] = useState(5);
  const [setBidAsValue, setSetBidAsValue] = useState("");
  const [valueToUpdateBidby, setvalueToUpdateBidby] = useState("");
  const targetCheckedForBulkEdit = useSelector(
    (state) => state.campaignListData.targetCheckedForBulkEdit
  );
  const checkedTargetDataForBulEdit = useSelector(
    (state) => state.campaignListData.checkedTargetDataForBulEdit
  );

  const isButtonDisable = (selectedActionForbidEdit === "increase-by" && increaseByInputValue === 0) || (selectedActionForbidEdit === "decrease-by" && decreaseByInputValue === 0) || (selectedActionForbidEdit === "set_bid_as" && (setBidAsValue==="" || setBidAsValue==="0"))

  const handelClick = () => {
    props.SetShowModal(!props.ShowModal);
  };


  useEffect(() => {
    dispatch(campaignListActions.actionEditCollectiveBidForMasterTargeting({}));
  }, []);

  useEffect(() => {
    if (selectedActionForbidEdit === "increase-by") {
      setvalueToUpdateBidby(increaseByInputValue);
    } else if (selectedActionForbidEdit === "set_bid_as") {
      setvalueToUpdateBidby(setBidAsValue)
    } else {
      setvalueToUpdateBidby(decreaseByInputValue);
    }
  }, [increaseByInputValue, decreaseByInputValue, selectedActionForbidEdit]);

  const plusButtonHandler = (type) => {
    switch (type) {
      case "increaseBy":
        setincreaseByInputValue(
          (increaseByInputValue) => increaseByInputValue + 1
        );
        break;
      case "decreaseBy":
        setdecreaseByInputValue(
          (decreaseByInputValue) => decreaseByInputValue + 1
        );
        break;
      default:
        break;
    }
  };

  const minusButtonHandler = (type) => {
    switch (type) {
      case "increaseBy":
        if (!increaseByInputValue == 0) {
          setincreaseByInputValue(
            (increaseByInputValue) => increaseByInputValue - 1
          );
        }
        break;
      case "decreaseBy":
        if (!decreaseByInputValue == 0) {
          setdecreaseByInputValue(
            (decreaseByInputValue) => decreaseByInputValue - 1
          );
        }
        break;

      default:
        break;
    }
  };

  const setBidAsValueDataHandler = (e) => {
    setSetBidAsValue(e.target.value);
  };

  const onSelecthandler = (e) => {
    setSelectedActionForbidEdit(e.target.id);
  };

  function resetToInitialStates () {
    setincreaseByInputValue(5);
    setdecreaseByInputValue(5);
    setSelectedActionForbidEdit("")
    setSetBidAsValue("")
    setvalueToUpdateBidby("")
  }

  const onupdateClickHandler = () => {
    dispatch(campaignListActions.hideBulkeditBidModal());
    dispatch(
      campaignListActions.actionEditCollectiveBidForMasterTargeting({
        selectedAction: selectedActionForbidEdit,
        valuePercentage: valueToUpdateBidby,
        targetIdsToChagedbid: checkedTargetDataForBulEdit,
        setAsValue:
          selectedActionForbidEdit === "set_bid_as" ? setBidAsValue : null,
      })
    );
    resetToInitialStates()
  
    if(props.getData) {
      const data = {
        selectedAction: selectedActionForbidEdit,
        valuePercentage: valueToUpdateBidby,
        targetIdsToChagedbid: checkedTargetDataForBulEdit,
        setAsValue:
          selectedActionForbidEdit === "set_bid_as" ? setBidAsValue : null,
      }
      props.getData(data)
    }
    if(props.cancel) {
      props.cancel();
    }
  };
  const cancelHandler = () => {
    dispatch(campaignListActions.hideBulkeditBidModal());
    // dispatch(campaignListActions.actionEditCollectiveBidForMasterTargeting({}));
    if(props.cancel) {
      props.cancel();
    }
    resetToInitialStates()

  };

  let modalBody = (
    <div className="bid-config-modal-container">
      <div className="edit-bid-heading">Edit Collective Bid</div>
      <div className="d-flex justify-content-center flex-column align-items-center targeting-bid-config-content-containet">
        <div className="targeting-bid-config-container">
          <span>
            <RadioButtonRb
              className="action-by-btn cursor-pointer-global"
              onChange={onSelecthandler}
              checked={selectedActionForbidEdit === "increase-by"}
              id="increase-by"
              name="collective-bid"
              label="Increase by"
            ></RadioButtonRb>
          </span>
          <span className="percetage-config-container">
            <span onClick={() => plusButtonHandler("increaseBy")}  className="cursor-pointer-global">
              <img src={plusIcon} alt={"increase"}></img>
            </span>
            <span className="d-flex justify-center actionn-text">
              <input
                className="bid-number"
                type="text"
                inputMode="number"
                disabled={selectedActionForbidEdit !== "increase-by"}
                value={increaseByInputValue + "%"}
                onChange={(e) => {
                  const withoutSymbol = e.target.value?.replace(/%/g, '');
                  setincreaseByInputValue(Number(withoutSymbol))
                }}
              ></input>
            </span>
            <span onClick={() => minusButtonHandler("increaseBy")}  className="cursor-pointer-global">
              <img src={minusIcon} alt={"decrease"}></img>
            </span>
          </span>
        </div>
        <div className="targeting-bid-config-container">
          <span>
            <RadioButtonRb
              className="action-by-btn"
              name="collective-bid"
              id="decrease-by"
              checked={selectedActionForbidEdit === "decrease-by"}
              label="Decrease by"
              onChange={onSelecthandler}
            ></RadioButtonRb>
          </span>
          <span className="percetage-config-container">
            <span>
              <img
              className="cursor-pointer-global"
                src={plusIcon}
                onClick={() => plusButtonHandler("decreaseBy")}
                alt={"decrease"}
              ></img>
            </span>
            <span className="d-flex justify-center">
              <input
                className="bid-number"
                type="text"
                value={decreaseByInputValue + "%"}
                disabled={selectedActionForbidEdit !== "decrease-by"}
                onChange={(e) =>{ 
                  const withoutSymbol = e.target.value?.replace(/%/g, '');
                  setdecreaseByInputValue(Number(withoutSymbol))
                }}
              ></input>
            </span>
            <span onClick={() => minusButtonHandler("decreaseBy")}  className="cursor-pointer-global">
              <img  src={minusIcon} alt={"decrease"}></img>
            </span>
          </span>
        </div>
        {
          props.setBidAs &&
        <div className="targeting-bid-config-container">
          <span className="targeting-edit-bid-set-bid-box">
            <RadioButtonRb
              className="action-by-btn"
              name="collective-bid"
              checked={selectedActionForbidEdit === "set_bid_as"}
              id="set_bid_as"
              label="Set Bid as"
              onChange={onSelecthandler}
            ></RadioButtonRb>
          </span>
          <span className=" targeting-edit-bid-set-bid-cal-box">
            {/* <span className="percetage-config-container">
                        <span><img src={plusIcon} onClick={() => plusButtonHandler("decreaseBy")}></img></span>
                        <span className="d-flex justify-center"><input className="bid-number" type="text" value={decreaseByInputValue + "%"} disabled={selectedActionForbidEdit !== "decrease-by"} onChange={e => setdecreaseByInputValue(e.target.value)}></input></span>
                        <span onClick={() => minusButtonHandler("decreaseBy")}><img src={minusIcon}></img></span>
                    </span> */}
            <input
              type="number"
              value={setBidAsValue}
              placeholder=""
              className="set-as-iput-text"
              onChange={setBidAsValueDataHandler}
            ></input>
          </span>
        </div>
}
      </div>
      <div className="targeting-btn-container-for-bid">
        <SecondaryBtnRb
          secondaryBtnText="Cancel"
          onClick={cancelHandler}
          className="primary-inactive-btn px-5 mx-3"
        ></SecondaryBtnRb>
        <SecondaryBtnRb
          secondaryBtnText="Save"
          id={props?.confirmationId}
          disabled={selectedActionForbidEdit === "" || isButtonDisable}
          onClick={onupdateClickHandler}
          className="primary-active-btn px-5"
        ></SecondaryBtnRb>
      </div>
    </div>
  );

  return (
    <div className="filetr-modal-for-targeting-main-container">
      <Modal
        // class="modal-dialog modal-dialog-centered"
        show={props.show}
        scrollable="true"
        className="targeting-filter"
        // dialogClassName="modal-90w"
        // onHide={handleClose}
        // backdrop="edit-bid-for-targetinng-modal"
        backdropClassName="edit-bid-for-targetinng-backdrop"
        contentClassName="edit-bid-for-targetinng-conntennt"
        // keyboard={false}
        // contentClassName="filter-content-class"
        // contentClassName="main-modal-className-for-filter"
        // bsPrefix="modal"

        dialogClassName="edit-bid-for-targetinng-dialogue"
        // modal="edit-bid-for-targetinng-modal"
      >
        <Modal.Body>{modalBody}</Modal.Body>
      </Modal>
    </div>
  );
}
