import React from 'react';
import Spinner from '../spinners/spinner';

const ShimmerTable = ({rows,tableHeaders,hideHeaders,keyAppender}) => {
const dummyDataForSkeleton = Array.from({ length: rows }, (_, index) => `row_${index + 1}${keyAppender ? keyAppender : "default"}`);
  return (
    
        hideHeaders ?  dummyDataForSkeleton?.map((row, index) => {
            return (
              <tr key={row + index +( keyAppender ? keyAppender : 'default')}>
                {tableHeaders?.map((data, mainIndex) => {
                  return (
                    <td
                      key={data?.name}
                      style={{ border: "1px solid #ccc" }}
                      className={""}
                    >
                      <div className="td-with-loader">
                        <Spinner shimmer={true} />
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          }) :   <div className="main-campaignlist-table-body-container">
          <table className="campaign-list-table campaign-list-center">
            <thead className="fixed-table">
              <tr className="main-table-row-fixed">
                {tableHeaders?.map((th, mainIndex) => {
                  return (
                    <th key={th.name} className={"targeting-table-heads"}>
                      {th.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {
              dummyDataForSkeleton?.map((row, index) => {
                return (
                  <tr key={row}>
                    {tableHeaders?.map((data, mainIndex) => {
                      return (
                        <td
                          key={data?.key}
                          style={{ border: "1px solid #ccc" }}
                          className={""}
                        >
                          <div className="td-with-loader">
                            <Spinner shimmer={true} />
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })
              }
            </tbody>
          </table>
        </div>

  )
}

export default ShimmerTable
