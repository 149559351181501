import { masterApi } from "../../apis/masterApi";
import { useGlobalContext } from "../../context/global";
import sendUserInfoToClarity from "../analytics/clarity";
import sendUserInfoToGainsight from "../analytics/gainsight";
import useUserDetails from "../userData/useUserDetails";
import axios from "axios";
import { currencySymbolPicker } from "../../constant/defaultCurrencyConfigurations";


const fetchData = async (clientId) => {
  try {
    const response = await axios.get('https://pamphlet.back4app.io/classes/Client?where={ "objectId":"' + clientId + '" }', {
      headers: {
        'X-Parse-Application-Id': '0f5uiNLZwPItPmo2g10gQlaCpglrbzkpuRnXUSqp',
        'X-Parse-REST-API-Key': 'lNPIUzmgFLoLbX4F1YqJ9RhRFRd7gfOwOM3OugrG',
        'X-Parse-Session-Token': 'r:97f814adf62536301f368c2156b86c41'
      }
    });
    return response
    
  } catch (error) {
    throw error;
  }
}
export default async function getClientData(passedClientId, wantReports,email="") {
  /**
   * Function to Get the current ClientId Data including reports
   * @Note Just the pass the clientId for which you want the data and if you want reports just pass true or else pass false
   */
  // This function will return client data and reports of client
  const data = await masterApi({}, "clientData", false, "Get", passedClientId);
  const back4appData = await fetchData(passedClientId);
  localStorage.setItem('back4appData',JSON.stringify(back4appData?.data?.results[0]))
  const websiteForGainsight = back4appData?.data?.results[0]?.website
  const { client_id, account_id, default_bid, brand_id, name , brand_keywords,account_type,vendor_catalog_csv_url,id,currency_code } =
    data?.data?.data[0];
    let  mainData = {};  
    mainData = {clientData:data?.data?.data[0]};
  localStorage.setItem("clientId", JSON.stringify(client_id));
  localStorage.setItem("accountIdNumeric",JSON.stringify(id));
  localStorage.setItem("client_account_type",JSON.stringify(account_type))


  //Sending data to gainsight
  if(email) {
    sendUserInfoToGainsight(
      { email: email },
      { clientId: client_id, ...(websiteForGainsight && {website:websiteForGainsight}) },
    );
    //sending Data to clarity
    sendUserInfoToClarity({ email: email }, { clientId: client_id },{name : name});
  }
  const currency_symbol = currencySymbolPicker(currency_code);
  localStorage.setItem("currency_symbol",JSON.stringify(currency_symbol))
  
  localStorage.setItem("defaultBid", default_bid);
  localStorage.setItem("brandId", brand_id);
  localStorage.setItem("clientId", JSON.stringify(client_id));
  localStorage.setItem("accountId", JSON.stringify(account_id));
  localStorage.setItem("clientName", JSON.stringify(name));
  localStorage.setItem("brandKeywords",JSON.stringify(brand_keywords))
  localStorage.setItem("csvUrl",JSON.stringify(vendor_catalog_csv_url))
  
 

  // Another API Call For Reports.
  if (wantReports === true) {
    axios
      .get(
        `https://app1.adyogi.com/api/amzn/getCustomReports?accountId=${account_id}`
      )
      .then((response) => {
        // setReportData(response.data);
        localStorage.setItem("reportData", JSON.stringify(response.data));
        const data  = response.data
        mainData["reportData"] = data;
      });

    // setClientAccountId(account_id);
  }
  return mainData;
}
