
export const onRowExpandHandler = (
  asin,
  sentIndex,
  currentState
) => {
  const updatedState = currentState.map((parentAd) => {
    if(parentAd?.asin === asin) {
      const updatedChildAds  = parentAd?.childRows?.map((childAd,index) => {
        if(index === sentIndex) {
          childAd.isTargetChecked = !childAd.isTargetChecked
        }
        return childAd;
      })
      parentAd.childRows = updatedChildAds
    }
    return parentAd
  })
  return updatedState
};
