/**
 * @Note This function will take an current Filtering Object that will return an array of filtering with key, value and operator.
 * @param {*} FilteringObject 
 * @returns {*} Array Of Filters
 */

const emptyArrayCaseValues = ["","All","Both","all",[]]
const emptyKeyCaseValues = ["recommendation"]
const equalsOperatorArrayValues = ["product_name","status","campaign_type","asin"]
const inOperatorArrayValues = ["portfolio_id","campaign_id","adgroup_id","amz_portfolio_id","category"]

export const generateFiltering = (FilteringObject) => {
    const filterArray = Object.entries(FilteringObject).flatMap(([key, value]) => {
        if (emptyArrayCaseValues.includes(value)) return [];  
        if (emptyKeyCaseValues.includes(key)) return [];    

        const operator = equalsOperatorArrayValues.includes(key) ? "EQUALS" : inOperatorArrayValues.includes(key) ? "IN" : "CONTAINS";
        const valueWrapper  = key === "campaign_id" || key === "adgroup_id" ? value : value
        return [{ field_name: key, operator, [inOperatorArrayValues.includes(key)?"values":"value"]: valueWrapper }];
      });
      return  filterArray
}