import React, { useEffect,memo } from "react";
import crossIconForChip from "../../assets/icons/filterChip_cancel_icon.svg";
import "./FilterChips.css";
import resetIcon from "../../assets/icons/resetIcon.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaInfoCircle } from 'react-icons/fa';


 function FilterChips(props) {
  const onCancelClickHandler = (e, fieldNameForAPIFilter) => {
    props?.onCancelClickRemoveFilteringHandler(fieldNameForAPIFilter);
  };

  const renderCheckedValues = (listData) => {
    const { checkedValue } = listData;
  
    if (!checkedValue || checkedValue.length === 0) {
      return null;
    }
  
    const renderSpan = (val, index) => (
      <span key={index}>
        {`${val}${index < checkedValue.length - 1 ? ',' : ''} `}
      </span>
    );
  
    const renderHiddenValues = () => (
      <div>
        <div className="show-all-checked-filtering">
          <span className="above-two-chip-data-value-container">
            {` + ${checkedValue.length - 2}`}
          </span>
        </div>
        <div className="hideExtraTxt">
          <span>
            {checkedValue.slice(2).map((cv, ind) => (
              `${cv}${ind + 2 !== checkedValue.length ? ',' : ''}`
            ))}
          </span>
        </div>
      </div>
    );
  
    return checkedValue.map((val, index) => (
      index < 2
        ? renderSpan(val, index)
        : index + 1 === checkedValue.length
          ? renderHiddenValues()
          : null
    ));
  };


  return (
    <>
      <div className="main-container-for-chips">
        {
          //   value: checkedValue,
          //   fieldNameOfSelectedBtn: fieldNameOfSelectedBtn,
          //   fieldNameForAPIFilter: fieldNameForAPIFilter,
          props.filterChipList &&
            props.filterChipList.length &&
            props?.filterChipList.map((listData, i) => {
              // Adding a check For Objective in master targeting
              if(listData?.fieldNameOfSelectedBtn === "Objective") {
                listData.checkedValue = listData?.checkedValue?.map((data) => data === "Retarget" ? "Retargeting" : data) 
              }
              return (
                <div className="d-flex">
                  {listData?.tooltip === true ? (
                    <ReactTooltip
                      id="metric-filter-chip"
                      style={{
                        zIndex: "999999",
                        fontSize: "12px",
                        padding: "8px",
                      }}
                      effect="solid"
                      place="top"
                      variant="dark"

                    />
                  ) : null}
                  <button className="d-flex justify-content-between chip-box-conrainer"    >
                    <div className="d-flex">
                      <span className="chip-box-field-name-text">
                        {`${listData.fieldNameOfSelectedBtn}:`}
                      </span>
                      {listData && Array.isArray(listData?.checkedValue) ? (
                        <span className="chip-box-field-name-selected-opt-text">
                          {/* <span>{listData.checkedValue}</span> */}
                          {listData &&
                            Array.isArray(listData?.checkedValue) &&
                            listData?.checkedValue?.length &&
                           renderCheckedValues(listData)}
                        </span>
                      ) : (
                        <span className="chip-box-field-name-selected-opt-text">
                          {
                            listData.checkedValue !== "" &&
                            listData?.minValue === "-Infinity"
                              ? ` ${listData.minValue} to ${listData.maxValue}`
                              : ` ${listData.minValue} - ${listData.maxValue}`
                            // listData.fieldNameOfSelectedBtn
                          }
                        </span>
                      )}
                    </div>
                   { listData?.tooltip ? <div className="px-2" data-tooltip-id={ listData?.tooltip ?"metric-filter-chip" : true}
                  data-tooltip-html={`${listData?.toolTipContent}`}>
                      <FaInfoCircle height={20}/>
                    </div> : null}
                    <div className="px-3">
                      <img
                        src={crossIconForChip}
                        onClick={(e) =>
                          onCancelClickHandler(e, listData.field_name)
                        }
                        alt={"cancel"}
                      ></img>
                    </div>
                   
                  </button>
                </div>
              );
            })
        }
        <div
          className={
            props.isResetButtonNeeded && props.filterChipList?.length
              ? "mx-3 align-items-center mb-2"
              : "hide"
          }
        >
          <button
            className="primary-inactive-btn reset-btn-for-filter-btn"
            onClick={props.onResetButtonClick}
          >
            <span>
              <img className="reset-icon-img" src={resetIcon} alt={"reset"}></img>
            </span>
            <span className="text-color-for-reset-btn">Reset</span>
          </button>
        
        </div>
      </div>
    </>
  );
}

export default memo(FilterChips)
