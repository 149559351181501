import React from 'react';
import { Form } from 'react-bootstrap';
import "./radioButtonRb.css";
const RadioButtonRb = (props) => {
    return <div className={props.className ? props.className + " leavle" : "radio-btn-containe leavle"}>
        <Form.Check
            inline
            label={props.label}
            name={props.name}
            checked={props.checked}
            type={props.checkBox ? "checkbox" : "radio"}
            state={props.state}
            setstate={props.setState}
            value={props.state}
            bsPrefix="form-check"
            onSelect={props.onSelect}
            onClick={props.onClick}
            disabled={props.disabled?true:false}
            checked={props.checked}
            className="leavle"
            onChange={props.onChange}
            id={props.id}
            // defaultChecked={props.defaultChecked}
        />
    </div>
}



export default RadioButtonRb;