import React from "react";
import NewRuleButtonn from "../Buttons/NewRule";
import SwitchBtn from "../../Buttons/switchBtn";
import CustomDropDown from "../../DropDown/CustomDropDown";
import cancelMinusBox from "../../../assets/svgs/cancelMinusBox.svg";
import dropdownIcon from "../../../assets/icons/downArrowForSplitButton.svg";
import addIcon from "../../../assets/svgs/plus_box.svg";
import deleteIcon from "../../../assets/svgs/delete.svg";
import {
  rulesActions,
  staticRulesConfig,
} from "../../../constant/Automations/rulesConfig";
import { Stack } from "react-bootstrap";
import RuleContainerModal from "../RuleContainerModal/RuleContainerModal";
import { useState } from "react";

const RuleContainer = ({
  currentCase,
  caseHeaderImage,
  caseHeading,
  rules,
  maxRules,
  hideIncreaseByField,
  handleRulesActions,
}) => {

  const [showModal,setShowModal] = useState(false);
  const [modalDetails,setModalDetails] = useState({text:"",currentIndex:null});
  const {text,currentIndex} = modalDetails

  
  const handleYesClick = () => {
    if(text.includes("reset")) {
      handleRulesActions({
        action: RESET_RULE,
        payload: {
          ruleIndex: currentIndex,
          case: currentCase,
       },
      })
    } else {
      handleRulesActions({
        action:DELETE_RULE,
        payload:{
          sentIndex:currentIndex,
          case:currentCase
        }
       })
    } 
    setShowModal(false)
  }

  const handleNoClick = () => {
    setShowModal(false)
  }

  const handleShowModal = (modalFor,index) => {
    const text = modalFor === "reset" ? "Are you sure you want to reset this rule?" :"Are you sure you want to delete this rule?"
    setShowModal(true);
    setModalDetails({...modalDetails,text:text,currentIndex:index})
  }

  const {
    ADD_RULE,
    ADD_FILTER,
    REMOVE_FILTER,
    UPDATE_FILTER_VALUE,
    UPDATE_RULE_VALUE,
    DELETE_RULE,
    TOGGLE_RULE,
    RESET_RULE,
  } = rulesActions;
  const backgroundColor = staticRulesConfig[currentCase]["caseBgColor"];
  return (
    <div className="rules-individual-container">
      <div className="rules-header d-flex justify-content-between">
        <div className="d-flex gap-3 align-items-center">
          <div
            className="header-icon-container"
            style={{ backgroundColor: backgroundColor }}
          >
            <img alt={currentCase} height={10} src={caseHeaderImage} />
          </div>
          <p className="m-0">{caseHeading}</p>
        </div>
        <div>
          <p className="m-0">
            {rules.length}/{maxRules}
          </p>
        </div>
      </div>
      <div className="rules-body">
        {rules?.map((rule, index) => {
          const isDisabled = rule?.isDisabled;
          const parentIndex = index;
          return (
            <div className="position-relative">
              {/* Turn Rule Off On  */}
              <div className="rules-body-header d-flex align-items-center justify-content-between mb-3 gap-2">
                <div className="d-flex align-items-center gap-2">
                  <SwitchBtn
                    checked={!isDisabled}
                    classNameForFormSwitch={"form-switch-padding-automation"}
                    onChange={() =>
                      handleRulesActions({
                        action: TOGGLE_RULE,
                        payload: {
                          recievedIndex: index,
                          case: currentCase,
                        },
                      })
                    }
                  />
                  <p className="m-0">Rule {index + 1}</p>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <p
                    className="m-0 clickable-text-color cursor-pointer-global"
                    onClick={() =>
                      handleShowModal("reset",index)
                    }
                  >
                    Reset
                  </p>
                  <img
                    src={deleteIcon}
                    onClick={() =>
                      handleShowModal("delete",index)
                    }
                    className={"cursor-pointer-global"}
                    alt={"delete"}
                  />
                </div>
              </div>
              {/* All added Metrices  */}
              <div
                className={isDisabled ? "switch-btn-disabled-smart-ads" : ""}
              >
                {rule?.filters?.map((ruleFilter, index) => {
                  return (
                    <div className="row mb-1">
                      {
                        <div
                          className="d-flex col-lg-1  justify-content-center align-items-center cursor-pointer mt-3"
                          onClick={() =>
                            handleRulesActions({
                              action: REMOVE_FILTER,
                              payload: {
                                case: currentCase,
                                filterIndex: index,
                                defaultIndex: parentIndex,
                              },
                            })
                          }
                        >
                          {
                            <img
                              src={cancelMinusBox}
                              className={"automation-cancel-minus-box"}
                              height={20}
                              alt={"Cancel"}
                            />
                          }
                        </div>
                      }
                      <div className="col-lg-4">
                        <CustomDropDown
                          title={"Choose Metric"}
                          classes={"w-100"}
                          data={[
                            "Spends",
                            "Orders",
                            "Clicks",
                            "ROAS",
                            "CVR",
                            "CPC",
                            "Revenue",
                          ]}
                          targettingValue={ruleFilter?.field_name}
                          filterCampaigns={(type, value) =>
                            handleRulesActions({
                              action: UPDATE_FILTER_VALUE,
                              payload: {
                                ruleIndex: parentIndex,
                                filterIndex: index,
                                case: currentCase,
                                filterKey: "field_name",
                                value: type?.target.value,
                              },
                            })
                          }
                          type={"field_name"}
                          src={dropdownIcon}
                        />
                      </div>
                      <div className="col-lg-2">
                        <CustomDropDown
                          title={"Condition"}
                          classes={"w-100"}
                          data={["=", ">", "<",">=","<="]}
                          filterCampaigns={(type, value) =>
                            handleRulesActions({
                              action: UPDATE_FILTER_VALUE,
                              payload: {
                                ruleIndex: parentIndex,
                                filterIndex: index,
                                case: currentCase,
                                filterKey: "operator",
                                value: type?.target.value,
                              },
                            })
                          }
                          targettingValue={ruleFilter?.operator}
                          type={"operator"}
                          src={dropdownIcon}
                        />
                      </div>
                      <div className="col-lg-5">
                        <Stack>
                          <label className="filter-options">Value</label>
                          <div class="input-group input-group-lg w-100">
                            <input
                              type="text"
                              className="form-control automation-rule-input"
                              placeholder="Enter Value Here"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={ruleFilter?.value}
                              onChange={(e) =>
                                handleRulesActions({
                                  action: UPDATE_FILTER_VALUE,
                                  payload: {
                                    ruleIndex: parentIndex,
                                    filterIndex: index,
                                    case: currentCase,
                                    filterKey: "value",
                                    value: e?.target.value?.trim(),
                                  },
                                })
                              }
                            />
                          </div>
                        </Stack>
                      </div>
                    </div>
                  );
                })}

                {/* Add condition Button */}
                <div className="d-flex align-items-center gap-3 cursor-pointer mt-2">
                  <img
                    src={addIcon}
                    onClick={() =>
                      handleRulesActions({
                        action: ADD_FILTER,
                        payload: {
                          case: currentCase,
                          parentIndex,
                        },
                      })
                    }
                    height={20}
                    alt={"Add Condition"}
                  />
                  <span>Add Condition</span>
                </div>
                {/* Increase By Decrease By Field */}
                {!hideIncreaseByField ? (
                  <div className="d-flex gap-3 mt-3 align-items-center">
                    <label>{currentCase==="increaseCase"?"Increase By":"Decrease By"}</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="textBoxWrapper"
                        value={currentCase === "increaseCase" ? rule?.increase_bid : rule?.decrease_bid}
                        onChange={(e) =>
                          handleRulesActions({
                            action: UPDATE_RULE_VALUE,
                            payload: {
                              ruleIndex: parentIndex,
                              filterKey: currentCase === "increaseCase" ? "increase_bid" : "decrease_bid",
                              value: e.target.value?.trim(),
                              case: currentCase,
                            },
                          })
                        }
                      />
                      <span className="position-absolute smart-ads-percent-symbol">
                        %
                      </span>
                    </div>
                  </div>
                ) : null}
                {/* Duration Field  */}
                <div className="automation-rule-duration-container mt-2 gap-4 mb-3 d-flex align-items-center">
                  <label>Duration</label>
                  <CustomDropDown
                    notitle={true}
                    data={["3 Days", "7 Days", "14 Days", "30 Days"]}
                    filterCampaigns={(e) =>
                      handleRulesActions({
                        action: UPDATE_RULE_VALUE,
                        payload: {
                          ruleIndex: parentIndex,
                          filterKey: "rule_duration",
                          value: e.target.value,
                          case: currentCase,
                        },
                      })
                    }
                    targettingValue={rule?.rule_duration}
                    type={"rule_duration"}
                    src={dropdownIcon}
                  />
                </div>
                <hr />
              </div>
            { (showModal && index === currentIndex )? <RuleContainerModal text={text} handleNoClick={handleNoClick} handleYesClick={handleYesClick}/> : null}
            </div>
          );
        })}
        {rules?.length !== maxRules ? (
          <NewRuleButtonn
            text={"Add new Rule"}
            handleClick={() =>
              handleRulesActions({
                action: ADD_RULE,
                payload: { case: currentCase },
              })
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export default RuleContainer;
