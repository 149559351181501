import React from "react";
import searchIcon from "../assets/icons/search_icon.svg";
import "./searchbarForEditCampaign.css";
import { useSelector } from "react-redux";
import { masterApi } from "../apis/masterApi";
import { TOAST_POSITION_TOP_RIGHT, TOAST_DANGER } from "../constant/HardTypes";
import { useState, useRef, useEffect } from "react";
import {toast} from 'react-toastify';
import { useGlobalContext } from "../context/global";

// In This Component Defining the Search bar for different pages and component like navbar , campaign creation pages with different props.

const SearchbarForEditCampaign = (props) => {
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  const [word, setWord] = useState();
  const [currPage, setCurrPage] = useState(0); // storing current page number
  const [prevPage, setPrevPage] = useState(-1); // storing prev page number
  const [wasLastList, setWasLastList] = useState(false); // setting a flag to know the last list
  const { formValues, setFormValues } = useGlobalContext();
  const { isApplied, setIsApplied } = useGlobalContext();
  const { keywords, setKeywords } = useGlobalContext();
  const { matchTypes, setMatchTypes } = useGlobalContext();
  const [showDropDown, setShowDropDown] = useState(false);
  const {
    keywordsToBeShow,
    setKeywordsToBeShow,
    editKeywords,
    editKeywordsToBeShow,
    setEditKeywordsToBeShow,
    setEditKeywords,
    showAlert,
    setButtonStatus,
  } = useGlobalContext();
  const listInnerRef = useRef();
  const initialRender = useRef();

  // Effects

  // useEffect(() => {
  //     if(initialRender.current) {
  //         initialRender.current = false
  //     } else {
  //         handleAPI(word)
  //     }

  // },[word])

  useEffect(() => {
    setKeywordsToBeShow([]);
    setEditKeywordsToBeShow([]);
    setEditKeywords([]);
    setKeywords([]);
  },[])

  useEffect(() => {
    generateKeywordsWithType(
      props.editkeywords ? editKeywords : keywords,
      matchTypes
    );
  }, [matchTypes]);

  // Methods
  useEffect(() => {
    handleAPI(word, true);
  }, [isApplied]);

  const handleAPI = async (value, render) => {
    try {
      if (value.trim() === "") {
        return;
      }
      const data = {
        filterAnd: window.encodeURI(
          `[{"field_name":"keyword","operator":"CONTAINS","value":"${value}"}]`
        ),
        filterOr: window.encodeURI(`${JSON.stringify(formValues)}`),
        source: "amazon",
        limit: "2000",
      };
      setButtonStatus({ status: true, text: "fetching-keywords" });
      const result = await masterApi(data, "keywords", false);
      if (result.statusCode !== 200) {
        setButtonStatus({ status: false, text: "" });
        // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Success",
        //   description: "Error While Fetching Keywords",
        // });
        toast.error('Error While Fetching Keywords')
        return false;
      }

      props.editkeywords
        ? setEditKeywords(result?.data?.data)
        : setKeywords(result?.data?.data);
      if (render === true) {
        generateKeywordsWithType(result?.data?.data, matchTypes);
        setButtonStatus({ status: false, text: "" });
      }
      return result?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const generateKeywordsWithType = (currentWords, currentTypes) => {
    let oneMoreArray = [];
    let newArray = currentWords.map((word) => {
      return currentTypes.map((type) => {
        oneMoreArray.push({ text: word.keyword, match: type });
      });
    });
    props.editkeywords
      ? setEditKeywordsToBeShow((current) => {
          const copy = [...current];
          return oneMoreArray;
        })
      : setKeywordsToBeShow([...oneMoreArray]);
  };

  const handleChange = (e) => {
    setWord(e.target.value);
    if (e.key === "Enter") {
      handleAPI(e.target.value, true);
      generateKeywordsWithType(
        props.editkeywords ? editKeywords : keywords,
        matchTypes
      );
      setShowDropDown(false);
    }
  };

  return (
    <div>
      <div className="search-bar-container2">
        <span className=" search-icon-container">
          <img src={searchIcon} alt={"search"} style={{ zIndex: "100" }}></img>
          {/* <Search color="#4D4184" className="search-icon" /> */}
        </span>

        {props.asins ? (
          <span className="seacrh-input-container">
            <input
              type="text"
              className={
                !dashboardSideBarVisibiltyState
                  ? "seacrh-input-field-asins create-campaign-outline"
                  : "seacrh-input-field-open create-campaign-outline"
              }
              placeholder={props.placeholder}
              id="manual-search"
            />
          </span>
        ) : props.keywords ? (
          <div className="keywords-searchbar-container">
            {" "}
            <span className="seacrh-input-container">
              <input
                type="text"
                className={
                  !dashboardSideBarVisibiltyState
                    ? "seacrh-input-field-keywords create-campaign-outline"
                    : "seacrh-input-field-keywords-open create-campaign-outline"
                }
                onKeyDownCapture={handleChange}
                placeholder={props.placeholder}
                id="manual-search"
              />
            </span>
          </div>
        ) : (
          <span className="seacrh-input-container">
            <input
              type="text"
              className={
                !dashboardSideBarVisibiltyState
                  ? "seacrh-input-field create-campaign-outline"
                  : "seacrh-input-field-open create-campaign-outline"
              }
              placeholder={props.placeholder}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default SearchbarForEditCampaign;
