export const enablePauseSingleChildAd = (currentState,childAdId,status) => {
    const updatedState = currentState.map((parentAd) => {
        parentAd?.childRows?.map((childAd) => {
         if(childAd.id === childAdId) {
           childAd.status = status
           childAd.isTargetChecked = false
         }
         return childAd
       })
       return parentAd
     })
     return updatedState;
}