import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DashCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import MultiSelectCustomDropDown from "../../components/DropDown/MultiSelectCustomDropDown";
import "./productSets.css";
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import { categoriesList } from "./productSetData";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import { Button } from "react-bootstrap";
import { Stack } from "react-bootstrap";
import TableRb from "../../components/Tables/tableRb";
import { generateFiltering } from "../../helper/CampaignList/masterAds/generateFiltering";
import { useGlobalContext } from "../../context/global";
import { encodeData } from "../../helper/common/encodeData";
import { masterApi } from "../../apis/masterApi";
import cancelIcon from '../../assets/icons/Checkbox.svg'
import addIcon from '../../assets/svgs/plus_box.svg'
import ascendingIconSvg from "../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../assets/svgs/descendingIcon.svg";
import CheckboxRb from "../../components/Buttons/checkbox/checkbox";
import { selectAllcheckBoxes } from "../../helper/CampaignList/masterAds/selectAllcheckBoxes";
import whiteFindIcon from "../../assets/icons/white_magnnifineIcon.png";
import MasterTargetingSearchSelectModal from "../../components/modal/masterTargetinngActionnAlertMModal/search&select/MasterTargetingSearchSelectModal";
import removeQuotesFromEncodedURL from '../../helper/common/removeQuotesFromEncodedURL'
import checkDuplicateStringInArray from "../../helper/common/checkDuplicateStringinArray";
import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
  TOAST_DANGER,
} from "../../constant/HardTypes";
import ProductNameTd from "../../components/Tables/TableComponents/productNameTd";
import Spinner from "../../components/spinners/spinner";
import AddIcon from "../../assets/icons/add_box.png";
import MinusIcon from "../../assets/icons/less-box.png";
import SwitchBtn from "../../components/Buttons/switchBtn";
import { enablePauseSingleChildAd } from "../../helper/CampaignList/masterAds/enablePauseSingle";
import { onRowExpandHandler } from "../../helper/CampaignList/masterAds/rowExpandHandler";
import LazyLoadSpinner from "../../components/spinners/LazyLoadSpinner";
import { productsTableData } from "./productSetData";
import { productsTableHead } from "./productSetData";
import settingIcon from "../../assets/icons/overviewSeiingIcon.svg";
import { campaignListActions } from "../../redux/slices/campaignList";
import Loader from "../../components/loader";
import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import CalenderComponent from "../../components/calender/Calender";
import axios from "axios";
import closeIcon from "../../assets/icons/close_icon.png";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import {
  productsSelectedTableData,
  productsSelectedTableHead,
  numberParamaterFields,
  anyParamaterFields
} from "./productSetData";
import { productAttrib, perfmanceMetrices } from "./productSetData";
import dropDownTrigger from "../../assets/svgs/dropdowntrigger.svg";
import downArrow from "../../assets/icons/downArrowForSplitButton.svg";
import DropdownRow from "./DropDownRow";
import productSetDataFiltersParser from "../../helper/rawDataFilters/productSetDataFilters";
import resetIcon from "../../assets/icons/resetIcon.svg";
import useInitialLoad from '../../hooks/useInitialLoad';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from "react-router-dom";
import useInfiniteLoad from "../../hooks/useInfiniteLoad";
import MultiSelect from '../../components/DropDown/Multiselect'
import el from "date-fns/esm/locale/el/index.js";
import {toast} from 'react-toastify';

const CreateProductSet = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const calenderIconRefForCampaignlist = useRef(null);
  const [initialLoad, setInitialLoad] = useInitialLoad();
  const limit = 1000;
  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const { calendarDate, showAlert, dateDifference } = useGlobalContext();
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const navigate = useNavigate();
  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );
  const metricesWithNoDecimal = ["spends", "sale_value", "aov"];
  const metricesWithTwoDecimal = ["cpm", "ctr", "cpc", "cvr", "roas", "acos", "conversion_rate"];
  const dummyDataForLoader = ["row1", "row2", "row3", "row4", "row5", "row6", "row7", "row8", "row9", "row10", "row11", "row12", "row13", "row15", "row16", "row17", "row18", "row19", "row20", "row21", "row22", "row23", "row24", "row25", "row26", "row27", "row28", "row29", "row30", "row31"];
  const [
    multiSelectDropDownSelectedDataCount,
    setMultiSelectDropDownSelecteddDataCount,
  ] = useState({
    amz_targeting_type: 0,
    targeting_objective: 0,
    objective: 0,
  });
  const [allFiltersVisible, setAllFilterVisible] = useState({
    mainFilterLogicModal: false,
    ConfirmAlertModal: false,
    searchSortModal: false,
  });
  const [textValue, setTextValue] = useState("");
  const [deDuplicateValue, setDeDuplicateValue] = useState("No");
  const [logicFilters, setLogicFilters] = useState({
    product_set_type: "Dynamic",
    product_attribute: "-",
    product_criteria: "-",
  });
  const [tableDataCopy, setTableDataCopy] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchFiltersState, setSearchFiltersState] = useState({
    portfolios: [],
    campaigns: [],
    adGroups: [],
    categories: [],
  });
  const [filters, setFilters] = useState({
    product_name: "",
    campaign_type: "All",
    campaign_objective: "Both",
    category: "",
    portfolio_id: "",
    campaign_id: "",
    adgroup_id: "",
    status: "",
    asin: "",
  });

  // Derived Variables From State
  const isCampaignFilterActive = filters?.campaign_id?.length > 0;
  const isPortfolioFilterActive = filters?.portfolio_id?.length > 0;
  const adGroupFilterActive = filters?.adgroup_id?.length > 0;
  const categoryFilterActive = filters?.category?.length > 0;
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selProducts, setSelProducts] = useState([]);
  const [allLoaders, setAllLoaders] = useState({
    mainLoader: false,
    childRowLoader: false,
  });
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [categories, setCategories] = useState([]);
  const [columnName, setColumnName] = useState("");
  const [parentRowsData, setParentRowsData] = useState([]);
  const [sortBy, setSortBy] = useState({ sort_head: "spends", order: "desc" });
  const [statFilters, setStatFilters] = useState([]);
  const [tableDataToShow, setTableDataToShow] = useState([]);
  const [selProductTableDataToShow, setSelProductTableDataToShow] = useState(
    productsSelectedTableData
  );
  const [selProductType, setSelProductType] = useState("Dynamic");
  const [selDuration, setSelDuration] = useState("7 Days");
  const [loader, setLoader] = useState(true);
  const [prodRefreshDuration, setProdRefreshDuration] = useState("7 Days");
  const [productSetName, setProductSetName] = useState("");
  const [dataHeads, setDataHeads] = useState(productsTableHead);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [DropDownDataList, setDropDownDataList] = useState(categoriesList);
  const [productSets, setProductSets] = useState([])
  const [campaignsIds, setCampaignsIds] = useState("");
  const [products, setProducts] = useState([]);
  const [triggerAPI, setTriggerAPI] = useState(false);
  const [productAttributeRowsFilters, setProductAttributeRowsFilters] =
    useState([{ field_name: "-", operator: "-", value: "" }]);
  const [performanceRows, setPerformanceRows] = useState([
    { field_name: "-", operator: "-", value: "" },
  ]);
  const [paginationAction, setPaginationAction] = useState({
    currentPage: 1,
    currentOffSet: 0,
  });
  const [infiniteRef, lazyLoad, handleScroll] = useInfiniteLoad(tableDataToShow, setTableDataToShow, loadMoreData, paginationAction, setPaginationAction, limit)
  const fieldNamesWithPercentSymbol = ["Top Selling", "Top ROAS", "Top Conversion", "Top BSR", "Product Discount"]
  const [deDuplicateAsin,setDeDuplicateAsin] = useState(true)
  const [productAttributeStatus,setProductAttributeStatus] = useState(true);
  const [performanceAttributeStatus,setPerformanceAttributeStatus] = useState(true)

  function filterChange(field_name, operator, value, currentIndex, title) {
    setProductAttributeRowsFilters((copy) => {
      return copy;
    });
  }

  const handleAddRow = (index) => {
    setProductAttributeRowsFilters([
      ...productAttributeRowsFilters,
      { field_name: "-", operator: "-", value: "" },
    ]);
  };

  const handleAddPerformaceRow = (index) => {
    setPerformanceRows([
      ...performanceRows,
      { field_name: "-", operator: "-", value: "" },
    ]);
  };

  const handleRemoveRow = (indexToRemove) => {
    setProductAttributeRowsFilters(
      productAttributeRowsFilters.filter(
        (row, index) => index !== indexToRemove
      )
    );
    const { attributeFilters, performanceFilter } = productSetDataFiltersParser(
      productAttributeRowsFilters,
      performanceRows
    );
    if (attributeFilters?.length) {
      setTriggerAPI(!triggerAPI)
    }
  };

  const handleRemovePerformanceRow = (indexToRemove) => {
    setPerformanceRows(
      performanceRows.filter((row, index) => index !== indexToRemove)
    );
    const { attributeFilters, performanceFilter } = productSetDataFiltersParser(
      productAttributeRowsFilters,
      performanceRows
    );
    if (performanceFilter?.length) {
      setTriggerAPI(!triggerAPI)
    }
  };

  useEffect(() => {
    if (selectedCategory.toString().length > 1) {
      getProducts();
    }
  }, [selectedCategory, selDuration, triggerAPI, sortBy]);

  useEffect(() => {
    setPaginationAction({ ...paginationAction, currentOffSet: 0, currentPage: 1 })
  }, [selectedCategory, selDuration, triggerAPI, sortBy])

  useEffect(() => {
    getAllProductsSets();
  }, [])

  // ********* Method to get Main Table Data

  async function getProducts() {
    setLoader(true);
    const { attributeFilters, performanceFilter } = productSetDataFiltersParser(
      productAttributeRowsFilters,
      performanceRows
    );
    const date_duration = `last_${selDuration?.match(/\d+/g)}_days`;
    const categories = removeQuotesFromEncodedURL(encodeData(selectedCategory?.toString()));
    const productAttributes = productAttributeStatus ? encodeData(attributeFilters) : encodeData([])
    const performanceAttributes = performanceAttributeStatus ? encodeData(performanceFilter) : encodeData([])
    try {
      const dataForAPI = {
        attribute_conditions: productAttributes,
        performance_conditions: performanceAttributes,
        source: "amazon",
        categories: categories,
        sorting_order: sortBy?.order,
        order_by: sortBy?.sort_head,
        duration: date_duration,
        limit: limit,
        page: 1
      };
      const result = await masterApi(
        dataForAPI,
        "getSmartAdsProductsByRules",
        false
      );
      if (result?.statusCode !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Some Error Occured while fetching products",
        // });
        toast.error('Some Error Occured while fetching products')
        setLoader(false);
        setInitialLoad(false)
      }
      if (result?.data) {
        setTableDataToShow([...result?.data]);
      }
      setLoader(false);
      setInitialLoad(false)
    } catch (error) {
      console.log(error);
      setInitialLoad(false)
    }
  }

  // ******* Get already created Product Sets
  async function getAllProductsSets() {
    try {
      const urlForAllProductSets = `${process.env.REACT_APP_ADS_API}/client/${clientId}/product-set?source=amazon&categories`
      const result = await axios.get(urlForAllProductSets)
      if (result?.data) {
        setProductSets([...result?.data])
      }
    } catch (error) {
      console.log(error)
    }
  }

  // ******** Method to create a new Product Set
  async function createNewSet() {
    const { attributeFilters, performanceFilter } = productSetDataFiltersParser(
      productAttributeRowsFilters,
      performanceRows
    );
    const date_duration = `last_${selDuration?.match(/\d+/g)}_days`;
    const date_duration_refresh = `${prodRefreshDuration?.match(
      /\d+/g
    )}_days`;
    // const duplicateAsinVal = deDuplicateValue === "No" ? false : true;
    const productAttributes = productAttributeStatus ? attributeFilters : []
    const performanceAttributes = performanceAttributeStatus ? performanceFilter : []
    try {
      const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/product-set`
      const dataToPost = {
        name: productSetName,
        source: "amazon",
        attribute_conditions: productAttributes,
        performance_conditions: performanceAttributes,
        product_set_type: "amazon_smart_product_set",
        de_duplication: deDuplicateAsin,
        is_dynamic: true,
        duration: date_duration,
        refresh_duration: date_duration_refresh,
        categories: selectedCategory,
      };

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataToPost)
      }

      // Checker 

      const { checker, requiredFields } = validateProductSet(dataToPost)

      // If all data Checks are pass then call API
      if (checker) {
        setAllLoaders({ ...allLoaders, mainLoader: true })
        const response = await fetch(url, options)
        if (response.status !== 200) {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: "Some Unknown Error Occured while creating smart Ads",
          // });
          toast.error('Some Unknown Error Occured while creating smart Ads')
        } else {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_SUCCESS,
          //   title: "Success",
          //   description: `${productSetName} Smart Ad Created Successfully`,
          // });
          toast.success(`${productSetName} Smart Ad Created Successfully`)
          navigate("/product-set-list")
        }
        setAllLoaders({ ...allLoaders, mainLoader: false })
      } else {
        const fieldsToFill = requiredFields.join("\n")
        alert(`Please Fill Mandatory Fields:-\n ${fieldsToFill}`)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validateProductSet = (dataToPost) => {
    let checker = true;
    const requiredFields = [];
    if (dataToPost?.name === "") {
      checker = false
      requiredFields.push("Product Set Name cannot be empty")
    }

    if (dataToPost?.categories?.length === 0) {
      checker = false
      requiredFields.push("Categories Cannot be empty")
    }

    const allProductSetsName = productSets?.map((data) => data?.name)

    if (dataToPost?.name !== "") {
      if (checkDuplicateStringInArray(dataToPost?.name, allProductSetsName)) {
        checker = false
        requiredFields.push("Product Set Name Already Exists Try Different One!")
      }
    }

    return { checker, requiredFields }
  }

  const getUpdatedDrowDownFilters = (value, key) => {
    const filterKey =
      key === "campaign_id"
        ? "platform_id"
        : key === "adgroup_id"
          ? "platform_id"
          : key === "category"
            ? "name"
            : "platform_id";
    const dataToBeSet = value;
    // (value !=="") ? value?.map((data) => {
    //   return key === "category" ? data[filterKey] : parseInt(data[filterKey]);
    // }):""
    if (key === "campaign_id") {
      const campaignIdsData =
        value !== ""
          ? value?.map((data) => {
            return data?.id;
          })
          : "";
      setCampaignsIds(campaignIdsData);
    }
    setFilters({ ...filters, [key]: dataToBeSet });
  };

  const onSearchModalHandler = (input) => {
    setAllFilterVisible({ ...allFiltersVisible, searchSortModal: true });
    setColumnName(input);
  };

  const onAllAdSelection = (e) => {
    const updatedState = selectAllcheckBoxes(tableDataToShow, e.target.checked);
    setTableDataToShow([...updatedState]);
  };

  const onSortHandler = (
    initialData,
    condition,
    status,
    type,
    currentIndex
  ) => {
    // // FilterData Sorting.
    // const filteredData = sortData(initialData, condition, status, type);
    // // Set the Heads Flag to true or false
    let convertedData = JSON.parse(JSON.stringify(dataHeads));
    convertedData = convertedData.map((head) => {
      if (head.id === status) {
        head.isSortingClicked = !head.isSortingClicked;
      } else {
        head.isSortingClicked = false;
      }
      return head;
    });
    setSortBy({
      ...sortBy,
      sort_head: status,
      order: condition ? "desc" : "asc",
    });
    // // Update Necessary States
    setDataHeads(convertedData);
  };

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  // Function to convert Filters Values into Single Array

  function mapValues(obj, filterKey) {
    const keysArray = ["campaign_id", "portfolio_id", "adgroup_id", "category"];
    const newObj = { ...obj };
    for (const key in newObj) {
      if (typeof newObj[key] === "object" || Array.isArray(newObj[key])) {
        if (keysArray.includes(key) && newObj[key].length > 0) {
          newObj[key] = newObj[key].map((item) =>
            key === "category" ? item["name"] : item[filterKey]
          );
        }
      }
    }
    return newObj;
  }

  async function loadMoreData(currentOffset, setLazyLoad) {
    setLazyLoad(true);
    const { attributeFilters, performanceFilter } = productSetDataFiltersParser(
      productAttributeRowsFilters,
      performanceRows
    );
    const date_duration = `last_${selDuration?.match(/\d+/g)}_days`;
    const categories = removeQuotesFromEncodedURL(encodeData(selectedCategory?.toString()));
    try {
      const dataForAPI = {
        attribute_conditions: encodeData(attributeFilters),
        performance_conditions: encodeData(performanceFilter),
        source: "amazon",
        categories: categories,
        sorting_order: sortBy?.order,
        order_by: sortBy?.sort_head,
        duration: date_duration,
        limit: limit,
        page: currentOffset?.page
      };
      const result = await masterApi(
        dataForAPI,
        "getSmartAdsProductsByRules",
        false
      );
      setLazyLoad(false)
      if (result?.data) {
        return result?.data
      }
    } catch (error) {
      console.log(error);
      setInitialLoad(false)
    }
  };


  let tableBody = (
    <>
      <div
        className="main-campaignlist-table-body-container master-ads-main-table-wrapper product-set-table"
        onScroll={handleScroll}
        ref={infiniteRef}
      >
        {tableDataToShow?.length || loader ? (
          <>
            <table className="master-ads-table">
              <thead className="fixed-table">
                <tr className="main-table-row-fixed">
                  {dataHeads?.map((th, thIndex) => {
                    // th means tableHead
                    return (
                      <th
                        key={th.fieldName + thIndex + "12"}
                        className={
                          th.id === sortBy.sort_head ||
                            (isCampaignFilterActive &&
                              th.fieldName === "Campaign")
                            ? " targeting-table-heads "
                            : "targeting-table-heads"
                          // th.isSortFunctionalityNeededHere ? "stats-heads targeting-table-heads"
                          // : "targeting-table-heads"
                          // stats-heads
                        }
                      >
                        {th.isSortFunctionalityNeededHere ? (
                          <div className="d-flex flex-row align-items-center justify-content-end ">
                            <div className="d-flex align-items-center">
                              <span
                                className={
                                  th.id === sortBy.sort_head
                                    ? "stat-heads-with-icon active-filter-higlighted-text"
                                    : "stat-heads-with-icon"
                                }
                              >
                                {th.fieldName}
                              </span>
                            </div>
                            <span className="sort-icon cursor-pointer-global">
                              <img
                                className={
                                  th.id === sortBy.sort_head
                                    ? " d-flex align-items-end mt-spends-icon-activated "
                                    : " d-flex align-items-end"
                                }
                                src={
                                  !th.isSortingClicked
                                    ? descendingIconSvg
                                    : ascendingIconSvg
                                }
                                onClick={() =>
                                  onSortHandler(
                                    tableDataToShow,
                                    th.isSortingClicked,
                                    th.id,
                                    "masterAds",
                                    thIndex
                                  )
                                }
                                alt={"HeadsWithSorting"}
                              ></img>
                            </span>
                          </div>
                        ) : th.fieldName === "Product" ? (
                          <>
                            <div className="targeting-selected-head">
                              <span className="">
                                <div className="targeting-checkbox d-flex justify-content-start">
                                  <CheckboxRb
                                    className="check-colum-head"
                                    onChange={(e) => onAllAdSelection(e)}
                                    label={th.fieldName}
                                  // checked={allRowsSelected}
                                  ></CheckboxRb>
                                </div>
                              </span>
                              {/* <span className="stat-heads-with-icon">{th.fieldName}</span> */}
                            </div>
                          </>
                        ) : th.fieldName === "Portfolio" ||
                          th.fieldName === "Campaign" ||
                          th.fieldName === "Category" ||
                          th.fieldName === "Ad Group" ? (
                          <>
                            <div
                              className={`fieldnamme-with-search-iconn justify-content-start`}
                            >
                              <span className="d-flex align-items-center me-2">
                                <img
                                  // className="find-icon cursor-pointer-global"
                                  className={
                                    (isCampaignFilterActive &&
                                      th.fieldName === "Campaign") ||
                                      (isPortfolioFilterActive &&
                                        th.fieldName === "Portfolio") ||
                                      (adGroupFilterActive &&
                                        th.fieldName === "Ad Group") ||
                                      (categoryFilterActive &&
                                        th.fieldName === "Category")
                                      ? "find-icon cursor-pointer-global mt-find-icon-activated"
                                      : "find-icon cursor-pointer-global"
                                  }
                                  src={whiteFindIcon}
                                  onClick={() =>
                                    onSearchModalHandler(th.fieldName)
                                  }
                                  alt={"whiteFindIcon"}
                                ></img>
                              </span>
                              <div className="d-flex align-items-center test">
                                <span
                                  className={
                                    (isCampaignFilterActive &&
                                      th.fieldName === "Campaign") ||
                                      (isPortfolioFilterActive &&
                                        th.fieldName === "Portfolio") ||
                                      (adGroupFilterActive &&
                                        th.fieldName === "Ad Group") ||
                                      (categoryFilterActive &&
                                        th.fieldName === "Category")
                                      ? "active-filter-higlighted-text"
                                      : "stat-heads-with-icon "
                                  }
                                >
                                  {th.fieldName}
                                </span>
                              </div>
                              {columnName === th.fieldName &&
                                allFiltersVisible.searchSortModal && (
                                  <MasterTargetingSearchSelectModal
                                    findClickedColumnName={columnName}
                                    keyToBeSet={
                                      th.fieldName === "Campaign"
                                        ? "campaign_id"
                                        : th.fieldName === "Ad Group"
                                          ? "adgroup_id"
                                          : th.fieldName === "Portfolio"
                                            ? "portfolio_id"
                                            : "category"
                                    }
                                    getUpdatedDrowDownFilters={
                                      getUpdatedDrowDownFilters
                                    }
                                    isVisible={
                                      allFiltersVisible.searchSortModal
                                    }
                                    initialData={
                                      th.fieldName === "Campaign"
                                        ? searchFiltersState.campaigns
                                        : th.fieldName === "Ad Group"
                                          ? searchFiltersState.adGroups
                                          : th.fieldName === "Portfolio"
                                            ? searchFiltersState.portfolios
                                            : categories
                                    }
                                    cancelFilter={setAllFilterVisible}
                                    currentFilters={allFiltersVisible}
                                  />
                                )}
                            </div>
                          </>
                        ) : (
                          <>
                            <span>{th.fieldName}</span>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {tableDataToShow.length || loader ? (
                <tbody className="another-body">
                  {(tableDataToShow?.length && !loader) ? (
                    tableDataToShow?.map((parentAd, paIndex) => {
                      return (
                        <React.Fragment key={parentAd?.asin}>
                          <tr>
                            <td className="first-column-sw-btn ads-td-first">
                              <div className="classlist-switch-btn">
                                {
                                  <SwitchBtn
                                    checked={parentAd?.isEnabled}
                                    disabled={true}
                                  // onChange={() => {
                                  //   enablePauseSingleAd(parentAd?.asin);
                                  // }}
                                  />
                                }
                              </div>
                            </td>
                            <td className="ads-td-second tableProductText ">
                              <LazyLoadImage
                                src={parentAd?.image_url}
                                width="30px"
                                height="30px"
                                alt="product"
                              />
                              <span className="tableProductText">
                                {parentAd?.name}
                              </span>
                            </td>
                            <td>{parentAd?.asin}</td>

                            {dataHeads?.map((currentDataHead, i) => {
                              if (!currentDataHead.isDefaultHead) {
                                return (
                                  <td key={i + 11} className="stats-data-col">
                                    {
                                      parentAd[currentDataHead?.id]
                                        ? typeof parentAd[
                                          currentDataHead.id
                                        ] === "number" &&
                                          metricesWithNoDecimal.includes(
                                            currentDataHead.id
                                          )
                                          ? Math.round(
                                            parentAd[currentDataHead.id]
                                          )
                                          : typeof parentAd[
                                            currentDataHead.id
                                          ] === "number" &&
                                            metricesWithTwoDecimal.includes(
                                              currentDataHead.id
                                            )
                                            ? parentAd[
                                              currentDataHead.id
                                            ].toFixed(2)
                                            : parentAd[currentDataHead.id]
                                        : "-"
                                      // campStatInst ? campStatInst[currentDataHead.id] : '-'
                                    }
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <>
                      {loader
                        ? dummyDataForLoader.map((row) => {
                          return (
                            <tr key={row}>
                              {dataHeads?.map((currentDataHead, i) => {
                                return (
                                  <td key={currentDataHead.id} className="stats-data-col">
                                    {/* <div className="ma-loader"> */}
                                    <div className="td-with-loader">
                                      <Spinner shimmer={true} />
                                      {/* </div> */}
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })
                        : null}
                    </>
                  )}
                </tbody>
              ) : (
                (!tableDataToShow.length && !loader) && <p>None of the products meet the specified criteria.
                </p>
              )}
            </table>
            {lazyLoad && (
              <div className="lazy-loading-container d-flex p-5 justify-content-center align-items-center w-100">
                <LazyLoadSpinner text={`Loading ${limit} More Products...`} />
              </div>
            )}
          </>
        ) : (
          <>{(!tableDataToShow.length && !loader) && <p>None of the products meet the specified criteria.
            </p>}</>
        )}
      </div>
    </>
  );

  const onFilterIconhandler = () => {
    dispatch(campaignListActions.showFilterModalInCampaignList());
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const handleCreateProductSet = () => {
    if (selectedCategory?.length) {
      createNewSet()
    }
  };

  // API Calls Methods

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/categories?source=amazon&fields=name&limit=1000`
      );
      const result = await response.json();

      if (response?.status !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Unable To Fetch Categories",
        // });
        toast.error('Unable To Fetch Categories')
      }
      if (result?.data?.length > 0) {
        setCategories(result?.data ?? []);
        const modifiedCategories = result?.data?.map((value) => value?.name);
        setSelectedCategory(modifiedCategories);
      } else if (result?.data?.length === 0) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Categories Not Found",
        // });
        toast.error('Categories Not Found')
      }
    } catch (error) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `Unable To Fetch Categories`,
      // });
      toast.error('Unable To Fetch Categories')
    }
  };

  function calculateDiscountPercentage(sellingPrice, mrp) {
    if (sellingPrice >= mrp) {
      return 0; // no discount
    }

    var discountAmount = mrp - sellingPrice;
    var discountPercentage = (discountAmount / mrp) * 100;
    return discountPercentage.toFixed(2); // round to 2 decimal places
  }

  const fetchPerformanceMetricesForProducts = async (products) => {
    setAllLoaders({ ...allLoaders, mainLoader: true });
    const data = await Promise.all(
      products.map(async (product) => {
        const filteringToBeSent = [
          { field_name: "asin", operator: "IN", values: [product?.asin] },
        ];
        const parentRowData = {
          source: "amazon",
          master_row: "true",
          sorting_order: "desc",
          order_by: "spends",
          start_date: `${formatDateFn(calendarDate[0].startDate)}`,
          end_date: `${formatDateFn(calendarDate[0].endDate)}`,
          limit: 50,
          offset: 0,
          filtering: encodeData(filteringToBeSent),
        };
        const result = await masterApi(parentRowData, "masterAds", false);
        let mainProduct;
        if (result?.data?.data) {
          mainProduct = {
            ...product,
            ...result?.data?.data[0],
            discount: calculateDiscountPercentage(
              product?.sale_price,
              product?.maximum_retail_price
            ),
            isEnabled: false,
          };
        }
        return mainProduct;
      })
    );
    setAllLoaders({ ...allLoaders, mainLoader: false });
    setTableDataToShow(data);
    setTableDataCopy(data);
  };

  const handleDropDownModal = (e) => {
    e.preventDefault();
    setAllFilterVisible({
      ...allFiltersVisible,
      searchSortModal: !allFiltersVisible.searchSortModal,
    });
  };

  const applyHandler = (categories) => {
    if (categories.length > 0) {
      const modifiedCategories = categories?.map((value) => value?.name);
      setSelectedCategory(modifiedCategories);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    const data = productSetDataFiltersParser(
      productAttributeRowsFilters,
      performanceRows
    );
    // setTableDataToShow(data);
  }, [productAttributeRowsFilters, performanceRows]);

  const handleTextChange = (event, currentIndex, type, fieldName) => {
    const copy = JSON.parse(
      JSON.stringify(
        type === "performance" ? performanceRows : productAttributeRowsFilters
      )
    );
    const isNumberField = numberParamaterFields.includes(fieldName)
    const isNormalField = anyParamaterFields?.includes(fieldName)
    const isNumber = validateNumber(event.target.value) || event.target.value === ""
    const modifiedData = copy?.map((product, index) => {
      if (index === currentIndex) {
        if (isNumberField) {
          if (isNumber) {
            product.value = event.target.value
          }
        } else {
          if (!validateNumber(event.target.value) || isNormalField) {
            product.value = event.target.value;
          }
        }
      }
      return product;
    });
    if (type === "performance") {
      setPerformanceRows(modifiedData);
    } else {
      setProductAttributeRowsFilters(modifiedData);
    }
  };

  const resetConditionalFilters = (type) => {
    if (type === "performance") {
      setPerformanceRows([{ field_name: "-", operator: "-", value: "" }]);
    } else {
      setProductAttributeRowsFilters([
        { field_name: "-", operator: "-", value: "" },
      ]);
    }
    setTriggerAPI(!triggerAPI);
  };

  const filterCampaigns = (e, type, currentIndex, filterFor) => {
    const copy = JSON.parse(
      JSON.stringify(
        filterFor === "performance"
          ? performanceRows
          : productAttributeRowsFilters
      )
    );
    const modifiedData = copy?.map((product, index) => {
      if (index === currentIndex) {
        if (type.includes("attribute")) {
          product.field_name = e.target.value;
        } else {
          product.operator = e.target.value;
        }
      }
      return product;
    });
    if (filterFor === "performance") {
      setPerformanceRows(modifiedData);
    } else {
      setProductAttributeRowsFilters(modifiedData);
    }
    // Set Specific DropDownRows Values
    setProductAttributeRowsFilters((data) => {
      return [...data]?.map((el, index) => {
        if (currentIndex === index && e.target.value === "Cut Size *") {
          el.value = "50"
          el.operator = "="
        }

        if (currentIndex === index && e.target.value === "Products not advertised") {
          el.value = "paused"
          el.operator = "="
        }
        return el
      })
    })

    // If user is choosing from these then directly trigger API
    if (e.target.value === "Cut Size *" || e.target.value === "Products not advertised") {
      setTriggerAPI(!triggerAPI)
    }

    // If user is changing the attribute and value is not from below array then remove value and trigger API call
    const dataForChoosen = ["Cut Size *", "Products not advertised"]
    if (type.includes('attribute') && filterFor !== "performance") {
      setProductAttributeRowsFilters((data) => {
        return [...data]?.map((el, index) => {
          if (currentIndex === index && (!dataForChoosen.includes(e.target.value)) && el.value !== "" && el.operator !== "-") {
            el.operator = "-"
            el.value = ""
            setTriggerAPI(prev => !prev)
          }
          return el
        })
      })

    }

    // Change Operators while selecting From below array in performanceRows
    const fieldNames = ["Top Selling", "Top ROAS", "Top Conversion"]

    if (filterFor === "performance") {
      setPerformanceRows((prev) => {
        return [...prev]?.map((element, index) => {
          const isCurrentIndex = index === currentIndex
          const isInFieldNames = fieldNames?.includes(e.target.value)
          if (isCurrentIndex && isInFieldNames) {
            element.operator = "-"
          }
          return element
        })
      })
    }

  };

  const onDropdownImghandler = (type) => {
    console.log("@$# onDropdownImghandler");
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setTriggerAPI(!triggerAPI)
    }
  }

  function getDropDownDataAccordingToSelectedField(field) {
    const allOperators = [">", "<", "=", "contains", "not contains"]
    switch (field) {
      case "Product Name":
        return ["contains", "not contains"];
      case "-":
        return ["-"];
      case "Gender":
        return ["=", "contains", "not contains"];
      case "Products not advertised":
        return ["="];
      case "Product Market - Size":
        return ["contains", "="];
      case "Top ROAS":
        return ["="];
      case "Top Selling":
        return ["="];
      case "Top Conversion":
        return ["="];
      case "Top BSR":
        return ["="]
      case "BSR":
        return [">", "<"]
      case "Cut Size *":
        return ["="];
      case "Product - Color":
        return ["=", "contains", "not contains"];
      case "Product - Size":
        return ["=", "contains", "not contains"];
      case "Product MRP":
        return ["=", ">", "<"]
      case "Product SP":
        return ["=", ">", "<"]
      case "Product Discount":
        return ["=", ">", "<"]
      case "Revenue":
        return ["=", ">", "<"]
      case "Orders":
        return ["=", ">", "<"]
      case "CTR":
        return ["=", ">", "<"]
      case "ROAS":
        return ["=", ">", "<"]
      case "Conversion Rate":
        return ["=", ">", "<"]
      case "Impressions":
        return ["=", ">", "<"]
      case "Spends":
        return ["=", ">", "<"]
      case "Clicks":
        return ["=", ">", "<"]
      default:
        return allOperators
    }
  }

  const handleOutSideInputClick = () => {
    setTriggerAPI(!triggerAPI)
  }

  function validateNumber(input) {
    return (!isNaN(input) && input !== "") ? true : false
  }

  function handleProductSetAttributeStatus () {
    const { attributeFilters, performanceFilter } = productSetDataFiltersParser(
      productAttributeRowsFilters,
      performanceRows
    );
    setProductAttributeStatus(!productAttributeStatus)
    if(attributeFilters.length) {
      setTriggerAPI(!triggerAPI)
    }
  }

  function handlePerformanceSetStatus () {
    const { attributeFilters, performanceFilter } = productSetDataFiltersParser(
      productAttributeRowsFilters,
      performanceRows
    );
    setPerformanceAttributeStatus(!performanceAttributeStatus)
    if(performanceFilter.length) {
      setTriggerAPI(!triggerAPI)
    }
  }

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={allLoaders.mainLoader} />
      <p className="pageTitleText mb-3">1/3 Product Set Details</p>
      <div className="card-style-new">
        <div className="row">
        <div className="col-lg-6">
          <p className="dropdown-text">Choose Product Set Type</p>
              <CustomDropDown
              title={""}
              data={["Dynamic"]}
              filterCampaigns={(event) => setSelProductType(event.target.value)}
              classes={"w-100"}
              noTitle={true}
              targettingValue={selProductType}
              type={"-"}
              src={dropdownIcon}
              fontSize={"12px"}
              btnBgColor="#DBD9E6"
              borderRadius="0px 3px 3px 0px"
              menuZindex={100}
            // onDropdownImghandler={onDropdownImghandler}
            // data
            />
          </div>
          <div className="col-lg-6">
          <p className="dropdown-text">Choose Product Category</p>
          <div
          className="select-button-container w-100 select-div"
          onClick={handleDropDownModal}
        >
          <button className="selected-opt-show-btn">{"Choose Category"}</button>
          <button className="img-container">
            <img
              src={downArrow}
              className={"dropdown-img-for-select"}
              alt="DropDown For Select"
            />
          </button>
        </div>
        <div className="modal-select-search-wrapper position-relative">
          <MasterTargetingSearchSelectModal
            dynamicWidth={true}
            defaultSelected={true}
            defaultSelectedValue={selectedCategory}
            findClickedColumnName={"Category"}
            isVisible={allFiltersVisible.searchSortModal}
            currentFilters={allFiltersVisible}
            initialData={categories}
            cancelFilter={setAllFilterVisible}
            getUpdatedDrowDownFilters={applyHandler}
          />
        </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="pageTitleText marginTop20">
          2/3 Product Set Rules
        </p>
      </div>
      {/* <div className="d-flex">
        <div className="" style={{height:"300px" , border:"1px solid red"}}>Hello world</div>
        <div>Prabadhya</div>
      </div> */}
      <div className="d-flex gap-3">
        <div className="col-lg-6">
        <div className="products-rules-set-container">
        <div className="reset-container">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <SwitchBtn checked={productAttributeStatus} onChange={() => handleProductSetAttributeStatus()} classNameForFormSwitch={"switch-btn-for-smart-ads"} />
              <p className="mb-0 dropdown-text">Set Product Attribute Conditions</p>
            </div>
            <button
          className="primary-inactive-btn reset-btn-for-filter-btn"
          onClick={() => resetConditionalFilters()}
        >
          <span>
            <img className="reset-icon-img" alt="reset" src={resetIcon}></img>
          </span>
          <span className="text-color-for-reset-btn">Reset</span>
        </button>
          </div>
        </div>
        <div className="conditions-container">
          <p>IF THE FOLLOWING IS TRUE</p>
          <div className="conditions-dropdown-container">
            {
              productAttributeRowsFilters?.map((row,index) => {
                const operatorsField = getDropDownDataAccordingToSelectedField(row.field_name);
                const isPercentSymbolRequired = fieldNamesWithPercentSymbol?.includes(row.field_name)
                return (
                  // <div key={index+"12"} className={"d-flex gap-3 align-items-center"}>
                    <div className={!productAttributeStatus ? "row switch-btn-disabled-smart-ads d-flex align-items-center mb-3" :"row d-flex align-items-center mb-3"}>
                    {
                         <div className="col-lg-1">
                          <div
                            className={ !productAttributeStatus ?  "d-flex justify-content-center align-items-center switch-btn-disabled-smart-ads cursor-pointer" : "d-flex justify-content-center align-items-center cursor-pointer"}
                            onClick={() => handleRemoveRow(index)}
                          >
                            {
                              <img
                                src={cancelIcon}
                                height={20}
                                alt={"Cancel"}
                              />
                            }
                          </div>
                         </div> 
                        }
                        <div className="col-lg-4">
                        <CustomDropDown
                      title={""}
                      noTitle={true}
                      classes={"w-100"}
                      data={productAttrib}
                      filterCampaigns={(value, type) =>
                        filterCampaigns(value, type, index, row.field_name)
                      }
                      targettingValue={row.field_name}
                      type={"product_attribute"}
                      src={dropdownIcon}
                      fontSize={"12px"}
                      onDropdownImghandler={onDropdownImghandler}
                      btnBgColor="#DBD9E6"
                      borderRadius="0px 3px 3px 0px"
                      menuZindex={100}
                    />
                        </div>
                        {
                        row.field_name!=="Products not advertised" ? (<>
                        <div className="col-lg-3">
                        <CustomDropDown
                        title={""}
                        noTitle={true}
                        classes={"w-100"}
                        data={[...operatorsField]}
                        filterCampaigns={(value, type) =>
                          filterCampaigns(value, type, index)
                        }
                        targettingValue={row.operator}
                        type={"product_criteria"}
                        src={dropdownIcon}
                        fontSize={"12px"}
                        onDropdownImghandler={onDropdownImghandler}
                        btnBgColor="#DBD9E6"
                        borderRadius="0px 3px 3px 0px"
                        menuZindex={100}
                      />
                        </div>
                        <div className="col-lg-4">
                        <div className="position-relative">
                          <input
                            type="text"
                            value={row?.value}
                            disabled={row.field_name === "Cut Size *"}
                            onChange={(e) => handleTextChange(e, index, "attribute", row.field_name)}
                            onBlur={(e) => handleOutSideInputClick(e)}
                            onKeyDown={handleEnter}
                            className="textBoxWrapper"
                          />
                          {isPercentSymbolRequired ? <span className="position-absolute smart-ads-percent-symbol">%</span> : null}
                        </div>
                        </div>
                          </> ):null
                        }
                    </div>                
                    )
              })
              
            }
            <div className="row d-flex align-items-center">
              <div className="col-lg-1">
              <div className={!productAttributeStatus ? "d-flex justify-content-center align-items-center switch-btn-disabled-smart-ads  cursor-pointer" :"d-flex justify-content-center align-items-center cursor-pointer"}>
                      <img
                        src={addIcon}
                        onClick={() => handleAddRow()}
                        height={20}
                        alt={"Add Condition"}
                      />
               </div>
              </div>
            </div>
          
          </div>
          <hr/>
          <div className="empty-duration-container"></div>
        </div>
      </div>
        </div>
        <div className="col-lg-6">
          <div className="h-100">
        <div className="products-rules-set-container">
        <div className="reset-container">
          <div className="d-flex justify-content-between">
          <div className="d-flex justify-space-between">
            <div className="d-flex align-items-center gap-2">
              <SwitchBtn checked={performanceAttributeStatus} onChange={() => handlePerformanceSetStatus()}  classNameForFormSwitch={"switch-btn-for-smart-ads"}/>
              <p className="mb-0 dropdown-text">Set Performance Conditions</p>
            </div>
          </div>
          <button
          className="primary-inactive-btn reset-btn-for-filter-btn"
          onClick={() => resetConditionalFilters("performance")}
        >
          <span>
            <img className="reset-icon-img" alt="reset" src={resetIcon}></img>
          </span>
          <span className="text-color-for-reset-btn">Reset</span>
        </button>
          </div>
       
        </div>
        <div className="conditions-container">
          <p>IF THE FOLLOWING IS TRUE</p>
          <div className="conditions-dropdown-container">
            {
              performanceRows?.map((row,index) => {
                const operatorsField = getDropDownDataAccordingToSelectedField(row.field_name);
                const isPercentSymbolRequired = fieldNamesWithPercentSymbol?.includes(row.field_name)
                return (
                  <div className={!performanceAttributeStatus ? "row d-flex switch-btn-disabled-smart-ads align-items-center mb-3" :"row d-flex align-items-center mb-3"}>
                      <div className="col-lg-1">
                          <div
                            className={!performanceAttributeStatus ?"d-flex justify-content-center switch-btn-disabled-smart-ads align-items-center cursor-pointer":"d-flex justify-content-center align-items-center cursor-pointer"}
                            onClick={() => handleRemovePerformanceRow(index)}
                          >
                            {
                              <img
                                src={cancelIcon}
                                height={20}
                                alt={"Cancel"}
                              />
                            }
                          </div>
                      </div> 
                      <div className="col-lg-4">
                      <CustomDropDown
                      title={"Product Attributes"}
                      noTitle={true}
                      data={perfmanceMetrices}
                      filterCampaigns={(value, type) =>
                        filterCampaigns(value, type, index, "performance")
                      }
                      targettingValue={row.field_name}
                      classes={"w-100"}
                      type={"product_attribute"}
                      src={dropdownIcon}
                      fontSize={"12px"}
                      onDropdownImghandler={onDropdownImghandler}
                      btnBgColor="#DBD9E6"
                      borderRadius="0px 3px 3px 0px"
                      menuZindex={100}
                    />
                      </div>
                      <div className="col-lg-3">
                      <CustomDropDown
                      noTitle={true}
                      data={[...operatorsField]}
                      filterCampaigns={(value, type) =>
                        filterCampaigns(value, type, index, "performance")
                      }
                      targettingValue={row.operator}
                      type={"product_criteria"}
                      classes={"w-100"}
                      src={dropdownIcon}
                      fontSize={"12px"}
                      onDropdownImghandler={onDropdownImghandler}
                      btnBgColor="#DBD9E6"
                      borderRadius="0px 3px 3px 0px"
                      menuZindex={100}
                    />
                      </div>
                      <div className="col-lg-4">
                      <div className="position-relative">
                      <input
                        type="text"
                        value={row.value}
                        onChange={(e) => handleTextChange(e, index, "performance", row.field_name)}
                        onBlur={(e) => handleOutSideInputClick(e)}
                        onKeyDown={handleEnter}
                        className="textBoxWrapper"
                      />
                      {isPercentSymbolRequired ? <span className="position-absolute smart-ads-percent-symbol">%</span> : null}
                    </div>
                      </div>
                  </div>
                )
              })
            }
            <div className="row d-flex align-items-center">
              <div className="col-lg-1">
              <div className={!performanceAttributeStatus ?"d-flex justify-content-center switch-btn-disabled-smart-ads align-items-center cursor-pointer":"d-flex justify-content-center align-items-center cursor-pointer"}>
                      <img
                        src={addIcon}
                        onClick={() => handleAddPerformaceRow()}
                        height={20}
                        alt={"Add Condition"}
                      />
               </div>
              </div>
            </div>
          </div>
         <hr/>
         <div className="empty-duration-container d-flex gap-3 align-items-center">
                <small>Duration</small>
                <CustomDropDown
                  noTitle={true}
                  data={["1 Day", "3 Days", "7 Days", "14 Days"]}
                  filterCampaigns={(event) => setSelDuration(event.target.value)}
                  targettingValue={selDuration}
                  type={"-"}
                  src={dropdownIcon}
                  fontSize={"12px"}
                  btnBgColor="#DBD9E6"
                  borderRadius="0px 3px 3px 0px"
                  menuZindex={100}
                />
         </div>
        </div>
      </div>
      </div>
        </div>
      </div>
      
        {tableDataToShow?.length ? <small className="text-muted smart-ads-desc-text text-start d-block mt-2 px-1">{`${tableDataToShow?.length} Selected Products Under Product Set As Per Conditions Set Above`}</small> : null}
        <div className="master-ads-table-wrapper">
          <Stack className="master-ads-main-table">
            <TableRb noDataFound={!tableDataToShow?.length && !loader} shimmer={loader} tableHeads={dataHeads} noDataFoundDescription={"“None of the products meet the specified criteria”"} table={tableBody}></TableRb>
          </Stack>
        </div>
      <div className="d-flex justify-content-between">
      <p className="pageTitleText marginTop20">
          3/3 Product Set Configuration
        </p>
      </div>
      <div className="card-style-new">
        <div className="row">
          <div className="col-lg-6">
            <p className="dropdown-text">Product Set Name</p>
            <input
              type="text"
              value={productSetName}
              onChange={(event) => setProductSetName(event.target.value)}
              className="textBoxWrapper"
            />
          </div>
          <div className="col-lg-6">
          <p className="dropdown-text">Product Set Refresh Frequency</p>
                <CustomDropDown
                  title={""}
                  noTitle={true}
                  classes="w-100"
                  data={["1 Day", "3 Days", "7 Days", "14 Days", "21 Days"]}
                  filterCampaigns={(event) =>
                    setProdRefreshDuration(event.target.value)
                  }
                  targettingValue={prodRefreshDuration}
                  type={"-"}
                  src={dropdownIcon}
                  fontSize={"12px"}
                  btnBgColor="#DBD9E6"
                  borderRadius="0px 3px 3px 0px"
                  menuZindex={100}
                />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <CheckboxRb className={"child"} onChange={() => setDeDuplicateAsin(!deDuplicateAsin)} checked={deDuplicateAsin} classes={"checkbox-container-nowidth"}/>
          <p className="mb-0 dropdown-text">De Duplication Of ASIN's</p>
        </div>
      </div>

            <div
            className={

                 "d-flex justify-content-end gap-10 keyword-targeting"
            }
            style={{ marginTop: "35px" }}
          >
            <button
              type="button"
              className="primary-outline-btn btn btn-outline-primary px-5"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
            <Button
              insideBtnText="Create Product Set"
              disabled={allLoaders?.mainLoader}
              id={"create-product-set-btn"}
              className="primary-btn  createProductBtn   paddingRight10 "
              onClick={handleCreateProductSet}
              variant="primary"
            >
              Create Product Set
            </Button>
          </div>

      <div style={{ marginBottom: "80px" }}></div>
    </div>
  );
};

export default CreateProductSet;
