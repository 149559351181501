import React, { useReducer } from "react";
import optimizerConfigs from "../../constant/optimizer/config.json";
import { useParams } from "react-router-dom";
import OptimizerFilters from "../../components/optimizer/OptimizerFilters";
import { Stack } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb/ReportsBreadCrumb";
import SectionTitle from "../../components/sectionTitle";
import { getMasterTargetingGraphData } from "../../helper/Reports/apicalls/targeting/getMasterTargetingData";
import { useEffect } from "react";
import { useState } from "react";
import { renderMainGraph } from "../../constant/optimizer/mainGraphs";
import cubejs from "@cubejs-client/core";
import SearchWithSelect from "../../components/Search/SearchWithSelect";
import DynamicFilterModal from "../../components/modal/DynamicFilterModal";
import OptimizerTable from "../../components/optimizer/table/OptimizerTable";
import ascendingIconSvg from "../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../assets/svgs/descendingIcon.svg";
import { humanize } from "../../components/Reports/Components/utils";
import {
  formatDataForView,
  getChildVisibleHeaders,
  diffDateFormatter,
  sortOrderAndReturnNewData,
  renderPointer,
  renderChipCount,
  renderComparisonCells,
  removeSummaryPrefix,
  removeSubstring,
  convertTableHeadersForModal,
  reorderArrayBasedOnKeys,
  createExcelBody,
  downloadCsv,
  createHeadersForExcelDownload,
  createCubeQueryFilter,
  removeSelectedFields,
  parseFilters,
  getHeadersAccordingtoView,
  removeParticularMinMaxFilter,
  getCampaignIdWhenClickedOnAdgroupToRedirect,
  renderEditableCell,
  getSmartChipFilters,
  toolTipContent,
  getSelectedPresetLabel,
  resultSetFormatter
} from "../../pages/Optimizer/utils";
import { useGlobalContext } from "../../context/global";
import useToken from "../../helper/Reports/useToken";
import {
  formatDate,
  OPTIMIZER_TABLE_ACTIONS,
  GROUP_BY_CONFIGS,
  REPORT_DOWNLOAD_QUERIES,
  RESTRICTION_DISABLE_LOGIC
} from "../../pages/Optimizer/utils";
import AddIcon from "../../assets/svgs/plus_box.svg";
import MinusIcon from "../../assets/svgs/minus_box.svg";
import { formatDecimal,format } from "../../components/Reports/Components/utils";
import numeral from "numeral";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import dropDownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import CheckboxRb from "../../components/Buttons/checkbox/checkbox.js";
import externalLink from '../../assets/icons/external_link.svg'
import ShimmerTable from "../../components/Tables/ShimmerTable.jsx";
import editIcon from "../../assets/icons/edit.svg";
import checkCircle from "../../assets/icons/check_circle.png";
import previousDays from "../../helper/Reports/common.js";
import movingUp from "../../assets/svgs/arrow_upward_green.svg";
import movingDown from "../../assets/svgs/arrow_downward_red.svg";
import SwitchBtn from "../../components/Buttons/switchBtn.js";
import { useMemo } from "react";
import SelectionBar from "../../components/Bars/SelectionBar.jsx";
import { ALERT_MESSAGES } from "../../pages/Optimizer/utils";
import MasterTagetinngActionAlertMModal from '../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal.js';
import { useSelector, useDispatch } from "react-redux";
import { campaignListActions } from "../../redux/slices/campaignList.js";
import EditBidModalForTargeting from "../../components/modal/editBidModalForTargeting.js";
import { Download } from "../../components/Reports/Components/Download.js";
import FilterToggleModal from "../../components/modal/FilterToggleModal.jsx";
import deltaIcon from '../../assets/svgs/filter.svg';
import { getCubeQueryDimensions , getSelectedRows,cubeQueryResultFormatter } from "../../helper/Optimizer/index.js";
import { COMMON_METRICS,API_DATA_LOGIC } from "../../pages/Optimizer/utils";
import { toast } from "react-toastify";
import { mergeFilters, generateChipFilters } from "../../pages/Optimizer/utils";
import FilterChips from '../../components/FilterChips/FilterChips.js';
import Loader from '../../components/loader.js'
import resultSetReducer from "../../pages/Optimizer/utils";
import ExcelJS from "exceljs";
import MasterTargetingSearchSelectModal from "../../components/modal/masterTargetinngActionnAlertMModal/search&select/MasterTargetingSearchSelectModal.jsx";
import whiteFindIcon from '../../assets/icons/white_magnnifineIcon.png';
import { masterApi } from "../../apis/masterApi.js";
import { formatDateFn } from "../../helper/common/formatDateForAPI";
import { encodeData } from "../../helper/common/encodeData.js";
import { HiOutlineViewColumns } from "react-icons/hi2";
import { MdOutlineFilterAlt } from "react-icons/md";
import { FaExchangeAlt } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { TbFilterDiscount } from "react-icons/tb";
import { GrAggregate } from "react-icons/gr";
import './optimizer.css';
import FiltersModal from "../../components/modal/FiltersModal.jsx";
import { useCallback } from "react";
import SecondaryBtnRb from "../../components/Buttons/secondaryBtnRb/secondaryBtnRb.js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import moment from "moment";
import { SiQuicklook } from "react-icons/si";
import ReportsNavigator from "../../components/Reports/ReportsNavigator.jsx";
import { allConfirmationButtonsIds } from "../../constant/confirmActions.js";




const Optimizer = (props) => {
  const reportParam = useParams();
  const dispatch = useDispatch();
  const [token, refresh] = useToken("");
  const reportType = JSON.parse(JSON.stringify(optimizerConfigs[reportParam?.reportType]));
  const paramName = reportParam?.reportType;
  const {updateFunction,formatter} = API_DATA_LOGIC[paramName];
  const {restrictFunc} = RESTRICTION_DISABLE_LOGIC[paramName];
  const [configData, setConfigData] = useState(reportType);
  const {
    name,
    isChartRequired,
    chart_details,
    filters,
    tableHeaders,
    cube,
    metrics,
    dimensions,
    timeDimension,
    order,
    child_data,
    metricsToFormat,
    group_by,
    search_by,
    selectAllKey,
    isExpandable,
    editableKey,
    parentRowEditable,
    childRowEditable,
    pointerKey,
    isTableFilter,
    tableFilters,
    fromCube,
    fromAPI,
    masterApiKey,
    record_limit,
    custom_config_key,
    tableHeadersOrder
  } = configData;
  const ALERT_MESSAGE = ALERT_MESSAGES[reportParam?.reportType]
  const hiddenHeadersForReport = REPORT_DOWNLOAD_QUERIES[reportParam?.reportType][group_by?.default]?.hidden_headers
  const chartName = chart_details?.chart_name ?? null;
  const dashboardSideBarVisibiltyState = false;
  const [chartData, setChartData] = useState(null);
  const [userConfigData,setUserConfigData] = useState({});
  const {
    calendarDate,
    applyCalendar,
    clientAccountId,
    clientId,
    cubeToken,
    setCubeToken,
    multiCalendarDate,
    dateDifference,
    setReportsTime,
    setReportsFunnelType,
    setCalendarFreeze,
    setOldCalendarData,
    setOldDateDifference,
    setDateDifference
  } = useGlobalContext();

  const [normalViewType, setNormalViewType] = useState("Custom");
  const [activeCancelStatFilterField, setActiveCancelStatFilterField] =
    useState("");
  const cubeQueryFilters = fromAPI ? [] : [
    {
      member: `${cube}.profileId`,
      operator: "equals",
      values: [clientAccountId],
    },
  ];
  const [searchFilters, setSearchFilters] = useState([]);
  const [refreshData,setRefreshData] = useState(false);
  const cubejsApi = cubejs(cubeToken, {
    apiUrl:"https://chocolate-ostrich.gcp-us-central1.cubecloudapp.dev/dev-mode/dev-adyogi-6c618110//cubejs-api/v1"
    // apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });
  const [cubeResultSet, setCubeResultSet] = useState({});
  const [resultSetData, dispatchResultSet] = useReducer(resultSetReducer, []);
  const [loading,setLoading] = useState(true);
  const [childRowsLoading, setChildRowsLoading] = useState(false);
  const selectedRows = useMemo(() => {
     return getSelectedRows(resultSetData,isExpandable)
  },[resultSetData])
  const [resetPagination,setResetPagination] = useState(false)
  const [refreshTableFilters,setRefreshTableFilters] = useState(false)
 const [alertText,setAlertText] = useState("");
 const [showBulkBidModal,setBulkBidModal] = useState(false);
 const [overlayLoader,setOverlayLoader] = useState(true);
 const [quickFiltersModal,setQuickFiltersModal] = useState(false)
 const [rowCountLoading,setRowCountLoading] = useState(true);
 const [modalVisible,setModalVisible] = useState(false);
 const [quickFilterLoading,setQuickFilterLoading] = useState(false)
 const [allFiltersVisible,setAllFilterVisible] = useState({searchSortModal:false})
  const isComparisonView = normalViewType === "Comparison";
  const [clickedColumnName,setClickedColumnName] = useState(null);
  const [tableFiltersKeys,setTableFiltersKeys] = useState({});
  const cubeQueryMeasures = getCubeQueryDimensions(cube,metrics)
  const cubeQueryDimesions = getCubeQueryDimensions(cube,dimensions);
  const hiddenHeadersDimensions = getCubeQueryDimensions(cube,hiddenHeadersForReport);
  // All Table Headers Seperated DRR and removing Visible Headers
  const tableHeadersDRR =  tableHeaders?.filter((data) => {
      return data?.isMetric && data?.isDRRView
    })
 
  const tableHeadersAggregate = tableHeaders?.filter((data) => {
    return data?.isMetric && !data?.isDRRView
  })

  const confirmationId = allConfirmationButtonsIds["Targeting"][alertText] ?? `${name}-confirmation-modal`;
  const confirmationIdForBidAdjust = 'bulk-bid-adjust-confirm'
  const [summaryTotal, setSummaryTotal] = useState([
]);
const [allRowsCount,setAllRowsCount] = useState({});
const [selectedBtn,setSelectedBtn] = useState("all-btn");
const [metricViewType,setMetricViewType] = useState(false); // false means it is aggregated view.
const [firstLoad,setFirstLoad] = useState(false)
const [quickFiltersType,setQuickFiltersType] = useState("Prospect");
const [quickFilterName,setQuickFilterName] = useState("unknown");
const [previousData,setPreviousData] = useState([]);
// Loading Index
const [loadingIndex,setLoadingIndex] = useState(false)

  // Actual Data To Show
  const customDataHeads = tableHeaders?.filter((data) => {
    return data?.isEnabled
   });

  const dataHeads = getHeadersAccordingtoView(customDataHeads,metricViewType);
  const summaryDataToFormat = summaryTotal?.map((data) => removeSummaryPrefix(data));
  const summaryDataToShow = formatDataForView(summaryDataToFormat,normalViewType,metricsToFormat);
  const dataToShow = formatDataForView(resultSetData,normalViewType,metricsToFormat);
  const isCustomHeadsRequired = useMemo(() => {
    return dataToShow?.some((data) => data?.isDisabled);
  },[dataToShow])
  const columnsToHide = ["current_bid","pdp_bids","tosp_bids","product_set","strategy","agg_br","dbr","campaign_type"];
  const visibleHeaders = (!isCustomHeadsRequired && isExpandable )? getCustomDataHeads(dataHeads,columnsToHide) : dataHeads;
  const [actionType,setActionType] = useState("");
  const [currentDataForAction,setCurrentDataForAction] = useState(null);
  const childVisibleHeaders = getChildVisibleHeaders(child_data?.tableHeaders,visibleHeaders,COMMON_METRICS)?.filter((data) => data?.isEnabled)
  const cubeQueryTimeDimensions = getCubeQueryTimeDimensions(normalViewType)
  const [normalFilters,setNormalFilters] = useState({agg:[],drr:[]});
  const [comparisonFilters,setComparisonFilters] = useState({agg:[],drr:[]});

  const minMaxNormalFiltering = [...createCubeQueryFilter(normalFilters?.agg,cube,fromAPI),...createCubeQueryFilter(normalFilters?.drr,cube,fromAPI)]
  const minMaxComparisonFiltering = [...createCubeQueryFilter(comparisonFilters?.agg,cube,fromAPI),...createCubeQueryFilter(comparisonFilters?.drr,cube,fromAPI)]
  const chipFilters = generateChipFilters([...filters,...tableFilters],normalFilters,comparisonFilters);
  const filteredChipFilters = (quickFilterName!=="" && quickFilterName!=="unknown")? chipFilters?.filter((data) => {
    return data?.field_name !== "campaign_objective"
  }):chipFilters
  const mainChipFilters = getSmartChipFilters(filteredChipFilters,quickFilterName,quickFiltersType)
  const filtersToBeSent = parseFilters(filters,cube,fromAPI);
  const tableFiltersToBeSent = parseFilters(tableFilters,cube,fromAPI);
  const statusFilter = getStatusFilter(selectedBtn);
  const allFilters = [
    ...cubeQueryFilters,
    ...filtersToBeSent,
    ...searchFilters,
    ...minMaxNormalFiltering,
    ...minMaxComparisonFiltering,
    ...tableFiltersToBeSent,
    ...statusFilter
  ];

  const cubeOrder = [
    [`${cube}.${order[0]}`,order[1]]
  ];
  
  const cubeConfigs = {
    measures: cubeQueryMeasures,
    order: cubeOrder,
    dimensions: cubeQueryDimesions,
    filters: allFilters,
    timeDimensions: cubeQueryTimeDimensions,
    timezone: "Asia/Kolkata",
    limit: record_limit,
    offset: 0,
    total: true,
  };


  // Close If Clicked Outside

  
  function closeInputIfClickedOutside (e) {
    const containers = document.querySelectorAll('.budget-td-container-placement');
    const confirmationBtn = document.querySelector("#change-current-bid-confirm");
    let isOutside = true;
    // Check if the click event target is within any of the containers
    containers.forEach(container => {
      if (container?.contains(e.target)) {
        isOutside = false;
      }
    });
    if(confirmationBtn?.contains(e.target)) {
      isOutside = false
    }
    // Execute function if the click is outside all containers
    if (isOutside) {
      dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.EDIT_TABLE_CELL,data:{childIndex:Infinity,isChildRow:isExpandable}}) // can give any childIndex
    }
  }

  useEffect(() => {
    // Effect to close if input box if clicked outside of that input box
   window.document.addEventListener('click',closeInputIfClickedOutside)

   return () => window.document.removeEventListener('click',closeInputIfClickedOutside)
  },[group_by,alertText])


 useEffect(() => {
  setReportsFunnelType("Normal" + " View");
  return () => {
    setReportsFunnelType("normal");
    const startDate = diffDateFormatter(calendarDate[0].startDate);
    const endDate = diffDateFormatter(calendarDate[0].endDate);
    setReportsTime([[startDate,endDate]])
  }
 },[])

  useEffect(() => {
    if (isChartRequired) {
      const { chart_name } = reportType?.chart_details;
      if (chart_name === "targeting_chart") {
        (async function () {
          const mainData = await getMasterTargetingGraphData();
          setChartData(mainData);
          setOverlayLoader(false);
          const customConfigData = await masterApi({key:custom_config_key},"updateClientConfig",false);
          if(customConfigData?.statusCode===200) {
            const filteredTableHeaders = tableHeaders?.filter((tableHeader) => !tableHeader?.isMetric);
            const savedHeaders = customConfigData?.data?.value;
            const newTableHeaders = [...filteredTableHeaders,...savedHeaders]
            const reOrderedHeaders = reorderArrayBasedOnKeys(newTableHeaders,tableHeadersOrder) // Re-Order Array Based on keys.
            setUserConfigData(customConfigData?.data);
            setConfigData((prev) => {
              return {
                ...prev,
                tableHeaders:reOrderedHeaders
              }
            })
          } 
        })();
      }
    }
  }, []);

  useEffect(() => {
    if(isTableFilter) {
    const updatedTableFilters = tableFilters?.map((tableFilter,index) => {
       masterApi(tableFilter?.reqConfig,tableFilter?.masterApiKey,false).then((response) => {
          if(response?.statusCode===200 && response?.data?.data) {
            tableFilters[index].allData = response?.data?.data
            tableFilters[index].options = response?.data?.data
          }
       })
      } )
    }
  },[refreshTableFilters])


  // Get Rows Count 




  // Get Summary Data

  useEffect(() => {
    (async function () {
      if(masterApiKey === "getOptimisedBids") {
        const top_performing_order_filter_previous = {field_name:"agg_dd_orders",operator:"GREATER_THAN_EQUAL_TO",value:3};
        if(quickFilterName === "Top Performing") {
          allFilters.push(top_performing_order_filter_previous)
         }
         const low_performing_order_filter_previous = {field_name:"agg_dd_clicks",operator:"GREATER_THAN_EQUAL_TO",value:40};
         if(quickFilterName === "Low Performing") {
          allFilters.push(low_performing_order_filter_previous)
         }
        const startDate = calendarDate[0].startDate;
        const endDate = calendarDate[0].endDate
        const { selection1, selection2 } = multiCalendarDate;
        const { startDate: start_date_first, endDate: end_date_first } = selection1;
        const { startDate: start_date_second, endDate: end_date_second } = selection2;
        let dataForAPI = {
          source: "amazon",
          order_by: order[0],
          sorting_order: order[1],
          duration_one_start_date: formatDateFn( isComparisonView ? start_date_first  : startDate),
          duration_one_end_date: formatDateFn(isComparisonView ? end_date_first : endDate),
          duration_two_start_date: formatDateFn(start_date_second),
          duration_two_end_date: formatDateFn(end_date_second),
          master_row:isExpandable,
          filtering:encodeData(allFilters),
          suggested_bid_required: false,
        };
       dataForAPI["summary_data"] = true
        const response = await masterApi(dataForAPI,"getOptimisedBids",false);
        if(response?.data?.data) {
          setSummaryTotal(response?.data?.data)
        }
        if(response?.statusCode!==200) {
          toast.error('Some unknown error occurred while fetching summaryRow')
        }
      }
    })()
  },[refreshData,searchFilters,applyCalendar])


  useEffect(() => {
   (async function () {
    if(masterApiKey === "getOptimisedBids") {
      const startDate = calendarDate[0].startDate;
      const endDate = calendarDate[0].endDate
      const { selection1, selection2 } = multiCalendarDate;
      const { startDate: start_date_first, endDate: end_date_first } = selection1;
      const { startDate: start_date_second, endDate: end_date_second } = selection2;
      let dataForAPI = {
        source: "amazon",
        order_by: order[0],
        sorting_order: order[1],
        duration_one_start_date: formatDateFn(isComparisonView ? start_date_first : startDate),
        duration_one_end_date: formatDateFn( isComparisonView ? end_date_first : endDate),
        duration_two_start_date: formatDateFn(start_date_second),
        duration_two_end_date: formatDateFn(end_date_second),
        master_row:isExpandable,
        suggested_bid_required: false,
        filtering:encodeData([{"field_name":"status_count","operator":"EQUALS","value":"count"}])
      };
      setRowCountLoading(true)
      if(!isExpandable) {
        dataForAPI["child_status_count"] = true;
        dataForAPI["limit"] = 25000
        delete dataForAPI["filtering"];
      }
      const responseForStatusCount = await masterApi(dataForAPI,"getOptimisedBids",false);

      if(responseForStatusCount?.statusCode!==200) {
        toast.error('Some unknown error occurred while fetching total number of records');
        setRowCountLoading(false);
      }

      if(responseForStatusCount?.data?.data[0]) {
           setAllRowsCount(responseForStatusCount?.data?.data[0]);
           setRowCountLoading(false);
      }
    
      setRowCountLoading(false);
    }
   })()
  },[group_by?.default,applyCalendar])

  

  useEffect(() => {
    if(quickFilterName!=="" && quickFilterName!=="unknown") {
      setCalendarFreeze(true)
    } else {
      setCalendarFreeze(false)
    }
    return () => {
      setCalendarFreeze(false)
    }
  },[quickFilterName])



  // Call Main Cube Js API here

  useEffect(() => {
    if (fromCube) {
      if (cubeToken && cubeQueryMeasures) {
        setLoading(true);
        setResetPagination(!resetPagination);
        cubejsApi
          .load(cubeConfigs, {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          })
          .then((resultSet) => {
            cubejsApi
              .load(
                {
                  measures: cubeQueryMeasures,
                  order: {
                    [`${cube}.${order[0]}`]: order[1],
                  },
                  filters: cubeQueryFilters,
                  timeDimensions: cubeQueryTimeDimensions,
                },
                {
                  headers: { "Access-Control-Allow-Origin": true },
                }
              )
              .then((result) => {
                const { resultSetData } = cubeQueryResultFormatter(
                  result,
                  normalViewType,
                  metricsToFormat,
                  cube
                );
                if (resultSetData?.length) {
                  setSummaryTotal(resultSetData[0]);
                }
              });
            const { resultSetData } = cubeQueryResultFormatter(
              resultSet,
              normalViewType,
              metricsToFormat,
              cube
            );
            setCubeResultSet(resultSet);

            dispatchResultSet({
              action: OPTIMIZER_TABLE_ACTIONS.SET_INITIAL_STATE,
              data: resultSetData,
            });
            setLoading(false);
          })
          .catch((error) => {
            if (error.message === "Invalid token") {
              setCubeToken(false);
              localStorage.setItem("cubeToken", null);
              refresh();
              setLoading(false);
            }
          });
      }
    }

    if (fromAPI) {
      (async function () {
        setLoading(true)
        setResetPagination(!resetPagination);
        if (masterApiKey) {
          let dataForAPI;
          if (masterApiKey === "getOptimisedBids") {
            const top_performing_order_filter_previous = {field_name:"agg_dd_orders",operator:"GREATER_THAN_EQUAL_TO",value:3};
            if(quickFilterName === "Top Performing") {
              allFilters.push(top_performing_order_filter_previous)
             }
             const low_performing_order_filter_previous = {field_name:"agg_dd_clicks",operator:"GREATER_THAN_EQUAL_TO",value:40};
             if(quickFilterName === "Low Performing") {
              allFilters.push(low_performing_order_filter_previous)
             }
             const startDate = calendarDate[0].startDate;
             const endDate = calendarDate[0].endDate
            const { selection1, selection2 } = multiCalendarDate;
            const { startDate: start_date_first, endDate: end_date_first } = selection1;
            const { startDate: start_date_second, endDate: end_date_second } = selection2;
            dataForAPI = {
              source: "amazon",
              order_by: order[0],
              sorting_order: order[1],
              duration_one_start_date: formatDateFn( isComparisonView ? start_date_first : startDate),
              duration_one_end_date: formatDateFn(isComparisonView ? end_date_first :endDate),
              duration_two_start_date: formatDateFn(start_date_second),
              duration_two_end_date: formatDateFn(end_date_second),
              limit: record_limit,
              master_row:isExpandable,
              suggested_bid_required: false,
              offset: 0,
              filtering: encodeData(allFilters),
            };
          
          }

          const response = await masterApi(dataForAPI,"getOptimisedBids",false);
          if(response?.statusCode!==200) {
            toast.error('Some unknown error occurred!')
            setLoading(false)
          }
          if(response?.data?.data) {
            const formattedResultSet = isExpandable ? response?.data?.data : resultSetFormatter(response?.data?.data)
            setCubeResultSet(response?.data?.data)
            dispatchResultSet({
              action: OPTIMIZER_TABLE_ACTIONS.SET_INITIAL_STATE,
              data: formattedResultSet,
            });
            setLoading(false)
          }
        }
      })();
    }
    // setLoading(false)
  }, [refreshData, searchFilters, applyCalendar]);

  useEffect(() => {
    const tableHeadersAgg = convertTableHeadersForModal(tableHeadersAggregate)
    const tableHeadersDrr = convertTableHeadersForModal(tableHeadersDRR);
    const parsedHeadersAgg = structuredClone(tableHeadersAggregate)
    const parsedHeadersDrr = structuredClone(tableHeadersDRR)
    const tableHeadersCompAgg = convertTableHeadersForModal(parsedHeadersAgg,"comp_");
    const tableHeadersCompDrr = convertTableHeadersForModal(parsedHeadersDrr,"comp_");
    setNormalFilters({agg:tableHeadersAgg,drr:tableHeadersDrr});
    setComparisonFilters({agg:tableHeadersCompAgg,drr:tableHeadersCompDrr});
    setFirstLoad(!firstLoad)
  },[])

  useEffect(() => {
    if(normalFilters?.agg?.length) {
      const newFilters = structuredClone(normalFilters);
      const aggregatedFilters = disableModalFieldsFromHeaders(newFilters?.agg,false,true);
      const drrFilters = disableModalFieldsFromHeaders(newFilters?.drr,false,false);
      setNormalFilters({agg:aggregatedFilters,drr:drrFilters})
    }
    if(comparisonFilters?.agg?.length) {
      const newFilters = structuredClone(comparisonFilters);
      const aggregatedFilters = disableModalFieldsFromHeaders(newFilters?.agg,"comp_");
      const drrFilters = disableModalFieldsFromHeaders(newFilters?.drr,"comp_");
      setComparisonFilters({agg:aggregatedFilters,drr:drrFilters})
    }
  },[tableHeaders,metricViewType,firstLoad])


  function disableModalFieldsFromHeaders(filtersData, additonalKey = false , isAggregated=false) {
    let recievedData = filtersData?.map((data) => {
      let disableAll = (metricViewType === false && !data?.field_key?.includes("agg")) || (metricViewType === true && data?.field_key?.includes("agg"))

 
      const foundedObject = tableHeaders?.find((dataHead) => {
        let previousDataKey = dataHead?.key;
        if (previousDataKey?.includes("agg") && additonalKey) {
          let removedData = removeSubstring(previousDataKey, "agg_");
          previousDataKey = `agg_comp_${removedData}`;
        }
        return (
          (additonalKey && !previousDataKey?.includes("agg")
            ? additonalKey + dataHead?.key
            : previousDataKey) === data?.field_key
        );
      });
      if (foundedObject) {
          data.disabled = disableAll ? true : !foundedObject?.isEnabled;
      }

      if (data?.permanentDisabled === true) {
        data.disabled = true;
        return data;
      }
      return data;
    });

    if (
      !filtersData?.find((data) => data?.field_key === "agg_br") &&
      !additonalKey &&
      group_by.default === "Granular"
    ) {
      const dataObj = {
        field_key: isAggregated ? "agg_br" : "dbr",
        field_name: isAggregated ? "BR" : "DBR",
        minValue: "",
        maxValue: "",
        disabled: false,
      };
      recievedData = recievedData?.filter((data) => {
        return data?.field_key !== "agg_br" && data?.field_key !== "dbr";
      });
      recievedData.push(dataObj);
    }

    if (group_by.default === "Global") {
      recievedData = recievedData?.filter((data) => {
        return data?.field_key !== "agg_br" && data?.field_key !== "dbr";
      });
    }

    return recievedData;
  }



  function getCubeQueryTimeDimensions  (viewType)  {
      return viewType === "Comparison" ? [{
        dimension:`${cube}.${timeDimension}`,
        compareDateRange:[[formatDate(multiCalendarDate.selection1.startDate),formatDate(multiCalendarDate.selection1.endDate)],[formatDate(multiCalendarDate.selection2.startDate),formatDate(multiCalendarDate.selection2.endDate)]]
      }]: [{
        dimension:`${cube}.${timeDimension}`,
        dateRange: [
          formatDate(calendarDate[0].startDate),
          formatDate(calendarDate[0].endDate),
        ]
      }]
  }


  function getStatusFilter(status) {
    return status === "all-btn"
      ? []
      : [{ field_name: "status", operator: "EQUALS", value: status.toLowerCase() }];
  }

  function getCustomDataHeads (arrayOfMainData,arrayOfSecondData) {
    const result = arrayOfMainData.filter((data) => {
        const mainData = data?.key
        return !arrayOfSecondData.includes(mainData)
      })
      return result
 }


 const handleHighlightList  = (index) => {
    const data = {
      index:index
    }
   dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.HIGHLIGHT_ROW,data})
 }


  const handleNormalViewType = (viewType) => {
    const { selection1, selection2 } = multiCalendarDate;
    const { startDate: start_date_second, endDate: end_date_second } =
      selection2;
    const { startDate: start_date_first, endDate: end_date_first } = selection1;
    if (viewType === "Comparison") {
      const startDate = diffDateFormatter(calendarDate[0].startDate);
      const endDate = diffDateFormatter(calendarDate[0].endDate);
      const reportsTimeArray = [
        [startDate, endDate],
        [
          diffDateFormatter(start_date_second),
          diffDateFormatter(end_date_second),
        ],
      ];
      setReportsFunnelType("Comparison View");
      setReportsTime(reportsTimeArray);
    } else {
      const reportsTimeArray = [
        [
          diffDateFormatter(start_date_first),
          diffDateFormatter(end_date_first),
        ],
      ];
      setReportsFunnelType("Normal View");
      setReportsTime(reportsTimeArray);
      const dataToSet = {
        startDate: start_date_first,
        endDate: end_date_first,
        key: "selection",
      }
      const dateDifference = getSelectedPresetLabel(dataToSet)
      setDateDifference(dateDifference?.date)
      setOldDateDifference(dateDifference?.date)
      setOldCalendarData([dataToSet])   
    }
    setNormalViewType(viewType);
  };

  const getAppliedFilters = (e, type, filterKey) => {
    const parsedData = JSON.parse(JSON.stringify(configData));
    parsedData["filters"] = parsedData["filters"]?.map((filter) => {
      if (filterKey === filter.key) {
        filter.default = e;
      }
      return filter;
    });
    setConfigData(parsedData);
    setRefreshData(!refreshData)
  };


  const renderSummaryRow = (summaryTotal) => {
    return (
      <tr className="summary-row-optimizer" key={"summaryRow"}>
        {visibleHeaders?.map((tableHead, index) => {
          const className = tableHead?.key === "status" ? "first-column" : tableHead?.key === "targeting" ? "second-column" : ""
          return (
         ( isComparisonView && metricsToFormat?.includes(tableHead?.key)) ? renderComparisonCells(summaryTotal[tableHead?.key] ?? {},tableHead?.key,className)     :  <td key={tableHead?.key + "1"} className={className}>
              <big className="font-weight-bold tableSummary">
                {index === 0
                  ? "Summary"
                  :summaryTotal?.hasOwnProperty(tableHead?.key)
                  ? formatMetrics(summaryTotal[tableHead?.key], tableHead?.key) : "-"
                  }
              </big>
            </td>
          );
        })}
      </tr>
    );
  };

  const handleSort = (data) => {
    // Actual Code is in SortOrderAndReturnNewData For sorting of headers and order change.
    const parsedData = sortOrderAndReturnNewData(data,configData)
    setConfigData(parsedData);
    setRefreshData(!refreshData)
  };

  const renderExpandableIcon = (recievedData) => {
    const { key, keyToCompare, isDisabled, handlePlusIcon, rowIndex } =
      recievedData;
    return (key === keyToCompare && isExpandable) ? (
      <div className="icon-hover" onClick={() => handlePlusIcon(rowIndex)}>
        <img
          className="tdIcon"
          src={isDisabled ? MinusIcon : AddIcon}
          alt="action"
        />
      </div>
    ) : null;
  };

  const renderLinkIcon = (recievedData) => {
    const { key, keyToCompare, handleClick, rowIndex } =
    recievedData;
    return key === keyToCompare ? (
      <div className="icon-hover"
      onClick={(event) =>
        handleClick(rowIndex)
      }
    >
      <img
        className="tdIcon"
        src={externalLink}
        alt="link"
      />
    </div>
    ) : null
  }

  async function handlePlusIconClick(recievedIndex) {
    dispatchResultSet({
      action: OPTIMIZER_TABLE_ACTIONS.EXPAND_ROW,
      data: {
        parentIndex: recievedIndex,
      },
    });
    if (!resultSetData[recievedIndex].child_rows?.length) {
      setLoadingIndex(recievedIndex)
      setChildRowsLoading(true);
      const measures = measuresParser(child_data?.metrics);
      const filterValue = resultSetData[recievedIndex][child_data?.expand_on];
      let filter;  
      if(fromAPI) {
        const top_performing_order_filter_previous = {field_name:"agg_dd_orders",operator:"GREATER_THAN_EQUAL_TO",value:3};
        if(quickFilterName === "Top Performing") {
          allFilters.push(top_performing_order_filter_previous)
         }
         const low_performing_order_filter_previous = {field_name:"agg_dd_clicks",operator:"GREATER_THAN_EQUAL_TO",value:40};
         if(quickFilterName === "Low Performing") {
          allFilters.push(low_performing_order_filter_previous)
         }
        filter = [
          {
            field_name:"exact_" + child_data?.expand_on,
            operator:"EQUALS",
            value:filterValue
          },
          {
            field_name:"adyogi_targeting_type",
            operator:"EQUALS",
            value:resultSetData[recievedIndex]?.adyogi_targeting_type
          },
          ...allFilters
        ];
      } else {
        filter = [
          {
            member: `${cube}.${child_data.expand_on}`,
            operator: "in",
            values: [filterValue],
          },
          ...allFilters
        ];
      }
      let dataForCube
      if(fromAPI) {
        const filtering = [];
        const startDate = calendarDate[0].startDate;
        const endDate = calendarDate[0].endDate
        const { selection1, selection2 } = multiCalendarDate;
        const { startDate: start_date_first, endDate: end_date_first } =
          selection1;
        const { startDate: start_date_second, endDate: end_date_second } =
          selection2;
        dataForCube = {
          source: "amazon",
          order_by: order[0],
          sorting_order: order[1],
          duration_one_start_date: formatDateFn( isComparisonView ? start_date_first : startDate),
          duration_one_end_date: formatDateFn(isComparisonView ? end_date_first : endDate),
          duration_two_start_date: formatDateFn(start_date_second),
          duration_two_end_date: formatDateFn(end_date_second),
          suggested_bid_required: false,
          master_row:false,
          filtering: encodeData(filter),
        };  
      } else {
      dataForCube  = {
          measures,
          order: cubeOrder,
          filters: filter,
          dimensions: child_data?.dimensions?.map(
            (dimension) => `${cube}.${dimension}`
          ),
          timeDimensions: cubeQueryTimeDimensions,
        };
      }

      const result = await getChildRows(dataForCube,masterApiKey);
      const formattedResult = resultSetFormatter(result);
      const {resultSetData:mainData} = cubeQueryResultFormatter(result,normalViewType,metricsToFormat,cube);
      dispatchResultSet({
        action:OPTIMIZER_TABLE_ACTIONS.ADD_CHILD_ROWS,
        data:{
          parentIndex:recievedIndex,
          childRows:fromAPI?formattedResult:mainData,
          tickParent:false
        }
      })
      setChildRowsLoading(false)
    }
  }

  function handleRedirectLink () {
    
  }


  function formatToNumeral (data) {
    return typeof data === "number" ? numeral(data)?.format(formatDecimal) : data
  }
  

  function handleToggleButton (data) {
    if(data?.isChildRow) {
      const currentData = resultSetData[data?.parentIndex]?.child_rows[data?.childIndex];
      const dataKey = currentData[pointerKey]?.toLowerCase()?.includes("enable") ? "Pause":"Enable";
      const alertText = `Are you sure that you want to ${dataKey} this?`
      setAlertText(alertText)
      setActionType("childToggle")
      setCurrentDataForAction(data)
      dispatch(campaignListActions.showActionAlertModal())
    } else {
      const currentData = resultSetData[data?.parentIndex]
      const dataKey = currentData[pointerKey]?.toLowerCase()?.includes("enable") ? "Pause":"Enable";
      const alertText = `Are you sure that you want to ${dataKey} this?`
      setAlertText(alertText)
      setActionType("parentToggle")
      setCurrentDataForAction(data)
      dispatch(campaignListActions.showActionAlertModal())
    }
  }

  function renderToggleButton(status,data,classNames) {
    return <td className={classNames}> 
      <div className="child-switch-btn-container">
    {
      <SwitchBtn
        checked={
          status
        }
        disabled={data?.disabled}
       onChange={(e) => {
        e.stopPropagation()
        handleToggleButton(data)
       }}
      ></SwitchBtn>
    }
  </div>
  </td>
  }

  

 async function handleCheckboxChange(data) {
   const { isChildRow, parentIndex, childIndex } = data;
   data['restrictName'] = paramName
   if (isChildRow) {
     dispatchResultSet({
       action: OPTIMIZER_TABLE_ACTIONS.SELECT_SINGLE_ROW,
       data: data,
     });
   } else {
     if (isExpandable) {
       if (!resultSetData[parentIndex].child_rows?.length) {
         setChildRowsLoading(true);
         setLoadingIndex(parentIndex)
         const measures = measuresParser(child_data?.metrics);
         const filterValue = resultSetData[parentIndex][child_data.expand_on];
         let filter;  
         if(fromAPI) {
           filter = [
             {
               field_name: "exact_" + child_data?.expand_on,
               operator:"EQUALS",
               value:filterValue
             },
             {
              field_name:"adyogi_targeting_type",
              operator:"EQUALS",
              value:resultSetData[parentIndex]?.adyogi_targeting_type
            },
             ...allFilters
           ];
         } else {
           filter = [
             {
               member: `${cube}.${child_data.expand_on}`,
               operator: "in",
               values: [filterValue],
             },
             ...allFilters
           ];
         }
         let dataForCube
         if(fromAPI) {
           const filtering = [];
           const startDate = calendarDate[0].startDate;
           const endDate = calendarDate[0].endDate
           const { selection1, selection2 } = multiCalendarDate;
           const { startDate: start_date_first, endDate: end_date_first } =
             selection1;
           const { startDate: start_date_second, endDate: end_date_second } =
             selection2;
           dataForCube = {
             source: "amazon",
             order_by: order[0],
             sorting_order: order[1],
             duration_one_start_date: formatDateFn( isComparisonView ? start_date_first : startDate),
             duration_one_end_date: formatDateFn(isComparisonView ? end_date_first : endDate),
             duration_two_start_date: formatDateFn(start_date_second),
             duration_two_end_date: formatDateFn(end_date_second),
             suggested_bid_required: false,
             master_row:false,
             filtering: encodeData(filter),
           };  
         } else {
         dataForCube  = {
             measures,
             order: cubeOrder,
             filters: filter,
             dimensions: child_data?.dimensions?.map(
               (dimension) => `${cube}.${dimension}`
             ),
             timeDimensions: cubeQueryTimeDimensions,
           };
         }
         dispatchResultSet({
           action: OPTIMIZER_TABLE_ACTIONS.EXPAND_ROW,
           data: {
             parentIndex: parentIndex,
           },
         });
         const result = await getChildRows(dataForCube);
         const { resultSetData: formattedResult } =
           cubeQueryResultFormatter(result,normalViewType,metricsToFormat,cube);

         const mainData = ( fromAPI ? result : formattedResult)?.map((data) => {
          if(restrictFunc(data)) {
            data.isChecked = true;
            data.bid_copy = data?.current_bid
          }
           return data;
         });

         dispatchResultSet({
           action: OPTIMIZER_TABLE_ACTIONS.ADD_CHILD_ROWS,
           data: {
             parentIndex: parentIndex,
             childRows: mainData,
             parentTick:true
           },
         });
         setChildRowsLoading(false);
       } else {
         dispatchResultSet({
           action: OPTIMIZER_TABLE_ACTIONS.SELECT_ALL_ROWS,
           data,
         });
       }
     } else {
      dispatchResultSet({
        action:OPTIMIZER_TABLE_ACTIONS.SELECT_SINGLE_ROW,
        data:data
      })
     }
   }
 }


 const openLinkInNewTab = (data,name) => {
    const url = `/campaign-list?campName=${data?.campaign_name}`
    if(url) {
      window.open(url,"_blank")
    }
 }

 const renderLinks = (data,text,name) => {
  const functionToRun = name === "campaign_name" ? openLinkInNewTab : getCampaignIdWhenClickedOnAdgroupToRedirect
  
  return (
    <td className="text-nowrap">
      <div
        className="cursor-pointer-global clickable-text-color"
        title={text}
        onClick={() => functionToRun(data)}
      >
        {text}
      </div>
    </td>
  );
 }

  const chipKeys = ["campaign_count","portfolio_count","ad_group_count"];
  const linkKeys = ["campaign_name","adgroup_name"]; 
  const renderTableRow = (data, index, childRowsLoading, isExpandable) => {
    const isLoading = data?.isDisabled && childRowsLoading && index === loadingIndex
    return (
      <>
        <tr className={`cursor-pointer ${data?.isHighlighted ? "highlight" :""}`} onClick={() => handleHighlightList(index)} id={`row-${index}`}>
          {visibleHeaders?.map((tableHead) => {
            const { isSelectAll } = tableHead;
            const dataToBeSentForRenderIcon = {
              key: child_data?.expand_on,
              keyToCompare: tableHead?.key,
              isDisabled: data?.isDisabled,
              handlePlusIcon: handlePlusIconClick,
              rowIndex: index,
            };
            const dataToBeSentForLinkIcon = {
              key: configData?.link_key,
              keyToCompare: tableHead?.key,
              handleClick: handleRedirectLink,
              rowIndex: index,
            };
            const dataForEditableCell = {
              key: editableKey,
              keyToCompare: tableHead?.key,
              parentIndex: index,
              childIndex: null,
              isEditable: data?.isEditable,
              otherData: data,
              isChildRow: false,
              isFieldPresent:data?.hasOwnProperty("targeting_expression") ? true : false,
              handleEditableCellClick:handleEditableCellClick,
              handleCancelEditableCell:handleCancelEditableCell,
              handleEditableValueChange:handleEditableValueChange,
              updateEditableCell:updateEditableCell
            };
            const dataForCheckBoxChange = {
              childIndex: null,
              childRow: false,
              parentIndex: index,
              disabled:!restrictFunc(data)
              
            };
            const classNames = tableHead?.key === "status" ? "first-column" : tableHead?.key === "targeting" ? "second-column" : ""
            return parentRowEditable && editableKey === tableHead?.key ? (
              renderEditableCell(dataForEditableCell)
            ) : isComparisonView && metricsToFormat.includes(tableHead.key) ? (
              renderComparisonCells(data[tableHead.key] ?? {},tableHead?.key,classNames)
            ) : pointerKey === tableHead?.key && isExpandable ? (
              renderPointer(data[tableHead?.key]?.toLowerCase()  === "enabled",classNames)
            ) : pointerKey === tableHead?.key && !isExpandable ? (
              renderToggleButton(data[pointerKey]?.toLowerCase() === "enabled",dataForCheckBoxChange,classNames)
            ) : chipKeys.includes(tableHead?.key) ? (renderChipCount(data[tableHead?.key],tableHead?.name)) : linkKeys.includes(tableHead?.key) ? (renderLinks(data,data[tableHead?.key],tableHead?.key)) : (
              <td
                className={`text-nowrap ${
                  child_data?.expand_on === tableHead?.key ||
                  configData?.link_key === tableHead?.key
                    ? "d-flex justify-content-between align-items-center"
                    : ""
                } ${classNames}`}
                key={tableHead?.key + "1"}
              >
                <div className="d-flex align-items-center">
                  {isSelectAll && (
                    <CheckboxRb
                      onChange={(e) => {
                        e.stopPropagation();
                        handleCheckboxChange(dataForCheckBoxChange);
                      }}
                      disabled={!restrictFunc(data)}
                      checked={data?.isChecked ? true : false}
                    />
                  )}
                  <span>
                    {formatMetrics(data[tableHead?.key], tableHead?.key) ?? "-"}
                  </span>
                </div>
                {renderLinkIcon(dataToBeSentForLinkIcon)}
                {renderExpandableIcon(dataToBeSentForRenderIcon)}
              </td>
            );
          })}
        </tr>
        {data?.child_rows?.length && data?.isDisabled ? (
          data?.child_rows?.map((childRow, childIndex) => {
            return (
              <tr className="childRows">
                {childVisibleHeaders.map((tableHead) => {
                   const classNames = tableHead?.key === "status" ? "first-column" : tableHead?.key === "targeting" ? "second-column" : ""
                  const { isSelectAll } = tableHead;
                  const dataForEditableCell = {
                    key: editableKey,
                    keyToCompare: tableHead?.key,
                    parentIndex: index,
                    childIndex: childIndex,
                    isEditable: childRow?.isEditable,
                    otherData: childRow,
                    isFieldPresent:childRow?.hasOwnProperty("targeting_expression") ? true : false,
                    isChildRow: true,
                    handleEditableCellClick:handleEditableCellClick,
                    handleCancelEditableCell:handleCancelEditableCell,
                    handleEditableValueChange:handleEditableValueChange,
                    updateEditableCell:updateEditableCell
                  };
                  const dataForCheckBoxChange = {
                    parentIndex: index,
                    childIndex: childIndex,
                    isChildRow: true,
                    disabled:!restrictFunc(childRow)
                  };
                  return childRowEditable && editableKey === tableHead?.key ? (
                    renderEditableCell(dataForEditableCell)
                  ) : isComparisonView &&
                    metricsToFormat.includes(tableHead?.key) ? (
                    renderComparisonCells(childRow[tableHead?.key] ?? {},tableHead?.key,classNames)
                  ) : isExpandable && tableHead?.key === pointerKey ? (
                    renderToggleButton(childRow[pointerKey]?.toLowerCase()  === "enabled",dataForCheckBoxChange,classNames)
                  ) : linkKeys.includes(tableHead?.key) ? (renderLinks(childRow,childRow[tableHead?.key],tableHead?.key)) : (
                    <td className={`text-nowrap ${classNames}`} key={tableHead?.key + "1"}>
                      <div className="d-flex align-items-center">
                        {isSelectAll && (
                          <CheckboxRb
                            onChange={(e) => {
                              e.stopPropagation();
                              handleCheckboxChange(dataForCheckBoxChange);
                            }}
                            disabled={!restrictFunc(childRow)}
                            checked={childRow?.isChecked ? true : false}
                          />
                        )}
                        <span>
                          {formatMetrics(
                            childRow[tableHead?.key],
                            tableHead?.key
                          ) ?? "-"}
                        </span>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })
        ) : isLoading ? (
          <ShimmerTable
            rows={3}
            tableHeaders={visibleHeaders}
            hideHeaders={true}
            keyAppender={'child-table'}
          />
        ) : null}
      </>
    );
  };

  const renderComparisonFirstRow = () => {
    return (
      <tr>
        {visibleHeaders?.map((tableHead) => {
          const classNames = tableHead.key === "status" ? "first-column" : tableHead?.key === "targeting" ? "second-column" : ""
          return metricsToFormat.includes(tableHead.key) ? (
            <>
              <td>Current</td> <td>Previous</td>
            </>
          ) : (
            <td className={classNames}></td>
          );
        })}
      </tr>
    );
  };

  const handleEditableCellClick = (data) => {
    dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.EDIT_TABLE_CELL,data:data})
  }

  const handleCancelEditableCell = (data) => {
    dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.CANCEL_EDIT_TABLE_CELL,data:data})
  }

  const handleEditableValueChange = (e,data) => {
    const value = e.target.value
    data["value"] = value
    dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.KEY_VALUE_CHANGE,data:data})
  }

 const updateEditableCell = (data) => {
    setActionType("updateCellValue")
    setCurrentDataForAction(data)
    setAlertText("Are you sure that you want to update this?")
    dispatch(campaignListActions.showActionAlertModal())
 }

  function formatMetrics(data, dataKey) {
    const allKeys = [{key:"legacyForSales",value:"Down"},{key:"autoForSales",value:"Up and Down"}]
    const notToDecimalConversion = ["agg_spends","agg_sale_value","agg_orders","agg_impressions","agg_clicks"]
   const findedVal = allKeys.find((keyData) => keyData.key === data)?.value
    const mainData = (metricsToFormat.includes(dataKey) || (dataKey === "agg_br" || dataKey === "dbr"))
      ? notToDecimalConversion?.includes(dataKey) ? numeral(data)?.format(format) : numeral(data)?.format(formatDecimal) 
      : findedVal ? findedVal: data;
    return mainData
  }
  

  function getSearchValue(filters) {
    const { input, select } = filters;
    if (input.trim() === "") {
      setSearchFilters([]);
    } else {
      const filter = fromAPI ? {field_name:select==="equals" ? `exact_${search_by}` : search_by , operator:"EQUALS", value:input} :{ member: `${cube}.${search_by}`, operator: select, values: [input] }
      setSearchFilters([
        filter
      ]);
    }
  }

  function onSearchModalHandler (columnName) {
    setAllFilterVisible({ ...allFiltersVisible, searchSortModal: true });
    setClickedColumnName(columnName)
  }

  function getUpdatedModalFilters (data,keyToBeSet) {
   const updatedFilters = tableFilters?.map((tableFilter) => {
    if(tableFilter.key === keyToBeSet) {
      tableFilter.default = data?.map(inst => inst[tableFilter?.field_key]);
      if(tableFilter?.different_key) {
        tableFilter.valuesToSendInFilters = data?.map((inst) => inst[tableFilter?.filter_value_key])
      }
    }
    return tableFilter;
   }) 
   setConfigData((prev) => {
    return {
      ...prev,
      tableFilters:updatedFilters
    }
   })
   setRefreshData(!refreshData)
  }

  const renderSearchModal = (data) => {
    const {name} = data 
  const currentTableFilter =  tableFilters.find((tableFilter) => tableFilter?.placeHolderName === name)
    return (
      <div
      className={`fieldnamme-with-search-iconn justify-content-start`}
    >
      <span className="d-flex align-items-center me-2">
        <img
          // className="find-icon cursor-pointer-global"
          className={
             (currentTableFilter?.default?.length && currentTableFilter)
              ? "find-icon cursor-pointer-global mt-find-icon-activated"
              : "find-icon cursor-pointer-global"
          }
          src={whiteFindIcon}
          onClick={() =>
            onSearchModalHandler(name)
          }
          alt={"whiteFindIcon"}
        ></img>
      </span>
      <div className="d-flex align-items-center test">
        <span
          className={
             currentTableFilter?.default?.length
              ? "active-filter-higlighted-text"
              : "stat-heads-with-icon "
          }
        >
          {name}
        </span>
      </div>
      {name === clickedColumnName &&
        allFiltersVisible.searchSortModal && (
          <MasterTargetingSearchSelectModal
            findClickedColumnName={clickedColumnName}
            keyToBeSet={
            currentTableFilter.key
            }
            getUpdatedDrowDownFilters={
              getUpdatedModalFilters
            }
            isVisible={
              allFiltersVisible.searchSortModal
            }
            initialData={
            currentTableFilter?.options
            }
            defaultSelected={true}
            defaultSelectedValue={currentTableFilter.default}
            cancelFilter={setAllFilterVisible}
            currentFilters={allFiltersVisible}
          />
        )}
    </div>
    )
  }

  function measuresParser(measures) {
    return measures?.map((measure) => `${cube}.${measure}`);
  }

  function handleToggleAllCheckBoxes (status,expandable) {
      dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.SELECT_ALL_ROWS_FROM_HEADER,data:{
        status:status,
        expandable:expandable,
        restrictFunc:restrictFunc
      }})
  }

  const renderTableHeader = (data, isComparisonView) => {
    const { name, sorting, key, isSelected, sort_order, isSelectAll } = data;
    const isMetricUnderComparison =
      metricsToFormat?.includes(key) && isComparisonView;
    return (
      <th
        key={name}
        className={`targeting-table-heads cursor-pointer ${
          (key === order[0] || (tableFilters?.find((tableFilter) => tableFilter?.placeHolderName === name))?.default?.length) ? "selected" : ""
        }`}
        colSpan={isMetricUnderComparison ? 2 : null}
      >
        {
          isTableFilter && tableFilters?.find((data) => data?.placeHolderName === name) ? renderSearchModal(data) :  <div className="d-flex gap-1 align-items-center text-nowrap">
          {isSelectAll && <CheckboxRb onChange={(e) => {
                   e.stopPropagation()
                  handleToggleAllCheckBoxes(e.target.checked,isExpandable)
          }}/>}
          <div>{humanize(name)}</div>
          {sorting && (
            <img
              onClick={() => handleSort(data)}
              className={
                key === order[0] ? "searchIcon sortSelected" : "searchIcon"
              }
              src={sort_order === "desc" ? descendingIconSvg : ascendingIconSvg}
              alt="ASC"
            />
          )}
        </div>
        }
       
      </th>
    );
  };

  const filterByGroups = (e) => {
    const chooseOption = e;
    if(name === "Targeting") {
      const parsedData = JSON.parse(JSON.stringify(configData));
      const newData = JSON.parse(JSON.stringify(GROUP_BY_CONFIGS[chooseOption]));
      const newTableHeaders = getChildVisibleHeaders(newData.tableHeaders,parsedData["tableHeaders"],COMMON_METRICS);
      const oldFilters = parsedData["filters"];
      const newFilters = newData?.filters
      parsedData["tableHeaders"] = newTableHeaders
      parsedData["filters"] = mergeFilters(oldFilters,newFilters)
      parsedData["cube"] = newData.cube
      parsedData["metrics"] = newData.metrics
      parsedData["group_by"]["default"] = chooseOption
      parsedData["dimensions"] = newData?.dimensions
      parsedData["timeDimension"] = newData?.timeDimension
      parsedData["link_key"] = newData?.link_key
      parsedData["isExpandable"] = newData?.isExpandable
      parsedData["parentRowEditable"] = newData?.parentRowEditable
      // Commenting out this one will figure out some way to do this in generic way (Tech Debt)
      // parsedData["isTableFilter"] = newData?.isTableFilter
      // parsedData["tableFilters"] = newData?.tableFilters?.map((data) => {
      //   data.default = []
      //   return data
      // })
      // if(newData?.tableFilters?.length) {
      //   setRefreshTableFilters(!refreshTableFilters)
      // }
      setConfigData(parsedData)
      setRefreshData(!refreshData)
    }
  };

  const filterByType = (value) => {
    setQuickFiltersType(value)
    if(quickFilterName!=="" && quickFilterName!=="unknown") {
      handleQuickFilterClick(value,quickFilterName,true)
    }
  }

  async function getChildRows(cubeConfigData,apiKey) {
    return new Promise((resolve, reject) => {
      if(fromAPI) {
        masterApi(cubeConfigData,masterApiKey,false).then((response) => {
          if(response?.data?.data) {
            resolve(response?.data?.data)
           }
        })
      } else {
        cubejsApi
        .load(cubeConfigData, {
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        })
        .then((response) => {
          resolve(response);
        });
      }
      
    });
  }

  async function getMoreData(data) {
    let APIdata = cubeConfigs;
    let mainData = [];
    if (fromAPI) {
      if (masterApiKey) {
        if (masterApiKey === "getOptimisedBids") {
          const top_performing_order_filter_previous = {field_name:"agg_dd_orders",operator:"GREATER_THAN_EQUAL_TO",value:3};
          if(quickFilterName === "Top Performing") {
            allFilters.push(top_performing_order_filter_previous)
           }
           const low_performing_order_filter_previous = {field_name:"agg_dd_clicks",operator:"GREATER_THAN_EQUAL_TO",value:40};
           if(quickFilterName === "Low Performing") {
            allFilters.push(low_performing_order_filter_previous)
           }
          const filtering = [...allFilters];
          const startDate = calendarDate[0].startDate;
          const endDate = calendarDate[0].endDate
          const { selection1, selection2 } = multiCalendarDate;
          const { startDate: start_date_first, endDate: end_date_first } =
            selection1;
          const { startDate: start_date_second, endDate: end_date_second } =
            selection2;
          APIdata = {
            source: "amazon",
            order_by: order[0],
            sorting_order: order[1],
            duration_one_start_date: formatDateFn( isComparisonView ? start_date_first : startDate),
            duration_one_end_date: formatDateFn(isComparisonView ? end_date_first : endDate),
            duration_two_start_date: formatDateFn(start_date_second),
            duration_two_end_date: formatDateFn(end_date_second),
            limit: record_limit,
            suggested_bid_required: false,
            master_row:isExpandable,
            offset: data?.offset,
            filtering: encodeData(filtering),
          };
        }
        // const response = await masterApi(dataForAPI,"getOptimisedBids",false);
        // if(response?.data?.data) {
        //   mainData = response?.data?.data
        // }
      }
    } else {
      APIdata["offset"] = data?.offset;
    }

    mainData = await getChildRows(APIdata);

    return mainData;
  }

  function recieveLoadedData (data) {
    const {resultSetData} = cubeQueryResultFormatter(data,normalViewType,metricsToFormat,cube)
    dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.ADD_MORE_DATA,data:fromAPI ? data : resultSetData})
  }

  const handleBarOptionClick = (data) => {
    const key = data.includes("Align") ? "Align" : data
    
    if(key === "Adjust Bid") {
      setBulkBidModal(true)
    } else {
      const alertMessage = ALERT_MESSAGE[key] ?? "Are you sure about this action?"
      setAlertText(alertMessage)
      setActionType("bulk"+key)
      dispatch(campaignListActions.showActionAlertModal());
    }
   
  }


  function cellValueUpdaterFunction(receivedData,dataForUpdate) {
    const {isChildRow,childIndex,parentIndex} = dataForUpdate
    let updatedResultSet = JSON.parse(JSON.stringify(receivedData))
    if(isChildRow) {
      let childRow = updatedResultSet[parentIndex]?.child_rows[childIndex];
      childRow.bid_copy = childRow['current_bid']
      childRow.isEditable = false
      childRow.targeting_expression.target[0].bid = childRow['current_bid']
      updatedResultSet[parentIndex].child_rows[childIndex] = childRow
    } else {
      let parentRow = updatedResultSet[parentIndex]
      parentRow.bid_copy = parentRow['current_bid']
      parentRow.isEditable = false
      parentRow.targeting_expression.target[0].bid = parentRow['current_bid']
      updatedResultSet[parentIndex] = parentRow
    }
    return updatedResultSet
  }

  async function onYesClick () {
   if(actionType==="childToggle") {
    setOverlayLoader(true)
    const {parentIndex,childIndex} = currentDataForAction 
    const currentData = resultSetData[parentIndex]?.child_rows[childIndex]
    const formattedData = formatter(currentData,actionType)
    const result = await updateFunction(formattedData);
    if(result?.statusCode!==200) {
      setOverlayLoader(false)
      toast.error("Some Unknown Error Occurred while Updating")
    }
    if(result?.statusCode===200) {
      toast.success("Updated Successfully")
      setOverlayLoader(false)
      dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.TOGGLE_ENABLE_PAUSE,data:{...currentDataForAction,pointerKey}})
    }
   }

   if(actionType==="parentToggle") {
    const {parentIndex,childIndex} = currentDataForAction 
    const currentData = resultSetData[parentIndex]
    const formattedData = formatter(currentData,actionType)
    setOverlayLoader(true)
    const result = await updateFunction(formattedData);
    if(result?.statusCode!==200) {
      setOverlayLoader(false)
      toast.error("Some Unknown Error Occurred while Updating")
    }
    if(result?.statusCode===200) {
      setOverlayLoader(false)
      toast.success("Updated Successfully")
      dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.TOGGLE_ENABLE_PAUSE,data:{...currentDataForAction,pointerKey}})
    }
   }
   
   if(actionType==="updateCellValue") {
    const {isChildRow,parentIndex,childIndex,key} = currentDataForAction
    const mainData = returnDataAccordingtoIndex(isChildRow,parentIndex,childIndex,resultSetData)
    const formattedData = formatter(mainData,actionType);
    setOverlayLoader(true)
    const result = await updateFunction(formattedData);
    if(result?.statusCode!==200) {
      setOverlayLoader(false)
      toast.error("Some Unknown Error Occurred while Updating")
    }
    if(result?.statusCode===200) {
      setOverlayLoader(false)
      toast.success("Updated Successfully")
    const updatedResultSet = cellValueUpdaterFunction(resultSetData,currentDataForAction)
      dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.SET_INITIAL_STATE,data:updatedResultSet})
    }
   }

   if(actionType.includes("bulk")) {
    setOverlayLoader(true)
    const formattedData = formatter(selectedRows,actionType)
    const result = await updateFunction(formattedData);
    if(result?.statusCode!==200) {
     setOverlayLoader(false)
     toast.error("Some unknown error occurred")
    }
    if(result?.statusCode===200) {
     setOverlayLoader(false)
     toast.success("Updated Successfully")
     const data = {
      isChildRow:isExpandable,
      keyForChange:"status",
      findKey:"target_id",
      keyValue:actionType === "bulkPause" ? "PAUSED" : "ENABLED",
      selectedRows:selectedRows
     }
     dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.UPDATE_BULK_ROWS,data:data})
    }
   }
  }

  function returnDataAccordingtoIndex(
    isChildRow,
    parentIndex,
    childIndex,
    data
  ) {
    let mainData;
    if (isChildRow) {
      mainData = data[parentIndex]?.child_rows[childIndex];
    } else {
      mainData = data[parentIndex];
    }
    return mainData;
  }
 
  const cancelSelectedRows = () => {
    const updatedData = removeSelectedFields(resultSetData)
    dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.SET_INITIAL_STATE,data:updatedData})
  }

 async function getData(adjustBidInfo) {
  try {
    setOverlayLoader(true)
    const mainData = {selectedRows,adjustBidInfo}
    const formattedData = formatter(mainData,"adjustBid");
    const result = await updateFunction(formattedData)
    if(result?.statusCode===200) {
      setOverlayLoader(false)
      toast.success("Bids Updated Successfully Please check after sometime!")
    }
    if(result?.statusCode!==200) {
      setOverlayLoader(false)
      toast.error("Some unknown error occurred")
    }
  } catch (error) {
    toast.error(error)
  }
    
  }

  function getAppliedFiltersForMetricFilters (data,type) {
    const {percentageFilters,normalFilters} = data
    if(type === "normal") {
      setNormalFilters({agg:normalFilters,drr:percentageFilters})
    } else {
      setComparisonFilters({agg:normalFilters,drr:percentageFilters})
    }
   setRefreshData(!refreshData)
  }


  const handleChipFilterClick = (fieldName) => {
    const isSmartFilters = quickFilterName !=="";
    const isInDropDownFilters = configData["filters"]?.find((data) => data?.key === fieldName)
    const parsedData = JSON.parse(JSON.stringify(configData))
    if(isInDropDownFilters) {
      parsedData["filters"] = parsedData["filters"]?.map((data) => {
        if(data?.key === fieldName) {
          data.default = []
        }
        return {...data}
      })
      setConfigData(parsedData)
    }
    const allNormalFilters = Object.values(normalFilters).flat(1)
    const isInNormalFilters = allNormalFilters.find((data) => data?.field_key === fieldName);
    if(isInNormalFilters) {
      const normalFiltersParsed = removeParticularMinMaxFilter(normalFilters,fieldName)
      setConfigData(parsedData)
      setNormalFilters(normalFiltersParsed)
    }
    const allComparisonFilters = Object.values(comparisonFilters).flat(1);
    const isInPercentageFilters = allComparisonFilters.find((data) => data?.field_key === fieldName);
    if(isInPercentageFilters) {
      const comparisonFiltersParsed = removeParticularMinMaxFilter(comparisonFilters,fieldName)
      setConfigData(parsedData)
      setComparisonFilters(comparisonFiltersParsed)
    }
    const isInTableFilters = configData["tableFilters"]?.find((data) => data?.key === fieldName)
    if(isInTableFilters) {
      const parsedData = JSON.parse(JSON.stringify(configData));
      parsedData["tableFilters"] = parsedData["tableFilters"]?.map((data) => {
        if(data?.key === fieldName) {
          data.default = []
        }
         return {...data}
      })
      setConfigData(parsedData)
    }

    const smartFilterKeys = ["Top Performing","Low Performing"];
 
   if(isSmartFilters && smartFilterKeys?.includes(fieldName)) {
    const parsedConfigData = JSON.parse(JSON.stringify(configData))
    parsedConfigData["filters"] = parsedConfigData["filters"]?.map((data) => {
      if(data?.key === "campaign_objective") {
        data.default = []
      }
      return data
    })
    const parsedNormalFilters = JSON.parse(JSON.stringify(normalFilters));
    parsedNormalFilters["agg"] = parsedNormalFilters["agg"].map((data) => {
      const removalKeys = ["agg_roas", "agg_orders"];
      if (removalKeys?.includes(data?.field_key)) {
        data.minValue = "";
        data.maxValue = "";
        data.disabled = false;
        data.permanentDisabled = false;
      }
      return data;
    });
    setQuickFilterName("")
    setConfigData(parsedConfigData);
    setNormalFilters(parsedNormalFilters);
   }
    setRefreshData(!refreshData)
  }

  // function removeParsedFilters

 
  function recieveData (data) {
   const parsedData = JSON.parse(JSON.stringify(configData));
   parsedData["tableHeaders"] = data
   setConfigData(parsedData)
  }


  function handleDownload () {
    const filteredMetrics = metricsToFormat.filter((data) => metricViewType===false ? data?.includes("agg") : !data?.includes("agg"))
   const tableHeadersExcel = createHeadersForExcelDownload(tableHeaders,isComparisonView,filteredMetrics);
   const excelRowForSummary = createExcelBody(summaryDataToShow,tableHeaders,filteredMetrics,isComparisonView);
   excelRowForSummary[0][0] = "Summary";
   const excelBody = createExcelBody(dataToShow,tableHeaders,filteredMetrics,isComparisonView);
   const overallExcelBody = [...excelRowForSummary,...excelBody]
   downloadCsv(tableHeadersExcel,overallExcelBody);
  }

  const renderAdditonalFilters = useCallback(() => {
    return (
      <>
       <OptimizerFilters classNames={"flex-column"} handleDropdown={getAppliedFilters} filtersData={filters?.slice(4,8)}/>
      </>
    )
  },[filters])



  const applyQuickFilter = (data, type, normalFilters,campaignObjective) => {
    const parsedData = JSON.parse(JSON.stringify(data));
    const parsedNormalFilters = JSON.parse(JSON.stringify(normalFilters));
    const parsedConfigData = { ...configData };
    const updateFilters = (minRoas, maxRoas, minOrders) => {
      parsedNormalFilters["agg"] = parsedNormalFilters["agg"]?.map((data) => {
        if (data?.field_key?.includes("agg_roas")) {
          data.minValue = minRoas;
          data.maxValue = maxRoas || "";
          data.disabled = true;
          data.permanentDisabled = true;
        }
        if (data?.field_key?.includes("agg_orders")) {
          data.minValue = minOrders || "";
          data.disabled = data.minValue!=="" ? true : data.disabled;
          data.permanentDisabled= data.minValue!=="" ? true : data.permanentDisabled;
        }
        return data;
      });
    };
  
    const updateConfigData = () => {
      parsedConfigData["filters"] = parsedConfigData["filters"]?.map((data) => {
        if (data?.key === "campaign_objective") {
          data.default = [campaignObjective];
        }
        return data;
      });
    };
  
    if (type === "Top Performing") {
      if(parsedData[0]?.hasOwnProperty("summary_agg_roas")) {
        const calculatedRoas = parsedData[0]["summary_agg_roas"] * 1.3;
        updateConfigData();
        updateFilters(calculatedRoas, "", 2);
      }

    }
  
    if (type === "Low Performing") {
      if(parsedData[0]?.hasOwnProperty("summary_agg_roas")) {
        const calculatedMinROAS = parsedData[0]["summary_agg_roas"] * 0.3;
        const calculatedMaxROAS = parsedData[0]["summary_agg_roas"] * 0.8;
        updateConfigData();
        updateFilters(calculatedMinROAS, calculatedMaxROAS,"");
      }

    }
    
    if(parsedData[0]?.hasOwnProperty("summary_agg_roas")) {
      setNormalFilters(parsedNormalFilters);
      setRefreshData(!refreshData);
      setConfigData(parsedConfigData);
    }

    

  };
  


  const handleQuickFilterClick = async (campaignObjective, filterName,cancelFilter=false) => {
    const state = (quickFilterName !== filterName) || (quickFilterName === filterName && cancelFilter)
    if (state) {
      if(masterApiKey === "getOptimisedBids") {
        if((!previousData?.length)) {
          let dataForAPI = {
            source: "amazon",
            order_by: order[0],
            sorting_order: order[1],
            duration_one_start_date: formatDateFn(moment().subtract(6, "days")._d),
            duration_one_end_date: formatDateFn(moment()._d),
            duration_two_start_date: formatDateFn(moment().subtract(13, "days")._d,),
            duration_two_end_date: formatDateFn(moment().subtract(7, "days")._d),
            master_row:isExpandable,
            suggested_bid_required: false,
            filtering: encodeData([{"field_name":"campaign_objective","operator":"IN","values":[campaignObjective]}])
          };
            dataForAPI["summary_data"] = true
            setQuickFilterLoading(true);
          const response = await masterApi(dataForAPI,"getOptimisedBids",false);
          if(Object.keys(response?.data?.data[0]).length) {
            setQuickFilterLoading(false)
            // setPreviousData(response?.data?.data)
            // If there is no property exist then change the filter type to previous and show error
            if(!response?.data?.data[0]?.hasOwnProperty("summary_agg_roas")) {
              if(mainChipFilters?.length) {
                handleChipFilterClick(filterName)
              }
              toast.error(`Data doesn't exists for quick filters (${campaignObjective})`)
             }
            applyQuickFilter(response?.data?.data,filterName,normalFilters,campaignObjective)
          } else {
            setQuickFilterLoading(false)
            toast.error("No Smart Filter Data To Show")
          }
          if(response?.statusCode!==200) {
            setQuickFilterLoading(false)
            toast.error('Some unknown error occurred while applying smart filters')
          }

          if(cancelFilter === false && response?.data?.data[0]?.hasOwnProperty('summary_agg_roas')) {
            setQuickFilterName((prev) => {
              return (prev === filterName && cancelFilter===false) ? "" : filterName
            })
          }
        }
        // if(previousData?.length) {
        //   applyQuickFilter(previousData,filterName,normalFilters)
        // }   
        
      }
 
 
    }
    if(metricViewType===true) {
      setMetricViewType(false)
    }

    if(quickFilterName === filterName && cancelFilter === false) {
      handleChipFilterClick(quickFilterName)
    }

  };


  const renderQuickFilters = useCallback(() => {
    return (
      <>
        <CustomDropDown
          filterCampaigns={(e) => {
            filterByType(e.target.value);
          }}
          autoWidth={true}
          data={["Prospect", "Retarget"]}
          targettingValue={quickFiltersType}
          src={dropDownIcon}
          title={"Filter For"}
          id={"quick-filter-dropdown"}
        />
        <div className="d-flex gap-1 mt-2">
          <SecondaryBtnRb
            disabled={quickFilterLoading}
            infoRequired={true}
            disabledText={"Loading..."}
            tooltipId={'top-performing-filter'}
            tooltipcontent={toolTipContent["Top Performing"]}
            secondaryBtnText={`${quickFilterLoading ? "Loading...":"Top Performing"}`}
            className={
              quickFilterName === "Top Performing"
                ? "primary-active-btn  ennable-paused-btns-height d-flex gap-2 text-nowrap"
                : "primary-inactive-btn ennable-paused-btns-height d-flex gap-2 text-nowrap"
            }
            id="top-performing-filter-btn"
            value="all-btn"
            onClick={(e, type) => {
              handleQuickFilterClick(quickFiltersType, "Top Performing");
            }}
          ></SecondaryBtnRb>
          <SecondaryBtnRb
           disabled={quickFilterLoading}
           disabledText={"Loading..."}
           tooltipId={'low-performing-filter'}
           tooltipcontent={toolTipContent["Low Performing"]}
           infoRequired={true}
           secondaryBtnText={`${quickFilterLoading ? "Loading...":"Low Performing"}`}
            className={
              quickFilterName === "Low Performing"
                ? "primary-active-btn  ennable-paused-btns-height d-flex gap-2 text-nowrap"
                : "primary-inactive-btn ennable-paused-btns-height d-flex gap-2 text-nowrap"
            }
            id="low-performing-filter-btn"
            value="all-btn"
            onClick={(e, type) => {
              handleQuickFilterClick(quickFiltersType, "Low Performing");
            }}
          ></SecondaryBtnRb>
        </div>
      </>
    );
  },[normalFilters,quickFiltersType,quickFilterName,quickFilterLoading])

  const handleButtonStatus = (status) => {
    setSelectedBtn(status)
    setRefreshData(!refreshData)
  }

  const onNoClick = () => {
    if(actionType === "updateCellValue") {
      // if(masterApiKey === "getOptimisedBids") {
        const {isChildRow,parentIndex,childIndex,key} = currentDataForAction
        const mainData = returnDataAccordingtoIndex(isChildRow,parentIndex,childIndex,resultSetData)
        const bidData = mainData?.targeting_expression?.target[0]?.bid;
        currentDataForAction["value"] = bidData
        dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.KEY_VALUE_CHANGE,data:currentDataForAction})
        dispatchResultSet({action:OPTIMIZER_TABLE_ACTIONS.EDIT_TABLE_CELL,data:currentDataForAction})
        setAlertText("")
      }
    // }
  }

  const handleSaveConfig = async  (config) => {
    try {
      
      // If user doesn't have configs then POST request Else PUT request
      const requestType = Object.keys(userConfigData)?.length ? "put":"post";
      const payloadData = {
        key:custom_config_key,
        value:config
      }
      const response = await masterApi(payloadData,"updateClientConfig",false,requestType,userConfigData?.id);
      if(response?.statusCode === 200) {
        toast.success("Column Configs Saved Successfully");
      } else {
        toast.error("Some unknown error occurred while saving configs");
      }
      if(requestType === "post") {
        setUserConfigData((prev) => {
          return {
            ...prev,
            id:response?.data?.id
          }
        })
      }
    } catch (error) {
      toast.error("Some unknown error occurred")
    }
  }

  const handleMetricViewType = () => {
    // If there is some filtering in the current view type then remove it from the filter
    if(minMaxNormalFiltering.length) {
         if(metricViewType === false) {
          const parsedFiltersAggregated = JSON.parse(JSON.stringify(normalFilters))?.agg;
          const updatedParsedFilters = parsedFiltersAggregated?.map((data) => {
            data.minValue = "";
            data.maxValue = "";
            return data
          })
          setNormalFilters((prev) => {
            return {
              ...prev,
              agg:updatedParsedFilters
            }
          })
          setRefreshData(!refreshData)
         }

         if(metricViewType === true) {
          const parsedFiltersDRR = JSON.parse(JSON.stringify(normalFilters))?.drr;
          const updatedParsedFilters = parsedFiltersDRR?.map((data) => {
            data.minValue = "";
            data.maxValue = "";
            return data
          })
          setNormalFilters((prev) => {
            return {
              ...prev,
              drr:updatedParsedFilters
            }
          })
          setRefreshData(!refreshData)
         }
    }
    if(minMaxComparisonFiltering.length) {
      if(metricViewType === false) {
        const parsedFiltersAggregated = JSON.parse(JSON.stringify(comparisonFilters))?.agg;
        const updatedParsedFilters = parsedFiltersAggregated?.map((data) => {
          data.minValue = "";
          data.maxValue = "";
          return data
        })
        setComparisonFilters((prev) => {
          return {
            ...prev,
            agg:updatedParsedFilters
          }
        })
        setRefreshData(!refreshData)
       }

       if(metricViewType === true) {
        const parsedFiltersDRR = JSON.parse(JSON.stringify(comparisonFilters))?.drr;
        const updatedParsedFilters = parsedFiltersDRR?.map((data) => {
          data.minValue = "";
          data.maxValue = "";
          return data
        })
        setComparisonFilters((prev) => {
          return {
            ...prev,
            drr:updatedParsedFilters
          }
        })
        setRefreshData(!refreshData)
       }
    }   
    setMetricViewType(!metricViewType)
  }

  const handleQuickFilters = () => {
    setQuickFiltersModal(!quickFiltersModal)
  }

  const resetAllFilters = () => {
    const parsedConfigData = JSON.parse(JSON.stringify(configData));
    if(configData.filters.some((data) => data.default.length) || configData.tableFilters.some((data) => data.default.length)) {
      parsedConfigData["filters"] = parsedConfigData["filters"]?.map((data) => {
        if(data.default.length) {
          data.default = []
        }
        return {...data}
      })

      parsedConfigData["tableFilters"] = parsedConfigData["tableFilters"]?.map((data) => {
        if(data.default.length) {
          data.default = []
        }
        return {...data}
      })

    }

    const parsedNormalFilters = JSON.parse(JSON.stringify(normalFilters));
    const removalKeys = ["agg_roas", "agg_orders"];

    parsedNormalFilters["agg"] = parsedNormalFilters["agg"].map((data) => {
         if(quickFilterName!=="" && removalKeys?.includes(data?.field_key)) {
          data.disabled = false
          data.permanentDisabled = false
         }
         data.minValue = "";
         data.maxValue = ""
       return data
    })
    parsedNormalFilters["drr"] = parsedNormalFilters["drr"].map((data) => {
      data.minValue = "";
      data.maxValue = ""
    return data
    })

    const parsedComparisonFilters = JSON.parse(
      JSON.stringify(comparisonFilters)
    );
    parsedComparisonFilters["agg"] = parsedComparisonFilters["agg"].map(
      (data) => {
        data.minValue = "";
        data.maxValue = "";
        return data;
      }
    );
    parsedComparisonFilters["drr"] = parsedComparisonFilters["drr"].map((data) => {
      data.minValue = "";
      data.maxValue = "";
      return data
    })

    if(quickFilterName!=="") {
      setQuickFilterName("");
    }
    setNormalFilters(parsedNormalFilters);
    setComparisonFilters(parsedComparisonFilters)
    setConfigData(parsedConfigData)
    setRefreshData(!refreshData)
  }

  const reportMenus = [{ 
    label: "Reports",
    status: true,
    pages:[
      {
      label: "Match Type Report",
      status: true,
      href:"/match-type-report"
    },
    {
      label: "Targeting Group Report",
      status: true,
      href:"/targeting-group-report"
    },
  ]
  }]
   
   

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      // id="optimizer-table-main-container"
    >
     <FiltersModal
     classes={"quick-filter-modal"}
      show={quickFiltersModal}
      modalHeading={"Quick Filters"}
      renderMainModalBody={renderQuickFilters}
      onHide={() => {
        setQuickFiltersModal(false);
      }}
     />

      <FiltersModal
        renderMainModalBody={renderAdditonalFilters}
        modalHeading={"More Filters"}
        show={modalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
      />
        <ReactTooltip
          id="metric-view-type-tooltip"
          style={{ zIndex: "999999", fontSize: "12px", padding: "8px" }}
          effect="solid"
          place="top"
          variant="dark"
        />
        <ReactTooltip 
            id="comparison-view-tooltip"
            style={{ zIndex: "999999", fontSize: "12px", padding: "8px" }}
            effect="solid"
            place="top"
            variant="dark"
        />
         <ReactTooltip 
            id="quick-filters-tooltip"
            style={{ zIndex: "999999", fontSize: "12px", padding: "8px" }}
            effect="solid"
            place="top"
            variant="dark"
        />
          <ReactTooltip 
            id="download-tooltip"
            style={{ zIndex: "999999", fontSize: "12px", padding: "8px" }}
            effect="solid"
            place="top"
            variant="dark"
        />
      <Loader visible={overlayLoader} />
      <MasterTagetinngActionAlertMModal
        onYesClick={onYesClick}
        alertText={alertText}
        onNoClick={onNoClick}
        confirmationId={confirmationId}
      />
      <EditBidModalForTargeting
        getData={getData}
        setBidAs={true}
        cancel={() => setBulkBidModal(false)}
        show={showBulkBidModal}
        confirmationId={confirmationIdForBidAdjust}
      />
      <div className="campaign-list-container overflow-hidden">
        <Stack>
          <Breadcrumb />
          <Stack direction="horizontal" className="d-flex align-item-center">
            <SectionTitle sectionTitleHeading={name} />
          </Stack>
          {isChartRequired ? renderMainGraph(chartName, chartData) : null}
          {/* <div className="mb-2 d-flex w-100 align-items-center gap-2"> */}
          <div className="d-flex gap-3 align-items-center">
            <SearchWithSelect
              classNames={`mt-1 optimizer-search-bar`}
              id={'optimizer-search-bar'}
              Value={getSearchValue}
            />
            <div className="d-flex gap-3">
            <div>
            <CustomDropDown filterCampaigns={(e) => {
              filterByGroups(e.target.value)
            }} classes={'optimizer-dropdown'} data={group_by.options} targettingValue={group_by.default} src={dropDownIcon} title={"Group Data By"} id={"group-by"}/>
            </div>
            <div>
              <OptimizerFilters
                handleDropdown={getAppliedFilters}
                filtersData={filters.slice(0, 4)}
              />
            </div>
            </div>
      
            <div
              className="select-button-container cursor-pointer-global text-center more-filters-btn select-div"
              onClick={() => setModalVisible(true)}
            >
              <div className="selected-opt-show-btn">More Filters</div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center"> 
          {(
            <Stack className="d-flex flex-row align-items-center">
              <div>
                <SecondaryBtnRb
                  secondaryBtnText={`All (${ rowCountLoading ? "Loading..." : allRowsCount?.total_count})`}
                  className={
                    selectedBtn === "all-btn"
                      ? "primary-active-btn  ennable-paused-btns-height "
                      : "primary-inactive-btn ennable-paused-btns-height "
                  }
                  id="all-btn"
                  value="all-btn"
                  onClick={(e, type, value) => handleButtonStatus("all-btn")}
                ></SecondaryBtnRb>
              </div>
              <div>
                <SecondaryBtnRb
                  secondaryBtnText={`Enabled (${ rowCountLoading ? "Loading..." :allRowsCount?.enabled_count ?? 0})`}
                  className={
                    selectedBtn === "ENABLED"
                      ? "primary-active-btn ennable-paused-btns-height "
                      : "primary-inactive-btn ennable-paused-btns-height "
                  }
                  id="enabled-btn"
                  value="ENABLED"
                  onClick={(e, type, value) => {
                    handleButtonStatus("ENABLED");
                  }}
                ></SecondaryBtnRb>
              </div>
              <div>
                <SecondaryBtnRb
                  secondaryBtnText={`Paused (${ rowCountLoading ? "Loading..." : allRowsCount?.paused_count ?? 0})`}
                  className={
                    selectedBtn === "PAUSED"
                      ? "primary-active-btn ennable-paused-btns-height "
                      : "primary-inactive-btn ennable-paused-btns-height "
                  }
                  id="paused-btn"
                  value="PAUSED"
                  onClick={(e, type, value) => handleButtonStatus("PAUSED")}
                ></SecondaryBtnRb>
              </div>
            </Stack>
          )}      
            <div className="d-flex">
              <div className="mb-2 all-features-btns d-flex align-items-center gap-1">
              <button
                  className={`primary-inactive-btn apply-filter d-flex align-items-center ${
                    quickFiltersModal ? "optimizer-btn-icon" : ""
                  }`}
                  data-tooltip-content={"Quick Filters"}
                  data-tooltip-id={"quick-filters-tooltip"}
                  id="quick-filters-btn"
                  onClick={handleQuickFilters}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <SiQuicklook height={40} />
                  </div>
                </button>
              <button
                  className={`primary-inactive-btn apply-filter d-flex align-items-center ${
                    metricViewType ? "optimizer-btn-icon" : ""
                  }`}
                  data-tooltip-id={"metric-view-type-tooltip"}
                  data-tooltip-content={`Current View:- ${metricViewType ? "DRR" : "Aggregated"}`}
                  id="view-change-btn"
                  onClick={handleMetricViewType}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <GrAggregate height={40} />
                  </div>
                </button>
                <button
                  className={`primary-inactive-btn apply-filter d-flex align-items-center ${
                    !isComparisonView ? "optimizer-btn-icon" : ""
                  }`}
                  data-tooltip-content={"Toggle (Comparison / Normal View)"}
                  data-tooltip-id="comparison-view-tooltip"
                  id="view-change-btn"
                  onClick={() =>
                    handleNormalViewType(
                      normalViewType === "Comparison" ? "normal" : "Comparison"
                    )
                  }
                >
                  <div className="d-flex gap-2 align-items-center">
                    <FaExchangeAlt height={40} />
                  </div>
                </button>
                <FilterToggleModal
                  buttonClassNames={"optimizer-btn-icon"}
                  partitionRequired={false}
                  metrics={["Action", "Metric(Columns)"]}
                  firstColumns={tableHeadersAggregate}
                  secondColumns={[]}
                  allColumns={tableHeaders}
                  isToggle={true}
                  dynamicIcon={<HiOutlineViewColumns height={40} />}
                  isDynamicIcon={true}
                  column1Heading={""}
                  recieveData={recieveData}
                  id={'columns-hide-show'}
                  columnsWidth={"300px"}
                  resetApplyButton={true}
                  isToolTipRequired={true}
                  resetButtonRequired={false}
                  onApply={handleSaveConfig}
                  // buttonHeading={"Columns"}
                />
                <DynamicFilterModal
                  percentageColumnRequired={true}
                  buttonClassNames={"optimizer-btn-icon"}
                  columns={normalFilters?.agg}
                  column1Heading={"Filter (Aggregate)"}
                  column2Heading={"Filter (DRR)"}
                  isDynamicIcon={true}
                  cancelFilterFor={activeCancelStatFilterField}
                  percentageColumns={normalFilters?.drr}
                  getAppliedFilters={(data) =>
                    getAppliedFiltersForMetricFilters(data, "normal")
                  }
                  // buttonHeading={"Filters"}
                  dynamicIcon={<MdOutlineFilterAlt height={40} />}
                  alignment={"right"}
                  tooltipContent={"Normal Range Filters*"}
                  tooltipId={"normal-tooltip"}
                  id={'normal-range-filters'}
                  mainDesc={"Filter By Metric"}
                  saveFilter={true}
                />
                
                  <DynamicFilterModal
                    buttonClassNames={"optimizer-btn-icon"}
                    percentageColumnRequired={true}
                    columns={comparisonFilters?.agg}
                    column1Heading={"Filter (Aggregate)"}
                    column2Heading={"Filter (DRR)"}
                    cancelFilterFor={activeCancelStatFilterField}
                    percentageColumns={comparisonFilters?.drr}
                    isDynamicIcon={true}
                    dynamicIcon={<TbFilterDiscount height={40} />}
                    getAppliedFilters={(data) =>
                      getAppliedFiltersForMetricFilters(data, "comparison")
                    }
                    alignment={"right"}
                    id={'comparison-range-filters'}
                    isDisabled={isComparisonView===false}
                    saveFilter={true}
                    tooltipId={"comparison-tooltip"}
                    mainDesc={"Filter By Comparison (%)"}
                    tooltipContent={"Comparison Range Filters (Only Comparison View Supported*)"}
                  />
            
                <button
                  className={
                    `primary-inactive-btn apply-filter d-flex align-items-center optimizer-btn-icon ${group_by.default === "Global" ?  "disable-modal-on-active"
                    :""}`
                  }
                  data-tooltip-content={'Download Report ( Granular View Supported* )'}
                  data-tooltip-id="download-tooltip"
                  id="download-report-btn"
                  onClick={handleDownload}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <FaDownload height={40} />
                  </div>
                </button>
              
              </div>
              <div className="mt-1 px-2 d-flex align-items-center">
                <ReportsNavigator data={reportMenus}/>
                </div>
            </div>
          </div>
          <small className="paginationtext">{`Showing ${resultSetData?.length} Out Of ${allRowsCount?.total_count} Records*`}</small>
          <small className="paginationtext">{`SB optimisation not supported*`}</small>
          <div className="d-flex justify-content-end">
 
            <SelectionBar
              barOptions={[
                "Adjust Bid",
                "Pause",
                "Enable",
              ]}
              handleClick={handleBarOptionClick}
              selectedCount={selectedRows}
              handleCancel={cancelSelectedRows}
            />
          </div>
          {mainChipFilters?.length ? (
            <div className="mt-2">
              <FilterChips
                filterChipList={mainChipFilters}
                onCancelClickRemoveFilteringHandler={(fieldName) => {
                  handleChipFilterClick(fieldName);
                }}
                isResetButtonNeeded={true}
                onResetButtonClick={resetAllFilters}
              />
            </div>
          ) : null}
          <OptimizerTable
            renderTableHeader={renderTableHeader}
            tableHeaders={visibleHeaders}
            renderSummaryRow={renderSummaryRow}
            isSummaryRowRequired={true}
            renderTableRow={renderTableRow}
            resultSet={dataToShow}
            totalSummary={summaryDataToShow[0] || {}}
            renderComparisonFirstRow={renderComparisonFirstRow}
            isComparisonView={isComparisonView}
            tableLoading={loading}
            childRowsLoading={childRowsLoading}
            isExpandable={isExpandable}
            recordLimit={record_limit}
            rowsCount={allRowsCount?.total_count}
            getData={getMoreData}
            recieveLoadedData={recieveLoadedData}
            resetOffset={resetPagination}
            noDataFound={!resultSetData?.length && !loading}
            handleEditableCellClick={handleEditableCellClick}
            updateEditableCell={updateEditableCell}
            handleEditableValueChange={handleEditableValueChange}
          />
        </Stack>
      </div>
    </div>
  );
};

export default Optimizer;
