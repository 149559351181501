export const enablePauseBulkChildAd = (currentState,status) => {
    const updatedState = currentState.map((parentAd) => {
        parentAd?.childRows?.map((childAd) => {
         if(childAd.isTargetChecked) {
           childAd.status = status
           childAd.isTargetChecked = false
         }
         return childAd
       })
       return parentAd
     })

     return updatedState
}