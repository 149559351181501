/**
 * 
 * @param {*} actionType 
 * @param {*} percentage 
 * @param {*} value 
 * @returns Modified value by percentage and actionType
 */

// export default function modifyValueByPercentage (actionType,percentage,value) {
//     switch (actionType) {
//         case "increase-by":
//           // Convert the string to number
//           return Number(((value/100)*percentage + value)?.toFixed(2))
//         case "decrease-by":
//           return Number((value - (value/100)*percentage)?.toFixed(2))
//         default:
//           break;
//       }
// }

// export default function modifyValueByPercentage(actionType, percentage, value) {
//   switch (actionType) {
//     case "increase-by":
//       // Calculate the increase amount
//       const increaseAmount = (value / 100) * percentage;
//       // Add the increase amount to the original value
//       return Number((value + increaseAmount).toFixed(2));
//     case "decrease-by":
//       // Calculate the decrease amount
//       const decreaseAmount = (value / 100) * percentage;
//       // Subtract the decrease amount from the original value
//       return Number((value - decreaseAmount).toFixed(2));
//     default:
//       return value;
//   }
// }


export default function modifyValueByPercentage(actionType, percentage, value) {
  const castedVal = typeof value === "number" ? value : Number(value)
  switch (actionType) {
    case "increase-by":
      // Calculate the increase amount
      const increaseAmount = (castedVal * percentage) / 100;
      // Add the increase amount to the original value
      const increasedValue = castedVal + increaseAmount;
      // Round the increased value to 2 decimal places
      const mainVal = Number(increasedValue?.toFixed(2));
      return mainVal
    case "decrease-by":
      // Calculate the decrease amount
      const decreaseAmount = (castedVal * percentage) / 100;
      // Subtract the decrease amount from the original value
      const decreasedValue = castedVal - decreaseAmount;
      // Round the decreased value to 2 decimal places
      return Number(decreasedValue?.toFixed(2));
    default:
      return castedVal;
  }
}
