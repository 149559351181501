export const dataHeadsForAutomationLogs = [
    {
      fieldName: "Rule Set",
      id: "rule_name",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Rule Task",
      id: "rule_task_name",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Configuration",
      id: "rule_config",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Frequency",
      id: "parent_rule_config",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Data Look back window",
      id: "lookback_window",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Campaigns",
      id: "campaign_name",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Targetings",
      id: "entity_name",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Ad Set Name",
      id: "parent_entity_name",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Activity Time ",
      id: "created_at",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Change",
      id: "message",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Old Bid",
      id: "decision_old",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "New Bid",
      id: "decision_new",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Status",
      id: "decision_status",
      isDefaultHead: true,
      isAbbrivation: false,
    },
  ];