import React from "react";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../context/global";
import "./Reports.css";

const Reports = (props) => {
  const dashboardSideBarVisibiltyState = useSelector((state) => state.dashboardData.dashboardSideBarVisibiltyState);
  // const {reportData} = props
  const reportData = JSON.parse(localStorage.getItem("reportData"))
  return (
    <div>
      <iframe
        src={ reportData?.reports?.Reporting?.url
        }
        className={dashboardSideBarVisibiltyState?"iframe-style-open":"iframe-style"}
      ></iframe>
    </div>
  );
};

export default Reports;
