import React from "react";
import { Modal, Button } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import './filtersmodal.css';

const FiltersModal = ({ show, onHide , renderMainModalBody,modalHeading,classes  }) => {
  return (
    <div className={`side-bar-filter-modal ${classes ? classes : ""}`}>
      <Modal show={show} onHide={onHide} className={`more-filter-modal ${classes ? classes : ""}`}>
        <Modal.Header closeButton className="modal-header">
          <h4 className="mx-3 mt-2">{modalHeading}</h4>
          <IoMdClose className="cursor-pointer-global close-icon" onClick={onHide} height={80}/>
        </Modal.Header>
        <hr className="m-0"/>
        <Modal.Body>
          {renderMainModalBody()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FiltersModal;
