import React, { useState, useRef, useEffect } from "react";
import Spinner from "../spinners/spinner";
import { Stack } from "react-bootstrap";
import dropdownIcon from "../../assets/svgs/downArrow.svg";
import "./Multiselect.css";
import Form from "react-bootstrap/Form";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import searchIcon from '../../assets/icons/search-icon.png'
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useGlobalContext } from "../../context/global";

const MultiSelect = ({ title, options, defaultSelected, onApply,isSearch,placeHolder,classes,label,isLoading,optionChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsToShow,setOptionsToShow] = useState(options ?? []) // If options undefined then put empty array []
  const [showOptions, setShowOptions] = useState(false);
  // Show the sorted options on the top.
  const [searchEnabled, setSearchEnabled] = useState(false)
  const sortedOptionsToShow = defaultSelectedSort(selectedOptions,optionsToShow)
  const [tempSelectedOptions, setTempSelectedOptions] = useState([]);

	const { dropdownBtnOptionsVisibilty, setDropdownBtnOptionsVisibilty } = useGlobalContext();

  // Effects 
  useEffect(()=> {
    setOptionsToShow(options ?? [])
  },[options])
  // Adding this effect if the default selected options are changed from outside of this component then here selected options should also change.

  useEffect(() => {
    setSelectedOptions([...defaultSelected])
    setTempSelectedOptions([...defaultSelected])
  },[defaultSelected])


  useEffect(() => {
    if(optionChange && options) {
        setOptionsToShow(options ?? [])
    }
  },[options])

  const closeDropdown = () => {
    setSelectedOptions(defaultSelected)
    setTempSelectedOptions(defaultSelected)
    setShowOptions(false);
    setOptionsToShow(options)
    setSearchEnabled(false)
  };

  //Ref
  const ref = useDetectClickOutside({ onTriggered: closeDropdown});

  useEffect(() => {
		if(!dropdownBtnOptionsVisibilty){
			closeDropdown()
			setDropdownBtnOptionsVisibilty(true);
		}
	}, [dropdownBtnOptionsVisibilty]);

  // Methods
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setTempSelectedOptions((prevSelectedOptions) => 
      prevSelectedOptions.includes(value) 
        ? prevSelectedOptions.filter((option) => option !== value) 
        : [...prevSelectedOptions, value]
    );
  };

  const handleApply = () => {
    setSelectedOptions(tempSelectedOptions);
    onApply(tempSelectedOptions);
    setShowOptions(false);
    setOptionsToShow(options);
    setSearchEnabled(false)
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setTempSelectedOptions(sortedOptionsToShow);
    } else {
      setTempSelectedOptions([]);
    }
  };

  const onCancelBtnhandler = () => {
    setSelectedOptions(defaultSelected);
    setTempSelectedOptions(defaultSelected)
    setShowOptions(false);
    setOptionsToShow(options)
  };

  const onSearch = (e) => {
    if(e.target.value === "") {
      setSearchEnabled(false)
    } else {
      setSearchEnabled(true)
    }
    const value = e.target.value.toLowerCase();
    const filteredOptions = options?.filter((option) => {
     return option?.toLowerCase().includes(value)
    })
    setOptionsToShow([...filteredOptions])  
  }

  function defaultSelectedSort (defaultArray,optionsArray) {
    let newArray = []
    if(!searchEnabled) {
      newArray = defaultArray?.concat(optionsArray)
    } else {
      newArray = optionsArray
    }
    const sortedArray = new Set([...newArray])
    return Array.from(sortedArray)
  }

  return (
    <div ref={ref} key={title}>
      <Stack className="d-flex flex-col w-100 position-relative cursor-pointer-global">
        {title ? (
          <>
            <label className="filter-options d-hide" htmlFor="campaign-type">
              {title}
            </label>
          </>
        ) : (
          ""
        )}
        <div
          className="select-button-container select-div w-100"
          onClick={() => setShowOptions((prevShowOptions) => !prevShowOptions)}
        >
          <div
            id="campaign-type"
            className="selected-opt-show-btn "
            value={title}
          >
            {tempSelectedOptions.length > 0 ? <>Selected</>: <>Select</>}
            {tempSelectedOptions.length > 0 ?
            <div className="count-of-selcted-in-main-button-container mx-1">
              {tempSelectedOptions.length}
            </div>
            : <div className="count-of-selcted-in-main-button-container zero mx-2">0</div>}
          </div>
          {isLoading && <div className="for-loader mt-2"><Spinner size={"sm"} shape={"border"}/></div> }
          <div className="">
            <img className="dropdown-img-select" src={dropdownIcon} alt={"open-dropdown-icon"}/>
          </div>
        </div>
        {showOptions && (
          <div className={`select-option-container multiselect-option-wrapper ${classes && classes} ${label ? 'withLabel' : ''}`} >
           {isSearch &&  
            (<div className={"search-box-infind-box dynamic-width-search-bar w-100 position-static"}>
            <div>
              <img src={searchIcon} alt="searchIcon"></img>
            </div>
            <div className="finnd-input-text">
              <input
                type="text"
                placeholder={
                placeHolder
                }
                id={title}
                onChange={onSearch}
              ></input>
            </div>
          </div>)
        }
            <div className="options-contaoner-cd lsit-table-scrollbar multiselect">
            <div className="select-opt">
              <div className="checkbox-rb-container ">
                <Form className="main-container-cb">
                  <div className="innput-container-cb multiselect">
                    <Form.Check
                      className="form-check"
                      inline
                      name={title}
                      type={"checkbox"}
                      id={title}
                      bsSwitchPrefix="form-switch"
                      onChange={handleSelectAllChange}
                      checked={sortedOptionsToShow.length > 0 && tempSelectedOptions.length === sortedOptionsToShow.length}
                    />
                  </div>
                  <div className={"innput-label-cb"}>
                    <label htmlFor={title}>Select All</label>
                  </div>
                </Form>
              </div>
            </div>
              {sortedOptionsToShow?.map((option) => (
                <div key={option} className="select-opt">
                  <div className="checkbox-rb-container ">
                    <Form className="main-container-cb">
                      <div className="innput-container-cb multiselect">
                        <Form.Check
                          value={option}
                          className="form-check"
                          inline
                          name={title}
                          type={"checkbox"}
                          id={option}
                          bsSwitchPrefix="form-switch"
                          onChange={handleOptionChange}
                          checked={tempSelectedOptions.includes(option)}
                        />
                      </div>
                      <div className={"innput-label-cb"}>
                        <label htmlFor={option}>{option}</label>
                      </div>
                    </Form>
                  </div>
                </div>
              ))}
            </div>
            <div className="custom-drop-down-saerch-box-footer multiselect-footer">
              <SecondaryBtnRb
                secondaryBtnText="Cancel"
                className="primary-inactive-btn custom-drop-down-footer-btn"
                onClick={onCancelBtnhandler}
              ></SecondaryBtnRb>
              <SecondaryBtnRb
                secondaryBtnText="Apply"
                className="primary-active-btn custom-drop-down-footer-btn"
                onClick={handleApply}
              ></SecondaryBtnRb>
            </div>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default MultiSelect;