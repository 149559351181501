import React from "react";
import { useGlobalContext } from "../../context/global";
import useUserDetails from "../../helper/userData/useUserDetails";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "../../assets/svgs/logout.svg";
import FolderIcon from "../../assets/svgs/folderwithgear.svg";
import switchIcon from "../../assets/svgs/switch.svg";
import excelIcon from '../../assets/icons/ExcelFile.png'
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { TOAST_POSITION_TOP_RIGHT, TOAST_DANGER } from "../../constant/HardTypes";
// import setUpIcon from '../../assets/images/setup.png';
import setUpIcon from '../../assets/images/setupicon.png';
import { RiListSettingsLine } from "react-icons/ri";


const HeaderDrawer = ({ setDrawer, drawer }) => {
  const { setShow, setModalFor, noMultiuserClients,setNoMultiuserClients, showAlert,showCMSLink,setShowCMSLink } = useGlobalContext();

  const [userDetails] = useUserDetails();
  const isMultiuser = userDetails?.user?.multiUser === "yes" ? true : false;
  const logout = useSignOut();
  const navigate = useNavigate();
  const closeRef = useRef();
  const { clientId } = useGlobalContext();
  const mainIcon = <RiListSettingsLine color="grey" size={20}/>


  // Derieved Variables From State
  const showClientsForNoMultiuser =
    noMultiuserClients?.length > 1 
  // Effects
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (drawer && closeRef.current && !closeRef.current.contains(e.target)) {
        setDrawer(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [drawer]);

 

  // Methods

  const clientSwitch = () => {
    setShow(true);
    setModalFor("switch-client");
  };

  const handleLogout = () => {
    logout();
    localStorage.clear();
    navigate("/login");
    setNoMultiuserClients([])
  };

  const openAdminView = () => {
    const email = userDetails?.user?.email;
    const token = userDetails?.token;
    if (email && token) {
      const url = `https://app2.adyogi.com/amazon-admin?platformMarker=react&userEmail=${email}&ref=${token}`;
      window.open(url);
    }
  };

  const openCMSView = async () => {
    try {
      const url = await fetchData();
      if(url) {
        window.open(url)
      } else {
        showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          backgroundColor: TOAST_DANGER,
          title: "Error",
          description: "Please set CMS sheet",
        });
      }
    } catch (error) {
      showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        backgroundColor: TOAST_DANGER,
        title: "Error",
        description: "An error occurred while fetching CMS data:", error,
      });
      console.error("An error occurred while fetching data:", error);
    }
  }
  
  const fetchData = async () => {
    try {
      const response = await axios.get('https://pamphlet.back4app.io/classes/Client?where={ "objectId":"' + clientId + '" }', {
        headers: {
          'X-Parse-Application-Id': '0f5uiNLZwPItPmo2g10gQlaCpglrbzkpuRnXUSqp',
          'X-Parse-REST-API-Key': 'lNPIUzmgFLoLbX4F1YqJ9RhRFRd7gfOwOM3OugrG',
          'X-Parse-Session-Token': 'r:97f814adf62536301f368c2156b86c41'
        }
      });
      const url = response.data.results[0].amazon_setup.cms;
      return url
    } catch (error) {
      throw error;
    }
  }

  const navigateToSetup = () => {
   navigate('/setup')
  }

  const menuItemsConfig = [
    { label: "Switch Client", icon: switchIcon, action: clientSwitch, show: (isMultiuser || (showClientsForNoMultiuser !== false && isMultiuser===false))},
    { label: "Admin View", icon: FolderIcon, action: openAdminView,show:isMultiuser },
    { label: "CMS", icon: excelIcon, action: openCMSView, show: showCMSLink },
    { label: "Setup", icon: mainIcon, action: navigateToSetup },
    { label: "Logout", icon: LogoutIcon, action: handleLogout }
  ];


  return (
    // <div className="dropdown-content-box position-absolute" ref={closeRef}>
    //   {["Switch Client", "Admin View","CMS","Setup","Logout"]?.map((ele, index) => {
    //     const classNameForHide = !isMultiuser && index === 1 ? "hide":showClientsForNoMultiuser===false && (ele === "Switch Client") && isMultiuser===false? "hide": showCMSLink===false && (ele === "CMS") ? "hide" :"dropdown-item d-flex gap-3"
    //     return (
    //       <>
    //         <div 
    //           className={
    //             classNameForHide
    //           }
    //           onClick={(e) => {
    //             ele === "Switch Client"
    //               ? clientSwitch()
    //               : ele === "Admin View"
    //               ? openAdminView()
    //               : ele === "CMS" 
    //               ? openCMSView()
    //               : ele === "Setup" 
    //               ? navigateToSetup()
    //               : handleLogout();
    //           }}
    //           key={index}
    //         >
    //           <img
    //             src={
    //               index === 0
    //                 ? switchIcon
    //                 : index === 1
    //                 ? FolderIcon
    //                 : ele === "CMS"
    //                 ? excelIcon
    //                 : ele === "Setup" 
    //                 ? setUpIcon
    //                 : LogoutIcon
    //             }
    //             alt={ele}
    //             height={20}
    //           />
    //           <div>
    //             {showClientsForNoMultiuser && isMultiuser === false && index === 0
    //               ? "Switch Account"
    //               : ele}
    //           </div>
    //         </div>
    //       </>
    //     );
    //   })}
    // </div>
    <div className="dropdown-content-box position-absolute" ref={closeRef}>
  {menuItemsConfig.map((config, index) => {
    const shouldHide = () => {
      if (config.show !== undefined && !config.show) return true;
      return false;
    };

    const classNameForHide = shouldHide() ? "hide" : "dropdown-item d-flex gap-3";

    return (
      <div
        className={classNameForHide}
        onClick={config.action}
        key={index}
      >
        {
          config.label === "Setup" ? mainIcon :   <img
          src={config.icon}
          alt={config.label}
          height={20}
        />
        }
      
        <div>
          {config.label === "Switch Client" && showClientsForNoMultiuser && !isMultiuser
            ? "Switch Account"
            : config.label}
        </div>
      </div>
    );
  })}
</div>
  );
};

export default HeaderDrawer;
