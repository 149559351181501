import { DEFAULT_OPERATOR_CONFIGS } from "./rulesConfig";

export default function convertAPIdataToStateConfigs(inputArray) {
    const outputObject = {
      increaseCase: {
        rules: [],
        maxRules: 2,
        caseHeading: "Increase Case",
      },
      decreaseCase: {
        rules: [],
        maxRules: 2,
        caseHeading: "Decrease Case",
      },
      pauseCase: {
        rules: [],
        maxRules: 1,
        caseHeading: "Pause Case",
      },
    };
  
    inputArray.forEach((rule) => {
      const ruleData = {
        isDisabled: false,
        filters: filterMapper(rule.entity_metric_filter.filters),
        rule_duration: convertTimeString(rule?.entity_metric_filter?.time_period), // You may need to calculate the duration based on the time_period in the input
      };
  
      switch (rule.rule_action_config.entity_action_config.action_type) {
        case "INCREASE_BID":
          ruleData.increase_bid = rule.rule_action_config.entity_action_config.percentage_change;
          outputObject.increaseCase.rules.push(ruleData);
          break;
        case "DECREASE_BID":
        ruleData.decrease_bid = rule.rule_action_config.entity_action_config.percentage_change
          outputObject.decreaseCase.rules.push(ruleData);
          break;
        case "PAUSE":
          outputObject.pauseCase.rules.push(ruleData);
          break;
        default:
          // Handle other action types if needed
          break;
      }
    });
  
    return outputObject;
  }

function filterMapper (filters) {
    const metricData = [
        "Spends",
        "Orders",
        "Clicks",
        "ROAS",
        "CVR",
        "CPC",
        "Revenue",
      ]
  return filters?.map((filter) => {
    const fieldName = metricData?.find((metric) => metric?.toLowerCase().includes(filter.field_name))
    return {
        ...filter,
        field_name:fieldName,
        operator:DEFAULT_OPERATOR_CONFIGS[filter.operator]
    }
  })
}

function convertTimeString(inputString) {
    const match = inputString.match(/LAST_(\d+)_DAY/);
  
    if (match && match[1]) {
      const numberOfDays = parseInt(match[1]);
      return `${numberOfDays} Days`;
    } else {
      // Handle invalid input or unknown format
      return inputString;
    }
  }
  