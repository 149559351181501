import React from "react";
import TickBar from "./TickBar";
import { useEffect } from "react";

const TickbarList = ({ options, selectedOptions, getUpdatedData }) => {

  const handleTicks = (e) => {
    const value = e;
    const isPresent = selectedOptions?.includes(value);
    const updatedSelectedOptions = isPresent
      ? value === "All"
        ? []
        : selectedOptions?.filter((option) => (option !== value) )?.filter((option) => option !== "All")
      : (value === "All" || options.length-2 === selectedOptions?.length)
      ? [...options]
      : [...selectedOptions, value];
    getUpdatedData(updatedSelectedOptions);
  };

  useEffect(() => {
    if(selectedOptions?.length === options.length - 1) {
      getUpdatedData(["All",...selectedOptions])
    }
  },[])

  return (
    <div className="d-flex gap-3">
      {[...options]?.map((option) => {
        const isSelected = selectedOptions?.includes(option);
        const allSelectedConditions = isSelected
        return (
          <TickBar
            key={option}
            isSelected={allSelectedConditions}
            handleClick={handleTicks}
            text={option}
          />
        );
      })}
    </div>
  );
};

export default TickbarList;
