import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import RadioButtonRb from "../../../Buttons/radioButtonRb/radioButtonRb";
import "./addNegativeTargeting.css";
import SecondaryBtnRb from "../../../Buttons/secondaryBtnRb/secondaryBtnRb";
import MultiSelect from "../../../DropDown/Multiselect";
import { useEffect } from "react";
import { masterApi } from "../../../../apis/masterApi";

const AddToNegativeTargetingModal = ({ show, onNoClick, onYesClick,defaultSelectedCampaigns , confirmationId }) => {
  const [activeTab, setActiveTab] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([...defaultSelectedCampaigns]);
  const dataForDropDown = options?.map((data) => data?.name)

  // Effects


  useEffect(() => {
    getCampaignList();
  },[])

  // API Methods
  async function getCampaignList() {
    try {
      const dataForCampaignList = {
        source: "amazon",
        limit: 5000,
        fields:"id,name"
      };

      const campaignData = await masterApi(
        dataForCampaignList,
        "campaignList",
        false
      );
      setOptions(campaignData?.data?.data ?? []);
    } catch (error) {
      console.log(error);
    }
  }

  // Methods
  const cancelHandler = () => {
    onNoClick();
  };

  const onupdateClickHandler = () => {
    const details = {
      level:activeTab,
      campaignIds:options.filter((data) => {
        let result = false
        selectedCategory.forEach((name) => {
          if(data.name === name) {
            result = true
          }
        })
        return result
      }).map((campaign) => campaign?.id) 
    }
    onYesClick(details)
  };

  const changeTab = (recievedTab) => {
    setActiveTab(recievedTab);
  };

  const handleApply = (e) => {
    setSelectedCategory(e);
  };

  const modalBody = (
    <>
      <div className="bid-config-modal-container">
        <div className="edit-bid-heading">Add To Negative Targeting</div>
        <div className="p-3">
          <div className="redioButton-box d-flex gap-1">
            <div
              className="redioButton col-6"
              onClick={(e) => {
                campaignRadioChange(e);
                changeTab("account");
              }}
            >
              <RadioButtonRb
                name="select-Campaign-Details"
                label={<Leavle heading="Account" para="" />}
              ></RadioButtonRb>
            </div>
            <div
              className="redioButton h-100 col-6"
              onClick={(e) => {
                campaignRadioChange(e);
                changeTab("campaign");
              }}
            >
              <RadioButtonRb
                name="select-Campaign-Details"
                label={<Leavle heading="Campaign" para="" />}
              ></RadioButtonRb>
            </div>
          </div>
        </div>
        {activeTab === "campaign" && (
          <>
            <div className="px-2">
              <MultiSelect
                title={"Choose Campaign"}
                defaultSelected={selectedCategory}
                options={dataForDropDown}
                onApply={(e) => handleApply(e, "Campaign")}
                isSearch={true}
                placeHolder={"Search For Campaigns"}
                classes={"position-static mst-negative-modal-drop-down-height"}
              />
            </div>
          </>
        )}
      
      </div>
    </>
  );

  const campaignRadioChange = (e) => {
    const mainBtn = e.target.closest(".redioButton-box");
    const allBtn = mainBtn.querySelectorAll(".redioButton");
    allBtn.forEach((item) => {
      item.classList.remove("active");
    });
    const add = e.target.closest(".redioButton");
    add.classList.add("active");
    add.children[0].children[0].children[0].checked = true;
  };

  return (
    <div className="filetr-modal-for-targeting-main-container">
      <Modal
        show={show}
        size={"lg"}
        scrollable="true"
        className="add-negative-targeting-modal"
        backdropClassName="edit-bid-for-targetinng-backdrop"
        contentClassName="edit-bid-for-targetinng-conntennt"
        dialogClassName="edit-bid-for-targetinng-dialogue"
        
      >
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer className="add-negative-targeting-modal-footer">  <div className="targeting-btn-container-for-bid mt-2 justify-content-center">
          <SecondaryBtnRb
            secondaryBtnText="Cancel"
            onClick={cancelHandler}
            className="primary-inactive-btn px-5 mx-3"
          ></SecondaryBtnRb>
          <SecondaryBtnRb
            secondaryBtnText="Save"
            id={confirmationId ?? "save"}
            disabled={activeTab === "" || (activeTab === "campaign" && selectedCategory.length === 0)}
            onClick={onupdateClickHandler}
            className="primary-active-btn px-5"
          ></SecondaryBtnRb>
        </div></Modal.Footer>
      </Modal>
    </div>
  );
};

const Leavle = ({ heading, para }) => {
  return (
    <>
      <span className="leavle-hover leavle-heading">{heading}</span>
      <br />
      {para && <span className="leavle-hover leavle-para">{para}</span>}
    </>
  );
};

export default AddToNegativeTargetingModal;
