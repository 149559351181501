import React from 'react'
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryBtnRB from '../../../Buttons/primaryBtnRB.js/primaryBtnRB';
import CreateAdGroup from '../../../modal/CampaignCreationPages/CreateAdGroup'
import AdDetails from '../../CampaignCreationPages/AdDetails';
import SDBrandAudienceAdGroup from '../../campaignAdGroupTables/SDBrandAudienceAdGroup';
import SDBrandProductAdGroupTable from '../../campaignAdGroupTables/SDBrandProductAdGroupTable';
import SDProductProspectAdGroupTable from '../../campaignAdGroupTables/SDProductProspectAdGroupTable'
import SpBrandProductAdGroupTable from '../../campaignAdGroupTables/SpBrandProductAdGroupTable';
import SpBrandKeywordAdGroupTable from '../../campaignAdGroupTables/SpBrandKeywordAdGroupTable';
import SpKeywordAdGroupTable from '../../campaignAdGroupTables/SpKeywordAdGroupTable'
import './adGroupModal.css';
import SpProductProspectAdGroupTable from '../../campaignAdGroupTables/SpProductProspectAdGroupTable';
import { useGlobalContext } from '../../../../context/global';
import { initialAdGroupData } from '../../../../constant/states/campaignCreation';
// import closeIcon from "../../../../assets/icons/close_dashlayout_icon.png";
import closeIcon from "../../../../assets/svgs/cross_icon.svg";
import Loader from '../../../loader';
import { initialCreativesCreationState } from '../../../../constant/campaignCreationDefaultConfigs';
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { masterApi } from '../../../../apis/masterApi';
import redirect from '../../../../constant/Redirect';
import { initialCreateCampaignErrors } from '../../../../constant/states/campaignCreation';
import CustomToast from '../../../ToastAlert/CustomToast/CustomToast';




const AdGroupModal = (props) => {

    // Common Variables and Destructuring
    const {targeting_objective,type,id} = props.data
    const navigate = useNavigate();
    const {
      setKeywords,
      setBrandKeywordsToBeAdded,
      setKeywordsToBeShow,
      setSelectedProducts,
      setSpAsins,
      setSdAsins,
      setManuallyAddedAsins,
      isLoaderVisible,
      setSpSelectedSuggestedCategories,
      setAdsetAsins,
      setSpWholeCategories,
      setSdSelectedSuggestedCategories,
      setSdWholeCategories,
      setPage,
      setSpBrandSelectedSuggestedCategories,
      setSpBrandAsins,
      setSdBrandSelectedSuggestedCategories,
      setSdBrandAsins,
      setsdBrandWholeCategories,
      setspBrandWholeCategories,
      setBrandMatchTypes,
      setBrandKeywordsToBeShow,
      setmanualBrandMatchTypes,
      setManualMatchTypes,
      setKeywordsTobeAdded,
      setAdGroupData,
      dispatchErrors,
      setAdSetData,
      setIsLoaderVisible,
      adGroupData,
      adSetData,
      setButtonStatus,
      productSetData
    } = useGlobalContext()
    const {RULE_1,RULE_2,RULE_3} = productSetData


    // Table Bodies

    // Methods
    const handleClose = () => {
        props.setAdGroupModalVisible(false)
        setKeywords([]);
        setBrandKeywordsToBeAdded([]);
        setKeywordsTobeAdded([]);
        setKeywordsToBeShow([]);
        setSelectedProducts([]);
        setSpAsins([]);
        setSdAsins([]);
        setManuallyAddedAsins([]);
        setAdsetAsins([]);
        setSpSelectedSuggestedCategories([])
        setSpWholeCategories([]);
        setSdSelectedSuggestedCategories([]);
        setSdWholeCategories([]);
        setPage("");
        setSpBrandSelectedSuggestedCategories([]);
        setSpBrandAsins([]);
        setSdBrandSelectedSuggestedCategories([]);
        setSdBrandAsins([]);
        setsdBrandWholeCategories([]);
        setspBrandWholeCategories([]);
        setBrandMatchTypes([]);
        setBrandKeywordsToBeShow([]);
        setmanualBrandMatchTypes([]);
        setManualMatchTypes([]);
        const parsedAdgroupData = JSON.parse(JSON.stringify(initialAdGroupData))
        setAdGroupData(parsedAdgroupData)
        dispatchErrors({action:"resetEveryError"})
    }

    useEffect(() => {
      setKeywords([]);
      setBrandKeywordsToBeAdded([]);
      setKeywordsTobeAdded([]);
      setKeywordsToBeShow([]);
      setSelectedProducts([]);
      setSpAsins([]);
      setSdAsins([]);
      setManuallyAddedAsins([]);
      setAdsetAsins([]);
      setSpSelectedSuggestedCategories([])
      setSpWholeCategories([]);
      setSdSelectedSuggestedCategories([]);
      setSdWholeCategories([]);
      setPage("");
      setSpBrandSelectedSuggestedCategories([]);
      setSpBrandAsins([]);
      setSdBrandSelectedSuggestedCategories([]);
      setSdBrandAsins([]);
      setsdBrandWholeCategories([]);
      setspBrandWholeCategories([]);
      setBrandMatchTypes([]);
      setBrandKeywordsToBeShow([]);
      setmanualBrandMatchTypes([]);
      setManualMatchTypes([]);
      const parsedAdgroupData = JSON.parse(JSON.stringify(initialAdGroupData))
      const parsedAdsetData = JSON.parse(JSON.stringify(initialCreativesCreationState))
      setAdGroupData(parsedAdgroupData)
      setAdSetData(parsedAdsetData)
      dispatchErrors({action:"resetEveryError"})
    },[])

    function addGroupData(id) {
      setAdGroupData({ ...adGroupData, campaign_id: id });
      return adGroupData;
    }
  
    function adSetDataSet(id) {
      setAdSetData({ ...adSetData, adset_id: id });
      return adSetData;
    }

    function validateData(adGroupData, adSetData) {
      const pattern = /(SD_|SP_).+/;
      let checker = pattern.test(adGroupData.name) && adSetData.type !== "";
      const errorsData = {...initialCreateCampaignErrors}
      let requiredFields = [];
      
      if (!pattern.test(adGroupData.name)) {
        requiredFields.push("Adgroup Name");
        errorsData.adgroup_name = true
      }
      
      if (adSetData.type === "") {
        requiredFields.push("Ad Details");
        errorsData.ad_details = true
      }
  
  
     if(!Object.keys(adGroupData)?.some((data) => data?.includes("targeting")) && type!=="SP-Auto" && !type?.includes("Unknown")) {
      requiredFields.push("Add Targeting")
      errorsData.targeting = true
      checker = false
     }
  
      if (adGroupData?.amz_keyword_targeting && adGroupData?.amz_keyword_targeting?.length === 0) {
        requiredFields.push("Add Keyword Targeting");
        checker = false;
        errorsData.targeting = true
      }
      
      if (adGroupData?.amz_product_targeting && adGroupData?.amz_product_targeting?.length === 0) {
        requiredFields.push("Add Product Targeting");
        checker = false;
        errorsData.targeting = true
      }
      
      if (adGroupData?.amz_audience_targeting && adGroupData?.amz_audience_targeting?.length === 0) {
        requiredFields.push("Add Audience Targeting");
        checker = false;
        errorsData.targeting = true
      }
      
  
        
  
      
      if (adSetData?.products && adSetData.products.length === 0) {
        if(adSetData?.validation_type === 'amazon_custom_creative_asin'){
          requiredFields.push("Enter at least one ASIN");
          checker = false;
        }else if(adSetData?.validation_type === 'amazon_custom_creative'){
          requiredFields.push("Select at least one Product");
          checker = false;
        }else{
          requiredFields.push("Add Products");
          checker = false;
        }
        
      }
      // Check If Smart Ads Products added is greater than 3 or not
      const arrayForSmartAds = ["TOP_SELLING","INSTOCK","DISCOUNTED"]
      if(arrayForSmartAds.includes(adSetData?.product_set_type)) {
        const currentSelectedType = adSetData?.product_set_type
        const currentSelectedProductCount = currentSelectedType === "TOP_SELLING" ? RULE_1 : currentSelectedType === "INSTOCK" ? RULE_2 : RULE_3
        if(currentSelectedProductCount<3) {
          requiredFields.push("Selected Products Count should be 3 or more than 3")
          toast.error('Products Count should be 3 or more than 3')
          checker = false
        }
      }else{
        if(adSetData?.validation_type === 'amazon_smart_creative'){
          requiredFields.push("Select at least one smart logic")
          checker = false
        }
      }
  
      if(adSetData?.product_set_type ===null) {
        requiredFields.push("Please Choose Smart Ads")
        checker=false
      }
  
      // Checking For the Manual Bids & Automatic Campaigns Data
  
     if(adGroupData?.amz_default_bid === "") {
        requiredFields.push("Default Bid");
        checker = false
        errorsData.amz_default_bid = true
     }
    
     if(checker) {
      dispatchErrors({action:"resetEveryError"})
     } else {
      dispatchErrors({action:"submitErrors",value:{...errorsData}})
     }
      return {
        checker: checker,
        requiredFields: requiredFields
      };
    }

    const newAdGroupAPICall = async () => {
      try {
        setIsLoaderVisible(true);
        setButtonStatus({ status: true, text: "Processing..." });
        const data1 = addGroupData(id);
        data1["campaign_id"] = id;
        if("amz_keyword_targeting" in data1) {
          data1['amz_keyword_targeting'] =  data1['amz_keyword_targeting'].map(function(keyword) {
            keyword["bid"] = keyword["userBid"]
            delete(keyword.lowest)
            delete(keyword.heighest)
            delete(keyword.userBid)
              return keyword;
          });
        }
        if(props.adGroupType!=="SP-Auto")
        if("amz_manual_bid" in data1) {
          delete data1["amz_manual_bid"]
      } 
        const result1 = await masterApi(
          data1,
          "Adgroup",
          false,
          "post",
          id
        );
        if (redirect(result1)) return navigate("/login");
        const data2 = adSetDataSet(result1?.data?.id);
        data2["adset_id"] = result1?.data?.id;
        const result2 = await masterApi(
          data2,
          "Adset",
          false,
          "post",
          id
        );
        if (redirect(result2)) return navigate("/login");
        if (result1?.statusCode !== 200 || result2?.statusCode !== 200) {
          toast.error('Error While Creating New AdGroup')
          setButtonStatus({ status: false, text: "" });
          setIsLoaderVisible(false);
          // alert("Error While Creating New AdGroup");
        } else {
          toast.success('Adgroup Has been created Successfully')
          setButtonStatus({ status: false, text: "" });
          setIsLoaderVisible(false);
          handleClose()
          navigate("/campaign-list");
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };

    function handleAdgroupCreation () {
      const {checker,requiredFields} = validateData(adGroupData,adSetData)
      if(checker) {
        newAdGroupAPICall()
      } else {
        const fields = ["Please Fill Mandatory Fields:-",...requiredFields]
        toast.error(<CustomToast toastDetails={fields}/>)
      }
    }


  return (
    <>
        <div className="modal-wrapper-adgroup-scroll">
            <Modal className="modal-wrapper-adgroup" show={props.adGroupModalVisible} onHide={handleClose}>
                <Modal.Body>
                  <Loader isVisible={isLoaderVisible}/>
                <div className="cursor-pointer-global" style={{paddingRight:"30px",paddingTop:"20px",position:"relative"}} >
                            <img src={closeIcon} onClick={handleClose} style={{width:"15px",position:"absolute",right:"5px"}} ></img>
                            </div>
                    <div className="sponsored-product-keyword-targeting create-new-adset-modal">

                            <CreateAdGroup data={props.data} update={"update"}/>
                            {
                              type === "SP-Auto" &&
                              targeting_objective.toLowerCase() === "prospect"
                              ? ""
                              : type === "SP-Keyword" &&
                                targeting_objective.toLowerCase() === "prospect"
                              ? <SpKeywordAdGroupTable/>
                              : type === "SP-Product" &&
                                targeting_objective.toLowerCase() === "prospect"
                              ? <SpProductProspectAdGroupTable/>
                              : type === "SD-Product" &&
                                targeting_objective.toLowerCase() === "prospect"
                              ? <SDProductProspectAdGroupTable/>
                              : type === "SP-Keyword" &&
                                targeting_objective.toLowerCase() === "retargeting"
                              ? <SpBrandKeywordAdGroupTable/>
                              : type === "SP-Product" &&
                                targeting_objective.toLowerCase() === "retargeting"
                              ? <SpBrandProductAdGroupTable/>
                              : type === "SD-Product" &&
                                targeting_objective.toLowerCase() === "retargeting"
                              ? <SDBrandProductAdGroupTable/>
                              : type === "SD-Audience" &&
                                targeting_objective.toLowerCase() === "retargeting"
                              ? <SDBrandAudienceAdGroup/>
                              : ""
                            }
                    </div>
                    <div className='sponsored-product-keyword-targeting ad-group-modal'>
                            <AdDetails/>
                    </div>
                    <div
            className={ "d-flex justify-content-end gap-10 keyword-targeting"
            }
            style={{ marginTop: "35px" }}
          >
            <button
              type="button"
              className="primary-outline-btn btn btn-outline-primary px-5"
              onClick={handleClose}
            >
              Go Back
            </button>
            <PrimaryBtnRB
              insideBtnText="Create AdGroup"
              id={"create-adgroup"}   
              className="width-100"
              setAdGroupModalVisible={props.setAdGroupModalVisible}
              pageClickOptionsHandler={handleAdgroupCreation}
            ></PrimaryBtnRB>
          </div>
                </Modal.Body>
            </Modal>
        </div>
    </>
  )
}

export default AdGroupModal;