import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import "./productReport.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import RadioInputGroup from "../../components/radioInputGroup/radioInputGroup";
import ReportFilters from "../../components/Reports/Filters";
import SectionTitle from "../../components/sectionTitle";
import previousDays from "../../helper/Reports/common";
import Loader from "../../components/loader";
import useInitialLoad from "../../hooks/useInitialLoad";
import Breadcrumb from "../../components/Breadcrumb/ReportsBreadCrumb";
import Canvas from "../../helper/Screenshot/canvas";
import downloadIcon from "../../assets/svgs/download.svg";
import { createQueryURL, formatPieChartData , objectoFiltering,getSearchParamsObject,formatDate } from "../../components/Reports/Components/utils";
import {useSearchParams } from "react-router-dom";
import CustomDropDown from '../../components/DropDown/CustomDropDown';
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";


const ProductReport = (props) => {
  const {
    calendarDate,
    setCalendarDate,
    dateDifference,
    setDateDifference,
    applyCalendar,
    multiCalendarDate,
  } = useGlobalContext();
  


  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const [tableView, setTableView] = useState("table");
  const allParams = new URLSearchParams(window.location.search);
  const paramsObject = getSearchParamsObject(allParams);
  const viewTYPE =allParams.get('viewtype') ?? 'Performance View'
  const previousFiltering = objectoFiltering(paramsObject,"VariantAnalysisTable")
  const [currentViewType, setCurrentViewType] = useState(viewTYPE);
  const isComparisonView = currentViewType === 'Comparison View';

  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [token, refresh] = useToken("");
  const { cubeToken, setCubeToken } = useGlobalContext();
  const [searchParams,setSearchParams] = useSearchParams({});
  let startDate = formatDate(multiCalendarDate.selection1.startDate);
  let endDate = formatDate(multiCalendarDate.selection1.endDate);
  let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
  let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
  const cubeQueryTimeDimensionsFormatted = !isComparisonView ? [{dimension:"VariantAnalysisTable.obDate",dateRange: [
    formatDate(calendarDate[0].startDate),
    formatDate(calendarDate[0].endDate),
  ]}] : [{dimension:"VariantAnalysisTable.obDate",compareDateRange:[
    [startDate, endDate],
    [startDatePrevious, endDatePrevious],
  ]}]

  const { clientAccountId } = useGlobalContext();

  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });

  const [showFilters, setShowFilters] = useState(true);
  const [campaignType, setCampaignType] = useState([]);
  const [campaignFormat, setCampaignFormat] = useState([]);
  const [targetingType, setTargetingType] = useState([]);
  const [productSet, setProductSet] = useState([]);
  const [adStatus, setAdStatus] = useState([]);
  const [portfolioName, setPortfolioName] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [campaignName, setCampaignName] = useState([]);
  const [childrenOne, setChildrenOne] = useState({});
  const [childDataIndex, setChildDataIndex] = useState(0);
  const [initialLoad, setInitialLoad] = useInitialLoad();

  const enabledfilters = [
    "Campaign Type",
    "Campaign Format",
    "Targeting Type",
    "Product Set",
    "Ad Status",
    "Portfolio Name",
    "Campaign Names",
    "Category Names",
  ];
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const groupByOptions = ["Product","Detailed category","Broad category"];
  const initialGroupByFilter = new URLSearchParams(window.location.search)?.get('reportType') ?? 'Product';
  const [groupByFilter,setGroupByFilter] = useState(initialGroupByFilter);
  const initialviewType = groupByFilter?.includes("Product") ? "Child Asin" : null 
  const [viewType, setViewType] = useState(initialviewType);

  
  const initialCubeQueryDimensions = groupByFilter?.includes("Broad") ? [
    "VariantAnalysisTable.broadCategory",
  ] : groupByFilter?.includes("Detailed") ? [
    "VariantAnalysisTable.category",
  ] : [
    "VariantAnalysisTable.asin",
    "VariantAnalysisTable.productNameWithImage",
  ]
  const isSubstringPresent = (string,subStr) => {
    return string?.includes(subStr)
   }
 
   const initialChildDimensions = isSubstringPresent(groupByFilter,"Broad") ? [
     "VariantAnalysisTable.broadCategory",
     "VariantAnalysisTable.category"
   ] : isSubstringPresent(groupByFilter,"Product") ? ["VariantAnalysisTable.asin",
   "VariantAnalysisTable.productNameWithImage"] : [
     "VariantAnalysisTable.category",
   ]

   
  const initialCubeQueryFilters = (isSubstringPresent(groupByFilter,"Broad") || isSubstringPresent(groupByFilter,"Detailed")) ? [{
    member: "VariantAnalysisTable.profileId",
    operator: "equals",
    values: [clientAccountId],
  },...previousFiltering] : [
    {
      and: [
        {
          member: "VariantAnalysisTable.profileId",
          operator: "equals",
          values: [clientAccountId],
        },
        ...previousFiltering
      ],
    },
  ]

  const initialChartFilters = isSubstringPresent(groupByFilter,"Broad") ? {
    spend: "cost",
    dimension:"broadCategory",
    revenue:"sales14d"
  } : isSubstringPresent(groupByFilter,"Detailed") ? {
    spend: "cost",
    dimension:"category",
    revenue:"sales14d"
  } : {
    spend: "cost",
    dimension:"asin",
    revenue:"sales14d"
  }
 
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "VariantAnalysisTable.cost",
    "VariantAnalysisTable.costDRR",
    "VariantAnalysisTable.impressions",
    "VariantAnalysisTable.impressionsDRR",
    "VariantAnalysisTable.clicks",
    "VariantAnalysisTable.clicksDRR",
    "VariantAnalysisTable.ctr",
    "VariantAnalysisTable.ctrDRR",
    "VariantAnalysisTable.cpc",
    "VariantAnalysisTable.cpcDRR",
    "VariantAnalysisTable.cpm",
    "VariantAnalysisTable.cpmDRR",
    "VariantAnalysisTable.conversions14d",
    "VariantAnalysisTable.conversions14dDRR",
    "VariantAnalysisTable.conversion_rate",
    "VariantAnalysisTable.conversion_rateDRR",
    "VariantAnalysisTable.sales14d",
    "VariantAnalysisTable.sales14dDRR",
    "VariantAnalysisTable.average_order_value",
    "VariantAnalysisTable.average_order_valueDRR",
    "VariantAnalysisTable.acos",
    "VariantAnalysisTable.acosDRR",
    "VariantAnalysisTable.roas",
    "VariantAnalysisTable.roasDRR",
  ]);

  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["VariantAnalysisTable.cost", "desc"],
    ["VariantAnalysisTable.parentSku", "desc"],
  ]);

  const [cubeChildRowQueryOrder, setCubeChildRowQueryOrder] = useState([
    ["VariantAnalysisTable.cost", "desc"],
  ]);

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState(initialCubeQueryDimensions);

  const [cubeChildRowsQueryDimensions, setCubeChildRowsQueryDimensions] =
    useState(initialChildDimensions);

  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState(cubeQueryTimeDimensionsFormatted);
  const [cubeQueryFilters, setCubeQueryFilters] = useState(initialCubeQueryFilters);
  const [cubeChildRowsQueryFilters, setCubeChildRowsQueryFilters] = useState(initialCubeQueryFilters);

  const [resultSet, setResultSet] = useState({});
  const [chartFilters, setChartFilters] = useState(initialChartFilters);
  var spendResultSet = formatPieChartData(
    resultSet,
    chartFilters.spend,
    "VariantAnalysisTable",
    chartFilters.dimension
  );
  var revenueResultSet = formatPieChartData(
    resultSet,
    chartFilters.revenue,
    "VariantAnalysisTable",
    chartFilters.dimension
  );

  const [drrView, setDrrView] = useState("");

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("start") && queryParams.get("end")) {
      setDate(queryParams.get("start"), queryParams.get("end"));
    }
  }, []);

  const setDate = (currentDate, previousDate) => {
    const dates = {
      startDate: new Date(currentDate),
      endDate: new Date(previousDate),
      key: "selection",
    };
    setDateDifference("Custom");
    setCalendarDate([dates]);
    setCubeQueryTimeDimensions((prev) => {
      let data = [...prev];
      data[0]["dateRange"] = [
        formatDate(dates.startDate),
        formatDate(dates.endDate),
      ];
      return data;
    });
  };

  useEffect(() => {
    if (currentViewType !== "Comparison View") {
      const startDate = formatDate(calendarDate[0].startDate);
      const endDate = formatDate(calendarDate[0].endDate);
      if (
        startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] ||
        endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]
      ) {
        setIsLoaderVisible(true);
        setCubeQueryTimeDimensions((prev) => {
          let data = [...prev];
          data[0]["dateRange"] = [startDate, endDate];
          return data;
        });
      }
    }
  }, [applyCalendar]);

  useEffect(() => {
    if (currentViewType === "Comparison View") {
      let startDate = formatDate(multiCalendarDate.selection1.startDate);
      let endDate = formatDate(multiCalendarDate.selection1.endDate);
      let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
      let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
      let apiDateRange = cubeQueryTimeDimensions[0]["compareDateRange"];
      if (
        startDate !== apiDateRange[0][0] ||
        endDate !== apiDateRange[0][1] ||
        startDatePrevious !== apiDateRange[1][0] ||
        endDatePrevious !== apiDateRange[1][1]
      ) {
        setIsLoaderVisible(true);
        setCubeQueryTimeDimensions((prev) => {
          let data = [...prev];
          data[0]["compareDateRange"] = [
            [startDate, endDate],
            [startDatePrevious, endDatePrevious],
          ];
          return data;
        });
      }
    }
  }, [applyCalendar]);

  useEffect(() => {
    //table data
    if (cubeToken) {
      cubejsApi
        .load(
          {
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: cubeQueryDimensions,
            filters: cubeQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit: 10000,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
          setResultSet(resultSet);
          setIsLoaderVisible(false);
          setInitialLoad(false);
          if (currentViewType === "Comparison View") {
            setTableView("ComparisonTable");
          } else {
            setTableView("table");
          }
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    }
  }, [
    cubeQueryMeasure,
    cubeQueryDimensions,
    cubeQueryFilters,
    cubeQueryTimeDimensions,
    cubeToken,
  ]);

  function dataQueryParser (mainData,e,type,tableName) {
   let data = [...mainData];
   if(isSubstringPresent(groupByFilter,"Product")) {
    data[0]["and"].forEach((x, i) => {
      if (x.member === `${tableName}.` + type) {
        data[0]["and"].splice(i, 1);
      }
    });
    if (e.length > 0) {
      data[0]["and"].push({
        member: `${tableName}.` + type,
        operator: "equals",
        values: e,
      });
    }
   } else {
    data.forEach((x, i) => {
      if (x.member === `${tableName}.` + type) {
        data.splice(i, 1);
      }
    });
    if (e.length > 0) {
      data.push({
        member: `${tableName}.` + type,
        operator: "equals",
        values: e,
      });
    }
   }
  
  return data
  }

  const filterCampaigns = (e, type) => {
    setChildrenOne({});
    setIsLoaderVisible(true);
    const parsedFilters = dataQueryParser(JSON.parse(JSON.stringify(cubeQueryFilters)),e,type,"VariantAnalysisTable")
    const filtersForURL = groupByFilter?.includes("Product") ? parsedFilters[0]["and"] : parsedFilters
    const parsedParams = createQueryURL(filtersForURL ?? [])
    parsedParams["viewtype"] = currentViewType
    parsedParams["reportType"] = groupByFilter
    const previousParams = new URLSearchParams(window.location.search)
    if(previousParams.get('metricViewType')) {
      parsedParams['metricViewType'] = previousParams.get('metricViewType')
    }
    setSearchParams(parsedParams)

    setCubeQueryFilters((prev) => {
      let data = [...prev]
      if(groupByFilter === "Product") {
        data[0]["and"].forEach((x, i) => {
          if (x.member === "VariantAnalysisTable." + type) {
            data[0]["and"].splice(i, 1);
          }
        });
        if (e.length > 0) {
          data[0]["and"].push({
            member: "VariantAnalysisTable." + type,
            operator: "equals",
            values: e,
          });
        }
        const filteredData = data[0]["and"]?.filter((filterName) => filterName?.member!=="VariantAnalysisTable.parentSku")
         data[0]["and"] = filteredData
         return data
      } else if (groupByFilter?.includes("Broad") || groupByFilter?.includes("Detailed")) {
        data.forEach((x, i) => {
          if(x.member === 'VariantAnalysisTable.'+type) {
              data.splice(i, 1);
          }
      });
      if(e.length > 0) {
          data.push({
              "member": 'VariantAnalysisTable.'+type,
              "operator": "equals",
              "values": e
            })
      }
      return data?.filter((filterName) => filterName?.member!=="VariantAnalysisTable.parentSku")
      }
    
    });
    setCubeChildRowsQueryFilters((prev) => {
      let data = [...prev];
      if(groupByFilter === "Product") {
        data[0]["and"].forEach((x, i) => {
          if (x.member === "VariantAnalysisTable." + type) {
            data[0]["and"].splice(i, 1);
          }
        });
        if (e.length > 0) {
          data[0]["and"].push({
            member: "VariantAnalysisTable." + type,
            operator: "equals",
            values: e,
          });
        }
      } else if (groupByFilter?.includes("Broad") || groupByFilter?.includes("Detailed")) {
        data.forEach((x, i) => {
          if(x.member === 'VariantAnalysisTable.'+type) {
              data.splice(i, 1);
          }
      });
      if(e.length > 0) {
          data.push({
              "member": 'VariantAnalysisTable.'+type,
              "operator": "equals",
              "values": e
            })
      }
      }
      return data;
    });
    if (type === "campaignType") {
      setCampaignType(e);
    } else if (type === "campaignFormat") {
      setCampaignFormat(e);
    } else if (type === "targetingType") {
      setTargetingType(e);
    } else if (type === "productSet") {
      setProductSet(e);
    } else if (type === "adStatus") {
      setAdStatus(e);
    } else if (type === "portfolioName") {
      setPortfolioName(e);
    } else if (type === "category") {
      setCategoryName(e);
    } else if (type === "campaignName") {
      setCampaignName(e);
    }
  };

  const expendChange = (data, type, index) => {
    if(groupByFilter === "Product") {
      if (type === "campaign_format_child" || type === "link") {
        let url = "/master-ads?asinId=" + data;
        window.open(url);
      } else {
        let prevData = [...cubeChildRowsQueryFilters];
        if(groupByFilter === "Product") {
          prevData[0]["and"].forEach((x, i) => {
            if (x.member === "VariantAnalysisTable.parentSku") {
              prevData[0]["and"].splice(i, 1);
            }
          });
          prevData[0]["and"].push({
            member: "VariantAnalysisTable.parentSku",
            operator: "equals",
            values: [data],
          });
        } 
        setCubeChildRowsQueryFilters(prevData);
        setChildDataIndex(data);
      }
    } else if (groupByFilter?.includes("Broad")) {
      let prevData = [...cubeChildRowsQueryFilters];
      prevData.forEach((x, i) => {
        if (x.member === "VariantAnalysisTable.broadCategory") {
          prevData.splice(i, 1);
        }
      });
      prevData.push({
        member: "VariantAnalysisTable.broadCategory",
        operator: "equals",
        values: [data],
      });
      setCubeChildRowsQueryFilters(prevData);
      setChildDataIndex(data);
    } else if(groupByFilter?.includes("Detailed")) {
      let url = "/product-analytics";
    const queryParams = [
      ["category", data],
      ["start", formatDate(calendarDate[0].startDate)],
      ["end", formatDate(calendarDate[0].endDate)],
      ["campaignType", campaignType],
      ["campaignFormat", campaignFormat],
      ["targetingType", targetingType],
      ["productSet", productSet],
      ["campaignStatus", adStatus],
      ["portfolioName", portfolioName],
    ];
    const queryString = queryParams
      .filter(([key, value]) => value !== "All")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    url += `?${queryString}`;
    // Open URL in new tab
    window.open(url);
    }
  };

  useEffect(() => {
    let getdata = false;
    let prevData = [...cubeChildRowsQueryFilters];
    if(groupByFilter?.includes("Product")) {
      prevData[0]["and"]?.forEach((x, i) => {
        if (x.member === "VariantAnalysisTable.parentSku") {
          getdata = true;
        }
      });
    } else if (groupByFilter?.includes("Broad")) {
      prevData?.forEach((x, i) => {
        if (x.member === "VariantAnalysisTable.broadCategory") {
          getdata = true;
        }
      });
    }

    if (getdata) {
      cubejsApi
        .load(
          {
            measures: cubeQueryMeasure,
            order: cubeChildRowQueryOrder,
            dimensions: cubeChildRowsQueryDimensions,
            filters: cubeChildRowsQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit: 10000,
          },
          {
            headers: { "Access-Control-Allow-Origin": true },
          }
        )
        .then((childResultSet) => {
          setChildrenOne((prev) => {
            return { ...prev, [childDataIndex]: childResultSet };
          });
          setIsLoaderVisible(false);
          setInitialLoad(false);
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    }
  }, [cubeChildRowsQueryFilters]);

  const calenderIconRefForCampaignlist = useRef(null);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);


  const onDropdownImghandler = (type) => {
    // console.log("@$# onDropdownImghandler");
  };

  useEffect(() => {
    if(viewType!==null) {
      setIsLoaderVisible(true);
      // setResultSet({})
      let prevData = [...cubeQueryFilters];
      prevData[0]["and"].forEach((x, i) => {
        if (x.operator === "contains") {
          prevData[0]["and"].splice(i, 1);
        }
      });
      let is_same_prev =
        cubeQueryFilters.length == prevData.length &&
        cubeQueryFilters.every(function (element, index) {
          return element === prevData[index];
        });
      if (!is_same_prev) {
        setCubeQueryFilters(prevData);
      }
      if (viewType === "Parent Asin") {
        let newCubeDimention = ["VariantAnalysisTable.parentSku"];
        let is_same =
          cubeQueryDimensions.length == newCubeDimention.length &&
          cubeQueryDimensions.every(function (element, index) {
            return element === newCubeDimention[index];
          });
        if (!is_same) {
          setCubeQueryDimensions(newCubeDimention);
        }
      } else {
        let newCubeDimention = [
          "VariantAnalysisTable.asin",
          "VariantAnalysisTable.productNameWithImage",
        ];
        let is_same =
          cubeQueryDimensions.length === newCubeDimention.length &&
          cubeQueryDimensions.every(function (element, index) {
            return element === newCubeDimention[index];
          });
        if (!is_same) {
          setCubeQueryDimensions(newCubeDimention);
        }
      }
      setChartFilters((prev) => {
        prev.dimension = viewType === "Parent Asin" ? "parentSku" : "asin";
        return prev;
      });
    }
  }, [viewType]);

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  useEffect(() => {
    if(groupByFilter) {
      const queryParams = new URLSearchParams(window.location.search)
     queryParams?.set("reportType",groupByFilter)
     queryParams?.set("viewtype",currentViewType)
     const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
     window.history.replaceState(null, null, newUrl);
    }
  },[groupByFilter,currentViewType])

  const handleComparisonView = (type) => {
    setChildrenOne({});
    if (type === "Comparison View") {
      setCubeQueryTimeDimensions([
        {
          dimension: "VariantAnalysisTable.obDate",
          compareDateRange: [
            [
              startDate,
              endDate,
            ],
            [
              startDatePrevious,
              endDatePrevious,
            ],
          ],
        },
      ]);
      setIsLoaderVisible(true);
    } else if (
      type !== "Comparison View" &&
      currentViewType === "Comparison View"
    ) {
      setCubeQueryTimeDimensions([
        {
          dimension: "VariantAnalysisTable.obDate",
          dateRange: [
            formatDate(calendarDate[0].startDate),
            formatDate(calendarDate[0].endDate),
          ],
        },
      ]);
      setIsLoaderVisible(true);
    }
    setCurrentViewType(type);
  };

  

  function dataFilter(data) {
    setChildrenOne({})
    setIsLoaderVisible(true)
    const table = "VariantAnalysisTable.";
    let filters = []
    let tempFilters = [...cubeQueryFilters];
    if(groupByFilter==="Product") {
      filters.push(tempFilters[0])
    } else {
      filters = [...tempFilters]
    }
    
    // Create a new "and" object
    if(groupByFilter==="Product") {
      const newAndObject = {
        and: [],
      };
  
      Object.entries(data).forEach(([key, value]) => {
        if (value.min) {
          newAndObject.and.push({
            member: `${table}${key}`,
            operator: 'gte',
            values: [parseFloat(value.min)],
          });
        }
        if (value.max) {
          newAndObject.and.push({
            member: `${table}${key}`,
            operator: 'lte',
            values: [parseFloat(value.max)],
          });
        }
      });
      filters.push(newAndObject);
    } else {
      Object.entries(data).forEach(([key, value]) => {
        filters.forEach((x, i) => {
          if (x.member === `${table}${key}`) {
            filters.splice(i, 1);
          }
        });
        if (value.min) {
          filters.push({
            member: `${table}${key}`,
            operator: 'gte',
            values: [parseFloat(value.min)],
          });
        }
        if (value.max) {
          filters.push({
            member: `${table}${key}`,
            operator: 'lte',
            values: [parseFloat(value.max)],
          });
        }
      });
    }

    // tempFilters.splice(1, 0, newAndObject); // Insert at index 1
  // Update the state with the modified cubeQueryFilters
  setCubeQueryFilters(filters);
  setCubeChildRowsQueryFilters(filters);
  }

  function handleSearch(data) {
    let prevData = JSON.parse(JSON.stringify(cubeQueryFilters));
    setIsLoaderVisible(true)
   if(groupByFilter === "Product") {
     // Remove existing "or" conditions in the first "and" condition
     prevData[0].and = prevData[0].and.filter(
       (condition) => !("or" in condition)
     );

     // Create a new "or" condition
     let newOrCondition = {
       or: cubeQueryDimensions.map((member) => ({
         member,
         operator: data.select,
         values: [data.input],
       })),
     }

     // Add the new "or" condition to the first "and" condition
     prevData[0].and.push(newOrCondition);
   } else if (groupByFilter !== "Product") {
    prevData.forEach((x, i) => {
      if (x.member === cubeQueryDimensions[0]) {
        prevData.splice(i, 1);
      }
    });
    if(data.input) {
      prevData.push({
          member: cubeQueryDimensions[0],
          operator: data.select,
          values: [data.input],
      });
    }
   }
  // Update the state with the modified cubeQueryFilters
  setCubeQueryFilters(prevData);
  setCubeChildRowsQueryFilters(prevData);
  }

  const handleDownload = () => {
    Canvas({ name: "product-analytics" });
  };

  const handleDrrView = (value, type) => {
    setDrrView(value);
    setChildrenOne({});
    if (value !== "drr") {
      setChartFilters((prev) => {
        prev.spend = "cost";
        prev.revenue = "sales14d";
        return prev;
      });
    } else {
      setChartFilters((prev) => {
        prev.spend = "costDRR";
        prev.revenue = "sales14dDRR";
        return prev;
      });
    }
  };

  function filterOutFilters (type,data) {
    // Filter out existing filters from the current states
   const mainData = type === "Product" ? [...data][0]["and"]?.filter((mainData,index) => {
      return mainData.member !== "VariantAnalysisTable.parentSku"
   }).concat([...data][1]?.hasOwnProperty(["and"]) ? [...data][1]["and"] : [] ) : type?.includes("Broad") ? [...data]?.filter((mainData,mainIndex) => {
      return mainData.member !== "VariantAnalysisTable.broadCategory"
   }) : data
   return mainData
  }
 

 
  const handleGroupByChange = (e) => {
    if(e.target.value!==groupByFilter) {
      // Change Filters According to groupTypes
      if(e.target.value?.includes("Broad")) {
        setIsLoaderVisible(true)
        setCubeQueryDimensions([
          "VariantAnalysisTable.broadCategory",
        ])
        setCubeChildRowsQueryDimensions([
          "VariantAnalysisTable.broadCategory",
          "VariantAnalysisTable.category"
        ])
        const prevDataParentFilters = [...cubeQueryFilters]
        const parentQueryFilters = filterOutFilters(groupByFilter,prevDataParentFilters);
        const childQueryFilters = filterOutFilters(groupByFilter,cubeChildRowsQueryFilters);
        setCubeChildRowsQueryFilters(childQueryFilters)
        setCubeQueryFilters(parentQueryFilters)
        setViewType(null);
        setGroupByFilter(e.target.value)
        setChartFilters((prev) => {
          prev.spend = "cost"
          prev.dimension = "broadCategory";
          prev.revenue = "sales14d"
          return prev
        })
      } else if(e.target.value?.includes("Detailed")) {
        setIsLoaderVisible(true)
        const filteredFilters = filterOutFilters(groupByFilter,cubeQueryFilters);
        setCubeQueryFilters(filteredFilters)
        setCubeQueryDimensions([
          "VariantAnalysisTable.category",
        ])
        setViewType(null);
        setGroupByFilter(e.target.value)
        setChartFilters((prev) => {
          prev.spend = "cost"
          prev.dimension = "category";
          prev.revenue = "sales14d"
          return prev
        })

      } else {
       setViewType("Child Asin")
       const filteredFilters = filterOutFilters(groupByFilter,cubeQueryFilters);
       // Take out normal and range Filters and send it cubeQueryFilters
       const rangeFilters = filteredFilters?.filter((data) => data?.operator?.includes("gte") || data?.operator?.includes("lte"))
       const normalFilters = filteredFilters?.filter((data) => !data?.operator?.includes("gte") && !data?.operator?.includes("lte"))
       setCubeQueryFilters([{and:normalFilters},{and:rangeFilters}])
       setCubeChildRowsQueryFilters([{and:normalFilters},{and:rangeFilters}])
       setCubeQueryDimensions(["VariantAnalysisTable.asin",
       "VariantAnalysisTable.productNameWithImage"])
       setCubeChildRowsQueryDimensions(["VariantAnalysisTable.asin",
       "VariantAnalysisTable.productNameWithImage"])
       setGroupByFilter(e.target.value)
       setChartFilters((prev) => {
        prev.spend = "cost";
        prev.dimension = "asin";
        prev.revenue = "sales14d";
        return prev
      })

      }
    }
  }

  const pageDisplayType = (viewType==="Child Asin" ? "product-child" : viewType === "Parent Asin" ? "product-parent" : groupByFilter?.includes("Broad") ? "expendable" : "category");
  const pageDisplayFormat = (viewType==="Child Asin" ? "campaign-format_child" : viewType === "Parent Asin" ? "campaign_format" : groupByFilter?.includes("Broad") ? "campaign_format" : "");
  const childrenOneHeader = viewType!==null ? "Product Name" : "Category"
  const reportHeading = groupByFilter?.includes("Broad") ? "Broad Category Report" : groupByFilter?.includes("Detailed") ? "Detailed Category Report":"Product Report"

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={initialLoad}></Loader>

      <div
        style={{
          marginLeft: "auto",
        }}
      >
        <button
          className="date-container date-btn-campaignlist d-none"
          onClick={dateInputClickHandler}
        >
          <div className="d-flex justify-conntent-center">
            <span className="date-btn-text">{dateDifference}</span>
          </div>
          <button className="btn-calender-img-holder">
            <span className="">
              <img
                className="dropdown-img-for-select"
                src={selectSideIcon}
                alt={"SelectSideIcon"}
              ></img>

              <img
                className="calender-icon-for-campaignlist"
                src={CalenderIconForCampaignList}
                alt={"CalendarIcon"}
              ></img>
            </span>
          </button>
        </button>
      </div>

      <div
        ref={calenderIconRefForCampaignlist}
        className={
          showCustomDateBox ? "calender-inclasslist-container" : "hide"
        }
      >
        <CalenderComponent
          setShowCustomDateBox={setShowCustomDateBox}
        ></CalenderComponent>
        <span className="calender-close-icon-campaignlist">
          <img
            onClick={() => setShowCustomDateBox(false)}
            src={closeIcon}
            alt={"CloseIcon"}
          ></img>
        </span>
      </div>

      <Breadcrumb />
      <SectionTitle sectionTitleHeading={reportHeading}></SectionTitle>

      <div
        className="d-flex justify-content-between mt-4 mb-4 gap-4 position-relative"
        id="downloadChart"
      >
        <div
          onClick={handleDownload}
          className="downloadChartWrapper pie"
          id="downloadChartWrapper"
        >
          <img
            src={downloadIcon}
            className="cursor-pointer-global DownloadIcon"
            alt="overview-settings-tile"
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Spends</h4>
          <Chart
            type="apexpie"
            resultSet={spendResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Revenue</h4>
          <Chart
            type="apexpie"
            resultSet={revenueResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
      </div>
      <div className="d-flex gap-3">
      <CustomDropDown data={groupByOptions} classes={'product-report-group-by-filter'} filterCampaigns={handleGroupByChange} targettingValue={groupByFilter} src={dropdownIcon} title={"Group Data By"}/>
      <div className={"multiple-filters-wrapper"}>
      <ReportFilters
        handleDropdown={filterCampaigns}
        enabledfilters={enabledfilters}
      />
      </div>
      </div>
 

      <div>
        {
          <FilterLogicModal
            filterFor="campaignlist"
            show={isFilterVisible}
          ></FilterLogicModal>
        }
      </div>
      <div className="d-flex w-100 align-items-end justify-items-between pt-3 productradiobutton">
        <div className="w-100">
   {    groupByFilter === "Product" ?   <RadioInputGroup
            state={viewType}
            setState={setViewType}
            name="viewType"
            radios={["Parent Asin", "Child Asin"]}
            required
          ></RadioInputGroup> : null}
        </div>
      </div>
      <div>
        {         
          <Chart
            type={tableView}
            resultSet={resultSet}
            classes={`${viewType!==null ? "wraptext" : ""} marketing-reports`}
            display={pageDisplayFormat}
            handleChange={expendChange}
            childrenOneData={childrenOne}
            shimmerEffect={isLoaderVisible}
            childrenOneHeader={childrenOneHeader}
            FunnelViews={[
              "Performance View",
              "Funnel View",
              "Comparison View",
              "Custom View",
            ]}
            handleComparisonView={handleComparisonView}
            CurrentViewType={currentViewType}
            page={pageDisplayType}
            dataFilter={dataFilter}
            drrView={handleDrrView}
            drrViewType={drrView}
            handleSearch={handleSearch}
            showFilter={showFilters}
            groupByView={groupByFilter}
          />
      //    : viewType === "Child Asin" ? (
      //     <Chart
      //       type={tableView}
      //       resultSet={resultSet}
      //       shimmerEffect={isLoaderVisible}
      //       classes={"wraptext marketing-reports"}
      //       display={"campaign_format_child"}
      //       handleChange={expendChange}
      //       childrenOneHeader={"Product Name"}
      //       FunnelViews={[
      //         "Performance View",
      //         "Funnel View",
      //         "Comparison View",
      //         "Custom View",
      //       ]}
      //       handleComparisonView={handleComparisonView}
      //       CurrentViewType={currentViewType}
      //       page="product-child"
      //       dataFilter={dataFilter}
      //       drrView={handleDrrView}
      //       drrViewType={drrView}
      //       handleSearch={handleSearch}
      //       showFilter={showFilters}
      //       key={currentViewType}            
      //     />
      //   ):<Chart
      //   type={tableView}
      //   resultSet={resultSet}
      //   classes={"marketing-reports"}
      //   display={groupByFilter?.includes('Broad') ? 'campaign_format' : undefined}
      //   page={groupByFilter?.includes('Broad') ? 'expendable':'category'}
      //   handleChange={expendChange}
      //   childrenOneData={childrenOne}
      //   shimmerEffect={isLoaderVisible}
      //   childrenOneHeader={'Category'}
      //   FunnelViews={["Performance View","Funnel View","Comparison View","Custom View"]} 
      //   handleComparisonView={handleComparisonView} CurrentViewType={currentViewType} 
      //   dataFilter={dataFilter}
      //   drrView={handleDrrView} drrViewType={drrView}
      //   handleSearch={handleSearch}
      //   showFilter={showFilters}
      //   key={groupByFilter}
      // />
      }
      </div>
    </div>
  );
};

export default ProductReport;

