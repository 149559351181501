import { getChildRowsData } from "./getChildRowsData";

/**
 * @Note This method check if the child data is present or not if the it is present then it shows it otherwise calls API to get it.
 * @param {*} currentState
 * @param {*} parentBoxState
 * @param {*} currentFiltering
 * @returns Updated State according to  certain Conditions || State will be Array of Promises.
 */

export const selectAllcheckBoxes = (currentState, checkboxState) => {
  let updatedState = currentState.map((ParentAd) => {
    if (!ParentAd.isPlusIconDisabled) {
      ParentAd.isTargetChecked = checkboxState;
      ParentAd.childRows = ParentAd.childRows.map((childAd) => {
        childAd.isTargetChecked = checkboxState;
        return childAd;
      });
    }
    return ParentAd;
  });
  return updatedState;
};
